// Variables with default value can be overwritten by TYPO3 variables.
// For view customization enabled in controller.scss the file configuration-sitefactory

// # Global
$domain: "stratis.fr" !default;

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,700" !default;
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,700" !default;

$typo-1: Roboto, sans-serif !default;
$typo-2: Oswald, sans-serif !default;
$typo-3: Oswald, sans-serif !default;

// ## color-1
$color-1--1: #009DE0 !default;
$color-1--2: #0063AB !default;
$color-1--3: #03406D !default;
$color-1--4: #6fcad9 !default;
$color-1--5: #0a283d !default;
$color-1--6: #004f89 !default;

// ## color-2
$color-2--1: #72C7CC !default;
$color-2--2: #00ADA0 !default;
$color-2--3: #008675 !default;
$color-2--4: #7bcbcf !default;

// ## Color-3
$color-3--1: #C3D1DB !default;
$color-3--2: #6C7880 !default;
$color-3--3: #0C324C !default;
$color-3--4: #cfd0d0 !default;
$color-3--5: #3d424c !default;
$color-3--6: #ebf3f7 !default;
$color-3--7: #d7e4eb !default;
$color-3--8: #727272 !default;
$color-3--9: #f3f6f8 !default;

// ## Color-4
$color-4--1: #B5E1F8 !default;
$color-4--2: #6E61A4 !default;
$color-4--3: #EE0088 !default;

$color-5--1: #b5bbbf !default;
$color-5--2: #6ac0ca !default;
$color-5--3: #afb6ba !default;
$color-5--4: #f9fdff !default;
$color-5--5: #f0f9fe !default;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$path-root: "../" !default;
$path-images: "#{$path-root}Images/";
$path-images-bg: "#{$path-root}Images/bg/";
$path-images-fancybox: "#{$path-root}Images/fancybox/";
$path-fonts: "#{$path-root}Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;
$color-blue: #0091c4;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## Color social networks
$socials: (
    facebook: (#3b5998, '\e099'),
    twitter: (#00acee, '\e09b'),
    instagram: (#833ab4, '\e0a1'),
    youtube: (#c4302b, '\e09c')
);

// ## BG Patterns
%bg-pattern-blue {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADAQMAAABs5if8AAAABlBMVEUAAAAYnNYFFvnwAAAAAnRSTlMAmf9A5tgAAAALSURBVAjXYwCBBgAAhgCBifYgiQAAAABJRU5ErkJggg==');
}
%bg-pattern-white {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADAQMAAABs5if8AAAABlBMVEUAAAD///+l2Z/dAAAAAnRSTlMAM8lDrC4AAAALSURBVAjXYwCBBgAAhgCBifYgiQAAAABJRU5ErkJggg==');
}

%bg-pattern-darkblue {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkEyQkFBQUM4RjlEODExRTdBQ0MwQjAyQzYyNkNCMUI3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkEyQkFBQUM5RjlEODExRTdBQ0MwQjAyQzYyNkNCMUI3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTJCQUFBQzZGOUQ4MTFFN0FDQzBCMDJDNjI2Q0IxQjciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTJCQUFBQzdGOUQ4MTFFN0FDQzBCMDJDNjI2Q0IxQjciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7W2PouAAAAG0lEQVR42mJi8G75DwQMIMwIImCAiQEJAAQYAEH6CssV7p5DAAAAAElFTkSuQmCC);
}

%bg-pattern-white-2 {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAEklEQVQImWNgwAn+////H8YGACn9A/1K/AyVAAAAAElFTkSuQmCC');
}

%bg-title-decor {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.68 5.67'%3E%3Cpath fill='%2376C6C1' d='M19.9,1.99c-0.5,0.29-0.67,0.92-0.38,1.41c0.29,0.5,0.92,0.67,1.41,0.38c0.5-0.29,0.67-0.92,0.38-1.41C21.03,1.88,20.39,1.71,19.9,1.99'/%3E%3Cpath fill='%2376C6C1' d='M1.74,1.99c-0.5,0.29-0.67,0.92-0.38,1.41c0.29,0.5,0.92,0.67,1.41,0.38c0.5-0.29,0.67-0.92,0.38-1.41C2.87,1.88,2.24,1.71,1.74,1.99'/%3E%3Cpath fill='%2382CCD9' d='M18.09,1.95c-0.5-0.86-1.6-1.16-2.47-0.66c-0.86,0.5-1.16,1.6-0.66,2.47c0.5,0.86,1.6,1.16,2.47,0.66C18.29,3.92,18.59,2.82,18.09,1.95'/%3E%3Cpath fill='%2382CCD9' d='M7.67,1.95c-0.5-0.86-1.6-1.16-2.47-0.66c-0.86,0.5-1.16,1.6-0.66,2.47c0.5,0.86,1.6,1.16,2.47,0.66C7.87,3.92,8.17,2.82,7.67,1.95'/%3E%3Cpath fill='%2384C2EB' d='M10.17,0.84c-1.1,0.64-1.48,2.04-0.84,3.15c0.64,1.1,2.04,1.48,3.14,0.84c1.1-0.64,1.48-2.04,0.84-3.14C12.68,0.58,11.27,0.21,10.17,0.84'/%3E%3C/svg%3E");
}
%bg-title-dots {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 7.5'%3E%3Ccircle fill='%2376C6C1' cx='30.9' cy='3.7' r='1'/%3E%3Ccircle fill='%236BC4C8' cx='27.4' cy='3.7' r='1.4'/%3E%3Ccircle fill='%2382CCD9' cx='23.1' cy='3.7' r='1.8'/%3E%3Ccircle fill='%2384C2EB' cx='17.9' cy='3.7' r='2.3'/%3E%3Ccircle fill='%23007FC4' cx='11.5' cy='3.7' r='2.9'/%3E%3Ccircle fill='%236F62A8' cx='3.7' cy='3.7' r='3.7'/%3E%3C/svg%3E%0A");
}
%bg-title-dot {
    //background-image: url("data:image/svg+xml,%3Csvg fill='%2375C8D3' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 1.5'%3E%3Ccircle class='st0' cx='0.75' cy='0.75' r='0.75'/%3E%3C/svg%3E");
    background-image: url("data:image/svg+xml,%3Csvg fill='%2375C8D3' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 3'%3E%3Ccircle cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E");
}

