.language {

    $this: &;
    position: relative;

    &__current {
        display: inline-block;
        height: 8rem;
        line-height: 8rem;
        position: relative;
        color: $color-3--2;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: $light;
        transform: skewX(-13.6deg);
        font-family: $typo-2;
        padding: 0 2rem 0 1rem;
        text-align: center;
        cursor: pointer;

        &::after {
            content: '\e018';
            font-family: 'icons-default';
            position: absolute;
            right: .65rem;
            top: 50%;
            font-size: 1rem;
            color: $color-2--4;
            transform:  translateY(-50%) skewX(13.6deg);
        }
        &:before {
            content: '';
            position: absolute;
            right: -15px;
            bottom: 0;
            border-right: 2px dotted #b5bbbf;
            height: 50%;
        }
    }

    &__current-inner {
        display: block;
        transform: skewX(13.6deg);
        position: relative;
    }

    &.is-active {

        #{$this}__current {
            background-color: $color-1--2;
            color: $color-white;
        }

        #{$this}__drop {
            display: block;
        }
    }

    &__drop {
        display: none;
        width: 10rem;
        padding: 2rem;
        background-color: $color-1--2;
        left: 50%;
        margin-left: -6.7rem;
    }

    &__item {
        display: block;
        margin-bottom: .5rem;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: $color-white;
            text-transform: uppercase;
            font-family: $typo-2;
            font-size: 1.4rem;
            font-weight: $light;
            display: block;
            text-align: center;

        }
    }
}

.mobile-language {
    display: none;
    @include breakpoint(medium down) {
        display: block;

        &.is-active {

            .language__current {
                color: $color-white;
                background-color: $color-1--2;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

                .language__current-inner:after {
                    content: '\e019';
                }
            }

            .language__drop {
                display: block;
            }
        }

        .language__current {
            width: 100%;
            background-color: $color-white;
            transform: none;
            border-bottom: 1px dotted $color-3--2;
            text-align: center;
            color: $color-3--2;
            padding: 0 1rem;
            transition: background-color 300ms ease;
            outline-color: $color-white;
            position: relative;
            z-index: 2;

            &:hover, &:focus {
                background-color: $color-1--2;
                color: $color-white;
            }

            &:focus {
                outline-color: $color-white;
            }

            &:before {
                display: none;
            }

            &:after {
                display: none;
            }

            &-inner {
                display: inline-block;
                vertical-align: top;
                padding-right: 1.3rem;
                transform: none;
                position: relative;

                &:after {
                    content: '\e018';
                    font-family: 'icons-default';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    font-size: 1rem;
                    line-height: 1em;
                    color: $color-2--1;
                    transform: translateY(-50%);
                }
            }
        }

        .language__drop {
            display: none;
            width: 100%;
            position: relative;
            left: auto;
            margin: 0;
            z-index: 1;
            border-bottom: 1px dotted $color-3--2;
            padding: .5rem 1rem;
        }

        .language__item {
            margin: 1.5rem 0;

            a {
                outline-color: $color-white;

                &:focus {
                    outline-color: $color-white;
                }
            }
        }
    }
}
