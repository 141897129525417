//@name Page content

// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
.encadre > *:first-child,
// content in click and roll
.rte.ddm__sub-level > *:first-child,
.ddm__sub-level span.anchor:first-of-type + .colPos18181 .ce-bodytext > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

.section-main__content span.anchor:first-of-type + .colPos0:first-child > *:not(.story):first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > .story > *:first-child {
    margin-top: -.5rem;
}

// @name Title h2
$content-h2__font-size-small: 2;
$content-h2__font-size-large: 3.4;
.rte h2,
.rte .h2,
.h2 {
    font-family: $typo-3;
    font-size: #{$content-h2__font-size-small}em;
    line-height: 1.2;
    font-weight: $normal;
    color: $color-1--2;
    margin: em(6, $content-h2__font-size-small) 0 em(2.5, $content-h2__font-size-small) 0;
    @include breakpoint(medium) {
        font-size: #{$content-h2__font-size-large}em;
        margin: em(6, $content-h2__font-size-large) 0 em(2.5, $content-h2__font-size-large) 0;
    }
    a {
        color: $color-1--2;
        &:hover,
        &:focus {
            color: $color-black;
        }
    }
}

// @name Title h3
$content-h3__font-size-small: 1.8;
$content-h3__font-size-large: 2.5;
.rte h3,
.rte .h3,
.h3 {
    font-family: $typo-3;
    font-size: #{$content-h3__font-size-small}em;
    line-height: 1.2;
    font-weight: $normal;
    color: $color-2--3;
    display: table;
    margin: em(5, $content-h3__font-size-small) auto em(1.7, $content-h3__font-size-small) 0;
    padding-bottom: em(2.2, $content-h3__font-size-small);
    @extend %bg-title-dot;
    background-size: 5px;
    background-repeat: repeat-x;
    background-position: calc(-100% + 2px) calc(100% - 4px);
    position: relative;
    min-width: 7rem;
    @include breakpoint(medium) {
        font-size: #{$content-h3__font-size-large}em;
        margin: em(5, $content-h3__font-size-large) auto em(1.7, $content-h3__font-size-large) 0;
        padding-bottom: em(2.2, $content-h3__font-size-large);
        min-width: 20rem;
    }
    &:after {
        content: '';
        bottom: 0;
        right: 0;
        width: 45px;
        height: 10px;
        position: absolute;
        display: block;
        @extend %bg-title-dots;
        background-repeat: no-repeat;
        background-position: 100% 0;
        transform: scaleX(-1);
        background-color: $color-white;
        @include breakpoint(medium) {
            width: 16rem;
        }
    }
    a {
        color: $color-black;
        &:hover,
        &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size-small: 1.7;
$content-h4__font-size-large: 2;
.rte h4,
.rte .h4,
.h4 {
    font-family: $typo-3;
    font-size: #{$content-h4__font-size-small}em;
    line-height: 1.2;
    color: $color-1--2;
    margin: em(4, $content-h4__font-size-small) 0 em(1.8, $content-h4__font-size-small) 0;
    @include breakpoint(medium) {
        margin: em(4, $content-h4__font-size-large) 0 em(1.8, $content-h4__font-size-large) 0;
        font-size: #{$content-h4__font-size-large}em;
    }
    &:before {
        content: '/ ';
        color: $color-3--2;
    }
    a {
        color: $color-1--2;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h5
$content-h5__font-size-small: 1.6;
$content-h5__font-size-large: 1.7;
.rte h5,
.rte .h5,
.h5 {
    font-family: $typo-3;
    font-size: #{$content-h5__font-size-small}em;
    line-height: 1.2;
    color: $color-3--2;
    margin: em(4, $content-h5__font-size-small) 0 em(1.8, $content-h5__font-size-small) 0;
    @include breakpoint(medium) {
        font-size: #{$content-h5__font-size-large}em;
        margin: em(4, $content-h5__font-size-large) 0 em(1.8, $content-h5__font-size-large) 0;
    }
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6,
.rte .h6,
.h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.5;
.rte {
    // @name Link
    a {
        color: $color-1--2;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        @include default-icons-after('\e086', 0 0 0 .5rem, .8rem, $color-black, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    $content-paragraph__font-size-mobile: 1.4;
    p {
        font-size: #{$content-paragraph__font-size}em;
        font-weight: $normal;
        margin: 0 0 em(1.2, $content-paragraph__font-size) 0;
        //@include breakpoint(medium down) {
        //    font-size: #{$content-paragraph__font-size-mobile}em;
            //margin: em(.5, $content-paragraph__font-size-mobile) 0 em(1, $content-paragraph__font-size-mobile) 0;
        //}
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.5em;
        color: $color-3--3;
        a {
            color: $color-3--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    $encadre__font-size: 1.6;
    .encadre {
        padding: em(2, $encadre__font-size) em(2, $encadre__font-size) em(2, $encadre__font-size) em(10, $encadre__font-size);
        margin: em(2.5, $encadre__font-size) 0 em(2.5, $encadre__font-size);
        background-color: $color-1--2;
        font-weight: $light;
        color: $color-white;
        @extend %bg-pattern-white;
        position: relative;
        overflow: hidden;
        @include default-icons-absolute-before('\E008', 3.5rem, $color-2--1, 50%, inherit, inherit, 0.5em, 'icons-project');
        @include breakpoint(small down) {
            padding-left: em(9, $encadre__font-size);
        }
        &:before {
            transform: translateY(-50%);
            z-index: 10;
            @include breakpoint(small down) {
                top: 40px;
                font-size: 3rem;
            }
        }
        &:after {
            content: '';
            display: block;
            width: 100px;
            min-height: 100px;
            height: 100%;
            background-color: $color-1--3;
            position: absolute;
            border-radius: 0 50% 50% 0;
            top: 50%;
            left: 0;
            transform: translate(-20%, -50%);
            z-index: 0;
            @include breakpoint(small down) {
                height: 100px;
                top: 0;
                transform: translate(-30%, -20%) rotate(30deg);
            }
        }
        a {
            color: inherit;
            font-weight: $medium;
            text-decoration: underline;
            &:after {
                color: inherit !important;
            }
        }
    }
    p.encadre,
    .encadre p {
        font-size: #{$encadre__font-size}em;
    }

    // @name Blockquote
    $content-blockquote__font-size: 1.8;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        padding: em(1, $content-blockquote__font-size) 0 em(1, $content-blockquote__font-size) em(10, $content-blockquote__font-size);
        font-weight: $thin;
        font-style: italic;
        margin: em(1.5, $content-blockquote__font-size) 0 em(1.5, $content-blockquote__font-size) 0;
        color: $color-3--2;
        &:before {
            content: '';
            position: absolute;
            top: em(2, $content-blockquote__font-size);
            left: 10px;
            width: 66px;
            height: 66px;
            background-color: $color-white;
            border-radius: 50%;
            box-shadow: 10px 0 30px -15px rgba($color-black,0.75);
            @include breakpoint(medium) {
                top: em(1, $content-blockquote__font-size);
                width: 80px;
                height: 80px;
                left: 0;
            }
        }
        @include default-icons-absolute-after('\e06f', 4rem, $color-1--1, 3.25rem, inherit, inherit, 2.25rem);
        &:after {
            font-style: normal;
            @include breakpoint(medium) {
                font-size: 5rem;
                top: 2.5rem;
                left: 1.5rem;
            }
        }
        p {
            font-size: 1em;
            margin: em(1, $content-paragraph__font-size) 0;
            font-weight: $light;
        }
    }

    // @name Unordered list
    ul,
    .show-hide__content ul {
        margin: 1.5rem 0 1.5rem 0;
        list-style: none;
        font-size: #{$content-list__font-size}rem;
        color: $color-black;
        padding: 0;
        @include breakpoint(medium) {
            margin-right: 2.5rem;
        }
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            @include default-icons-absolute-before('\e01b', 1.1rem, $color-2--2, .2rem, inherit, inherit, 0);
            padding-left: 2rem;
            > ul:first-child, > * + ul { // for specificity selector
                margin: 0 0 1rem .8rem;
                list-style: none;
                padding: .1rem 0 .1rem 0;
                color: $color-3--2;
                font-size: em(1.4, $content-list__font-size);
                > li {
                    padding-left: 1.5rem;
                    @include default-icons-absolute-before('\e007', 1.1rem, currentColor, .5rem, inherit, inherit, 0);
                }
            }
            > ol:first-child, > * + ol { // for specificity selector
                margin: 0 0 1rem 1.5rem;
                padding: .1rem 0 .3rem 0;
                color: $color-3--2;
                font-size: em(1.4, $content-list__font-size);
                > li {
                    padding: 0;
                    &:before {
                        font-family: none;
                        content: none;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol,
    .show-hide__content ol {
        margin: 1.5rem 0 1.5rem 0;
        padding: 0 0 0 1.8rem;
        list-style: decimal;
        font-size: #{$content-list__font-size}rem;
        color: $color-black;
        @include breakpoint(medium) {
            margin-right: 2.5rem;
        }
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            padding: 0;
            > ol:first-child, > * + ol { // for specificity selector
                margin: 0 0 1rem 1.5rem;
                padding: .1rem 0 .1rem 0;
                color: $color-3--2;
                font-size: em(1.4, $content-list__font-size);
                > li {
                    padding: 0;
                }
            }
            > ul:first-child, > * + ul { // for specificity selector
                margin: 0 0 1rem;
                list-style: none;
                padding: .1rem 0 .3rem 0;
                color: $color-3--2;
                font-size: em(1.4, $content-list__font-size);
                > li {
                    padding-left: 1.5rem;
                    @include default-icons-absolute-before('\e007', 1.1rem, currentColor, .5rem, inherit, inherit, 0);
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 4em 0;
        @include breakpoint(medium down) {
            position: relative;
        }
    }
    .table-wrapper-fade {
         @include breakpoint(medium down) {
            position: absolute;
            right: 0;
            width: 30px;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
        }
    }
    .table-wrapper-inner {
        overflow-y: auto;
        width: 100%;
        border: 2px solid $color-3--1;
        table {
            margin-bottom: 2rem;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: $color-1--1;
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        caption {
            $table__caption_font-size: 1.6;
            font-family: $typo-2;
            text-align: left;
            font-weight: $black;
            font-style: italic;
            font-size: #{$table__caption_font-size}em;
            color: $color-2--3;
            margin-bottom: em(1.5, $table__caption_font-size);
            padding: 0.5em 0 0 0.5em;
            @include breakpoint(medium down) {
                text-align: left;
            }
        }
        th,
        td {
            padding: 1.2rem 2.5rem;
            border-right: 1px solid rgba(#656565, 0.2);
            ul {
                font-size: 1em;
            }
            p {
                font-size: #{$content-paragraph__font-size/1.3}em;
            }
            &:last-child {
                border: none;
            }
        }
        th {
            font-size: 1.4em;
            text-transform: uppercase;
            font-family: $typo-2;
            font-weight: $bold;
            color: $color-2--3;
            border-right: 2px solid $color-2--3;
            text-align: left;
            min-width: 200px;

            &[align*="center"] {
                text-align: center;
            }

            &[align*="right"] {
                text-align: right;
            }

            &[align*="left"] {
                text-align: left;
            }

            &:first-child {
                border-right-width: 5px;
            }
        }
        thead th {
            font-size: 1.4em;
            background: $color-white;
            font-weight: $normal;
            color: $color-2--3;
            &:first-child {
                border-color: $color-2--1;
            }
        }
        tbody th {
            text-align: right;
            color: $color-1--2;

            &[align*="center"] {
                text-align: center;
            }

            &[align*="right"] {
                text-align: right;
            }

            &[align*="left"] {
                text-align: left;
            }

            &:before {
                content: '/ ';
                color: $color-2--2;
            }
            &:first-child {
                border-color: $color-1--1;
            }
        }
        td {
            font-size: 1.4em;
            color: $color-3--2;
            //background: $color-white;
            vertical-align: top;
            font-family: $typo-1;
            font-weight: $light;
        }
    }

    // @name column 2
    .column--2,
    .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
}
