// @name site informations
.site-infos {
    position: relative;
    color: $color-white;
    //@include breakpoint(medium only) {
    //    width: calc(65% - 6em) !important;
    //    padding-left: 22rem;
    //}
    //@include breakpoint(large) {
    //    padding-left: 16rem;
    //}
    //@include breakpoint(xlarge) {
    //    padding-left: 5.5rem;
    //}
    .site-infos__img-wrap {
        display: block;
        @include breakpoint(medium) {
            position: absolute;
            z-index: 0;
            left: 0;
            top: -5rem;
            max-width: 28rem;
        }
        @include breakpoint(large) {
            //left: -6rem;
            //top: -10rem;
            left: -5rem;
            top: -8.5rem;
        }
        @include breakpoint(xlarge) {
            left: -17rem;
            top: -10rem;
        }
        img {
            display: block;
            border-radius: 50%;
            width: 206px;
            height: 206px;
            box-shadow: 30px 30px 90px -30px rgba(0,0,0,0.5);
            @include breakpoint(large only) {
                width: 180px;
                height: 180px;
            }
            @include breakpoint(small only) {
                margin: 0 auto 2rem;
            }
        }
    }
    .site-infos__wrapper {
        overflow: hidden;
        position: relative;
        z-index: 10;
        h3 {
            font-family: $typo-2;
            text-transform: uppercase;
            font-weight: $light;
            letter-spacing: 0.3px;
            font-size: 1.6em;
            //@include breakpoint(large only) {
            //    white-space: nowrap;
            //}
        }
    }
    address,
    p,
    ul {
        font-size: 1.4em;
        font-style: normal;
        font-weight: $light;
        margin: em(.5, 1.3) 0 em(.8, 1.3);
        &:first-child {
            margin-top: 0;
        }
    }
    ul {
        > li {
            margin: 0.2em 0;
        }
    }
    a {
        color: $color-white;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    .site-infos__map,
    .site-infos__tel {
        a {
            display: block;
            @include default-icons-before('\e02b', 0 .5rem 0 0, 1.15em, $color-1--4, -0.2rem);
        }
    }
    .site-infos__map {
        a:before {
            content: '\E00A';
            font-family: 'icons-project';
        }
    }
    .button-1 {
        display: inline-block;
        font-size: 1.2rem;
        text-decoration: none !important;
        background-color: $color-3--3;
        padding: 1.4em 2.4em 1.4em 3.4em;
        &:before {
            content: '\e02a';
            color: $color-1--4;
            left: 2rem;
            @include breakpoint(large) {
                left: 1.25em;
            }
        }
        &:hover,
        &:focus {
            background-color: $color-1--2;
        }
    }
}
