// Clear-fix mixin
@mixin clear {
    @extend %clear-fix;
}

@mixin abs-center() {
    @extend %abs-center;
}

// Center block horizontally
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Create cover block
@mixin coverer {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

// Set object fit property include polyfill values
// @param {string} $fit - object-fit value
// @param {string} $position - object-fit-position value
@mixin object-fit($fit: 'cover', $position: 'center') {
    &:not([src*='data:image']) {
        font-family: 'object-fit: #{$fit}; object-position: #{$position};';
        object-fit: unquote($fit);
    }
}

// Set font styles
// @param {string|list} $params - list of font params font-family, font-size, font-weight, font-style
@mixin font($params...) {
    @if length($params) > 0 {
        $params: if(length($params) == 1, nth($params, 1), $params);
        $props: (font-family, font-size, font-weight, font-style);
        @for $index from 1 through length($params) {
            @if $index == 1 {
                @if nth($params, $index) == $typo-1 {
                    #{nth($props, $index)}: $typo-1;

                    .wf-active & {
                        #{nth($props, $index)}: nth($params, $index);
                    }
                }

                @if nth($params, $index) == $typo-2 {
                    #{nth($props, $index)}: $typo-2;

                    .wf-active & {
                        #{nth($props, $index)}: nth($params, $index);
                    }
                }
            } @else {
                #{nth($props, $index)}: nth($params, $index);
            }
        }
    }
}

// Vertically centers an element using `transform`.
@mixin v-align-middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// Hide text without icon (old-version)
@mixin hide-text {
    display: inline-block;
    overflow: hidden;
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
}

// Hide text with icon
@mixin hide-text-with-icon {
    @include hide-text();

    &::before,
    &::after {
        @include abs-center;
        text-indent: 0;
    }
}

// Set width and height for element
@mixin size($width, $height: $width) {
    height: $height;
    width: $width;
}

// Set absolute position with settings
@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
    bottom: $bottom;
    left: $left;
    position: absolute;
    right: $right;
    top: $top;
}

// Set fixed position with settings
@mixin fixed($top: auto, $right: auto, $bottom: auto, $left: auto) {
    bottom: $bottom;
    left: $left;
    position: fixed;
    right: $right;
    top: $top;
}

// Set relative position with settings
@mixin relative($top: auto, $right: auto, $bottom: auto, $left: auto) {
    bottom: $bottom;
    left: $left;
    position: relative;
    right: $right;
    top: $top;
}

@mixin reset-position {
    bottom: auto;
    left: auto;
    position: static;
    right: auto;
    top: auto;
}

// Set transition for element with settings
@mixin transition($prop: all, $duration: 400ms, $easing: ease-in-out, $delay: 0ms) {
    transition: $prop $duration $easing $delay;
}

// Shortcode for transition mixin
@mixin trs($prop: all, $duration: 250ms, $easing: ease-in-out, $delay: 0ms) {
    @include transition($prop, $duration, $easing, $delay);
}

@mixin multi-inherit($this, $mask) {
    $selector: ();
    @each $amp in $this {
        $selector: append($selector, unquote(str-replace($mask, '&', $amp)), comma);
    }
    @at-root {
        #{$selector} {
            @content;
        }
    }
}

// Use this for creating scalable elements (usually images / background images) that maintain a ratio.
@mixin responsive-ratio($x,$y, $pseudo) {
    $padding: unquote(( $y / $x ) * 100 + '%');
    @if $pseudo == 'before' {
        &::before {
            content: '';
            display: block;
            padding-top: $padding;
            width: 100%;
        }
    } @else if $pseudo == 'after' {
        &::after {
            content: '';
            display: block;
            padding-top: $padding;
            width: 100%;
        }
    } @else {
        padding-top: $padding;
    }
}

// Mixin for truncating text
// @param {Integer} - $width - row max-width
@mixin truncate($width) {
    max-width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/// Since the current way to qualify a class from within its ruleset is quite
/// ugly, here is a mixin providing a friendly API to do so.
/// @author Hugo Giraudel
/// @param {String} $element-selector - Element selector
@mixin qualify($element-selector) {
    @at-root #{$element-selector + &} {
        @content;
    }
}

@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:focus {
            @content;
        }
    }
}

@mixin title($color: $color-black, $font: $typo-2, $weight: $bold) {
    align-items: center;
    color: $color;
    display: inline-flex;
    font-family: $font;
    font-weight: $weight;
    text-transform: uppercase;
}

//// Create fontFace
//@mixin fontFace($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
//  @font-face {
//    font-family: "#{$fontname}";
//    src: url("#{$font-path}#{$fontfile}.eot");
//    src: url("#{$font-path}#{$fontfile}.eot?#iefix") format("embedded-opentype"),
//    url("#{$font-path}#{$fontfile}.woff") format("woff"),
//    url("#{$font-path}#{$fontfile}.ttf") format("truetype"),
//    url("#{$font-path}#{$fontfile}.svg##{$fontname}") format("svg");
//    font-weight: $fontweight;
//    font-style: $fontstyle;
//  }
//}

%icons-font-aliasing {
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: $bold;
    -moz-osx-font-smoothing: grayscale;
    //vertical-align: -0.125em;
}

// Add inline icon before
@mixin icon( $position, $icon, $ff: null, $va: null, $fw: null) {
    &::#{$position} {
        @extend %icons-font-aliasing;
        content: if(str-index($icon, 'attr'), $icon, unquote('\"#{ $icon }\"'));
        font-family: $ff;
        font-weight: $fw;
        vertical-align: $va;
    }
}

@mixin icon-before($icon, $ff: null, $va: null, $fw: null) {
    @include icon('before', $icon, $ff, $va, $fw);
}

@mixin icon-after($icon, $ff: null, $va: null, $fw: null) {
    @include icon('after', $icon, $ff, $va, $fw);
}

// If modifier is passed it wraps the code in it.
@mixin add-modifier($modifier: null) {
    @if ($modifier) {
        &.-#{$modifier} {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin bg-pattern($color, $opacity: 1, $property: 'background-image') {
    #{$property}: inline-svg('<svg fill="#{$color}" fill-opacity="#{$opacity}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 3" width="3" height="3"><rect width="1" height="1"/></svg>');
    background-size: 3px;
}

//Shadow for section
@mixin section-shadow() {
    position: relative;

    &::before,
    &::after {
        background-color: transparent;
        bottom: 2rem;
        box-shadow: 0 2rem 1.5rem 0 rgba($color-black, 0.5);
        content: '';
        display: block;
        height: 1.5rem;
        position: absolute;
        width: calc(40% - 3rem);
        z-index: -1;
    }

    &::before {
        left: 6rem;
        transform: rotate(-3deg) skewX(-60deg);

        @include breakpoint(medium down) {
            left: 7rem;
        }
    }

    &::after {
        right: 6rem;
        transform: rotate(3deg) skewX(60deg);

        @include breakpoint(medium down) {
            right: 7rem;
        }
    }
}

// Fancy and smooth linear gradient
// @url https://css-tricks.com/easing-linear-gradients/
@mixin scrim-gradient($color: $color-white, $direction: 'to top') {
    $scrim-coordinates: (
        0: 1,
        19: 0.738,
        34: 0.541,
        47: 0.382,
        56.5: 0.278,
        65: 0.194,
        73: 0.126,
        80.2: 0.075,
        86.1: 0.042,
        91: 0.021,
        95.2: 0.008,
        98.2: 0.002,
        100: 0
    );

    $stops: ();

    @each $stop, $alpha in $scrim-coordinates {
        $stops: append($stops, rgba($color, $alpha) $stop*1%, comma);
    }

    background: linear-gradient(unquote($direction), $stops);
}

// Fix object fit for IE 11 and EDGE
@mixin object-fit-video-ie() {
    // IE
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: auto;
        width: 100vw;
    }

    // EDGE
    @supports (object-fit: cover) and (-ms-ime-align: auto) {
        height: auto;
        width: 100%;
    }
}

// Add styles for font-awesome icon inside block
@mixin fa-icon-style($pseudo: true) {
    span {
        pointer-events: none;

        &[class*='fa-'] {
            @if $pseudo == true {
                &::before {
                    @content;
                }
            } @else {
                @content;
            }
        }
    }
}

// Add styles if IE 10 and above
@mixin if-ie() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// Placeholder for image
@mixin image-placeholder($x: 480, $y: 320, $bgs: 40%) {
    &::after {
        background: {
            color: $color-3--1;
            image: image('placeholder-image.svg');
            position: center;
            repeat: no-repeat;
            size: $bgs;
        }
        content: '';
        display: block;
        padding-top: unquote(( $y / $x ) * 100 + '%');
    }
}
