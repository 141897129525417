.flash-info {
    position: relative;
    z-index: 300;
    background-color: $color-1--2;
    @extend %bg-pattern-darkblue;
    @include breakpoint(small down) {
        margin: 0 -1rem;
    }
    &__wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-1--2;
    }
    &__content {
        padding-right: 3.5rem;
        display: flex;
        align-items: center;
        @include breakpoint(small down) {
            padding: 0;
            flex-direction: column;
        }
    }
    &__icon {
        position: relative;
        padding-right: 2rem;
        overflow: hidden;
        @include breakpoint(small down) {
            width: 100%;
            padding-top: 3rem;
            padding-right: 0;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkEyQkFBQUM4RjlEODExRTdBQ0MwQjAyQzYyNkNCMUI3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkEyQkFBQUM5RjlEODExRTdBQ0MwQjAyQzYyNkNCMUI3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTJCQUFBQzZGOUQ4MTFFN0FDQzBCMDJDNjI2Q0IxQjciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTJCQUFBQzdGOUQ4MTFFN0FDQzBCMDJDNjI2Q0IxQjciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7W2PouAAAAG0lEQVR42mJi8G75DwQMIMwIImCAiQEJAAQYAEH6CssV7p5DAAAAAElFTkSuQmCC);
        }
        &:before {
            width: 7.6rem;
            height: 7.6rem;
            content: '';
            position: absolute;
            right: -4rem;
            top: 50%;
            transform: translateY(-50%);
            background: $color-1--2;
            border-radius: 50%;
            box-shadow: 12px 0 16px 3px rgba(0, 0, 0, 0.3);
            @include breakpoint(small down) {
                content: none;
            }
        }
        img {
            display: block;
            @include breakpoint(small down) {
                margin: 0 auto;
            }
        }
    }
    &__description {
        position: relative;
        max-width: 50rem;
        padding-left: 0.7rem;
        color: $color-white;
        @include breakpoint(small down) {
            max-width: 100%;
            padding: 2.5rem 2.1rem;
            &:before {
                width: 7.6rem;
                height: 7.6rem;
                content: '';
                position: absolute;
                right: 50%;
                top: -2rem;
                transform: translateX(50%);
                background: $color-1--2;
                border-radius: 50%;
                z-index: 0;
                box-shadow: 0 -14px 7px 1px rgba(0, 0, 0, 0.3);
            }
        }
        .category {
            position: relative;
            z-index: 1;
            margin-bottom: 0.5rem;
            color: $color-white;
            font-family: $typo-2;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: $bold;
        }
        .title {
            position: relative;
            z-index: 1;
            font-family: $typo-1;
            font-size: 2.2rem;
            line-height: 1;
        }
    }
    &__btn {
        &--plus {
            padding-left: 1.2rem;
            color: $color-white;
            font-size: 1.2rem;
            background: none;
            font-family: $typo-1;
            &:before {
                left: 0;
                font-size: 1rem;
                color: #b9ded0;
                content: '\e017';
                @include breakpoint(small down) {
                    left: 0.5rem;
                }
            }
            &:hover,
            &:focus {
                color: #b9ded0;
                background: none;
                &:before {
                    color: $color-white;
                }
            }
        }
        &--close {
            width: 3.5rem;
            height: 3.5rem;
            text-align: left;
            text-indent: -9999px;
            white-space: nowrap;
            position: absolute;
            right: 0;
            top: 1.7rem;
            overflow: hidden;
            @include background-opacity($color-black, 0.2);
            border-radius: 50%;
            @include default-icons-before('\e022', 0 0 0, 2rem, $color-2--1, middle, 'icons-default');
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-indent: 0;
            }
            @include breakpoint(small down) {
                right: 0.1rem;
                top: 1.1rem;
            }
            &:hover,
            &:focus {
                @include background-opacity($color-black, 0.5);
            }
        }
    }
}
