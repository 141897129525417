.social {

    .title-1 {
        font-size: 4rem;
        color: $color-black;
        @include breakpoint(medium down) {
            font-size: 3rem;
        }
        @include breakpoint(small down) {
            font-size: 2.8rem;
        }
    }

    .heading-block {
        margin-bottom: 0.5em;
    }

    &__wrap {
        margin: 0 -1rem 2rem;
        @include breakpoint(medium only) {
            width: 85%;
            margin: 0 auto;
        }
        @include breakpoint(large) {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
    }
    &__filter {
        display: flex;
        justify-content: center;
        list-style: none;
        margin-bottom: 3rem;
        li {
            margin: 0 2px;
        }
        a {
            display: block;
            text-align: center;
            line-height: 3.5rem;
            font-size: 1.2rem;
            transition: all 0.3s ease;
            &:not(.social__filter-tous) {
                color: $color-white;
                height: 3.5rem;
                width: 3.5rem;
                line-height: 3.5rem;
                font-size: 1.4em;
                text-align: center;
                background-color: $color-3--2;
                border-radius: 50%;
                &:before {
                    display: block;
                    font-style: normal;
                    font-variant-caps: normal;
                    font-variant-east-asian: normal;
                    font-variant-ligatures: normal;
                    font-variant-numeric: normal;
                    font-weight: $normal;
                    speak: none;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    font-family: 'icons-default';
                }
            }
            &.social__filter-tous {
                font-weight: $medium;
                color: $color-3--2;
                font-family: $typo-2;
                text-transform: uppercase;
                margin-right: 0.5em;
                &:before {
                    content: '/ ';
                }
            }
        }
        @each $vendor, $data in $socials {
            &-#{$vendor} {
                &:before {
                    content: nth($data, 2);
                }
                &:hover,
                &:focus,
                &.active {
                    text-decoration: none;
                    background-color: nth($data, 1) !important;
                }
            }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 1rem;
        padding: 0;
        list-style: none;
        overflow: hidden;
        a {
            word-break: break-all;
        }
    }
    // Hide items on initial
    .social-item:not(.social-item--tous) {
        //display: none;
    }
}

.social-item {

    $this: &;

    width: 100%;
    padding: 0 1em 4em;
    display: inline-block;
    @include breakpoint(medium) {
        width: 50%;
    }
    @include breakpoint(large) {
        width: 25%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    &__wrapper {
        &:before {
            content: '';
            width: 100%;
            height: 35px;
            line-height: 35px;
            display: block;
            text-align: center;
            background-color: $color-white;
            color: $color-white;
            font-size: 1.6em;
            font-style: normal;
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
            font-weight: $normal;
            speak: none;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            font-family: 'icons-default';
        }
    }
    &__thumbnail {
        display: block;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &__head {
        padding: 2em 0 1em;
        display: block;
        @extend .clear-fix;
        &-logo {
            float: left;
            width: 42px;
            height: 100%;
            margin-right: 10px;
            border: 1px solid rgba(119, 119, 119, 0.5);
        }
        &-text {
            display: block;
            margin: 0.15em 0;
            color: $color-black;
            h2 {
                font-weight: $bold;
                font-size: 1.4rem;
                a {
                    color: inherit;
                }
            }
            p {
                font-size: 1.1rem;
                font-weight: $light;
                color: #717171;
            }
        }
    }
    &__description {
        font-size: 1.3em;
        font-weight: $light;
        word-wrap: break-word;
        margin: 0 0 1em;
        a {
            position: relative;
            z-index: 10;
        }
    }
    &__footer {
        font-size: 1.1em;
        text-transform: uppercase;
        color: $color-2--3;
        margin: 11px 0 7px;
    }
    @each $vendor, $data in $socials {
        &#{$this}--#{$vendor} {
            #{$this}__wrapper:before {
                content: nth($data, 2);
                background-color: nth($data, 1);
            }
            #{$this}__head-text h2,
            #{$this}__description a,
            #{$this}__footer {
                color: nth($data, 1);
            }
        }
    }
}
