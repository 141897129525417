.logo {
    float: left;
    font-size: 1.3em;
    a {
        display: block;
    }

    &--header {
        float: none;

        .link-logo {
            display: block;
        }
    }
}
