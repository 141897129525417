.jesuis {

    $this: &;

    &__btn {
        position: relative;
        padding-left: 3.5rem;
        padding-right: 2.5rem;
        font-family: $typo-2;
        font-weight: $light;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: $color-3--2;
        height: 8rem;
        line-height: 8rem;
        width: 10rem;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        transform: skewX(-13.6deg);

        &::after {
            content: '\e018';
            font-family: 'icons-default';
            position: absolute;
            right: 1rem;
            top: 50%;
            font-size: 1rem;
            color: $color-2--4;
            transform:  translateY(-50%) skewX(13.6deg);
        }

        &:hover, &:focus {
            background-color: $color-1--2;
            color: $color-white;
            svg {
                path {
                    stroke: $color-white;
                }
            }
        }
    }

    &__btn-inner {
        display: block;
        transform: skewX(13.6deg);
        position: relative;
    }

    &__icon {
        position: absolute;
        display: block;
        left: -2.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 2.1rem;
        height: 2.1rem;
        line-height: 1;

        svg {
            line-height: 1;
            fill: none;
            stroke-width: 20px;
            stroke: $color-5--3;
        }
    }

    &__text {
        display: block;
        width: 4rem;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.is-active {

        #{$this}__drop {
            display: block;
        }

        #{$this}__btn {
            background-color: $color-1--2;
            color: $color-white;
        }
    }

    &__drop {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        padding-top: 3.5rem;
        padding-bottom: 3rem;
        background-color: $color-1--2;
        display: none;
    }

    &__title {
        color: $color-white;
        text-align: center;
        font-family: $typo-2;
        font-weight: $bold;
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    &__nav {
        margin-top: 5.5rem;
    }

    &__nav-list {
        display: flex;
        flex-wrap: wrap;
    }

    &__nav-item {
        width: calc(100%/6);

        &:not(:last-child) {

            #{$this}__nav-icon {

                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -4rem;
                    height: 8rem;
                    width: 2.5rem;
                    background: url("../Images/custom/jesuis__decor.png") no-repeat;
                }
            }
        }
    }

    &__nav-link {
        display: block;
        color: $color-white;
        text-align: center;
        outline-color: $color-2--1;

        &:hover, &:focus {
            text-decoration: none;

            #{$this}__nav-text {
                text-decoration: underline;
            }
        }

        &:focus {
            outline-color: $color-2--1;
        }
    }

    &__nav-icon {
        height: 8rem;
        padding: .5rem 2.5rem;
        position: relative;
        width: 100%;
        display: block;

        img {
            max-width: 100%;
            height: 7rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__nav-text {
        margin-top: 1.5rem;
        display: block;
        color: $color-white;
        font-size: 1.6rem;
        font-weight: $light;
        font-family: $typo-1;
    }
}


.mobile-jesuis {
    display: none;
    @include breakpoint(medium down) {
        display: block;

        &.is-active {

            .jesuis__btn {
                color: $color-white;
                background-color: $color-1--2;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

                .jesuis__btn-inner:after {
                    content: '\e019';
                }

                .jesuis__icon svg {
                    stroke: $color-white;
                }
                .jesuis__text {
                    color: $color-white;
                }
            }

            .jesuis__drop {
                display: block;
            }
        }

        .jesuis__btn {
            width: 100%;
            background-color: $color-white;
            transform: none;
            border-bottom: 1px dotted $color-3--2;
            text-align: center;
            color: $color-3--2;
            padding: 0 1rem;
            transition: background-color 300ms ease;
            outline-color: $color-white;
            position: relative;
            z-index: 2;

            &:hover, &:focus {
                background-color: $color-1--2;

                .jesuis__icon svg {
                    stroke: $color-white;
                }

                .jesuis__text {
                    color: $color-white;
                }
            }

            &:focus {
                outline-color: $color-white;
            }

            &:after {
                display: none;
            }

            &-inner {
                display: inline-block;
                width: auto;
                margin: 0;
                transform: none;
                position: relative;

                &:after {
                    content: '\e018';
                    font-family: 'icons-default';
                    position: absolute;
                    right: -1.3rem;
                    top: 50%;
                    font-size: 1rem;
                    line-height: 1em;
                    color: $color-2--1;
                    transform: translateY(-50%);
                    //transition: all 300ms ease;
                }
            }

            .jesuis__icon {
                svg {
                    stroke: rgba($color-3--2, .6);
                    //transition: all 800ms ease;
                }
            }

            .jesuis__text {
                width: auto;
                min-width: 4rem;
                //transition: all 300ms ease;
            }
        }

        .jesuis__drop {
            display: none;
            position: relative;
            z-index: 1;
            border-bottom: 1px dotted $color-3--2;
            padding: 3rem 1rem;

            .wrapper-main {
                max-width: none;
            }
        }

        .jesuis__title {
            margin-bottom: .5rem;
        }

        .jesuis__nav {
            margin-top: 0;

            &-item {
                margin-top: 2.5rem;

                @media(max-width: 1100px) {
                    width: 33.333%;

                    &:nth-child(3n) .jesuis__nav-icon:before {
                        display: none;
                    }
                }

                @include breakpoint(small down) {
                    width: 50%;

                    &:nth-child(2n - 1) .jesuis__nav-icon:before {
                        right: -1.3rem;
                    }

                    &:nth-child(3n) .jesuis__nav-icon:before {
                        display: block;
                    }

                    &:nth-child(2n) .jesuis__nav-icon:before {
                        display: none;
                    }
                }

                @media(max-width: 450px) {
                    width: 100%;

                    .jesuis__nav-icon:before {
                        display: none !important;
                    }
                }
            }
        }
    }
}
