.list-type-1--event {
    .list-type-1__picture,
    .list-type-1__wrapper-date,
    .list-type-1__wrapper {
        display: table-cell;
        vertical-align: top;
        @include breakpoint(small down) {
            display: block;
            vertical-align: inherit;
        }
    }
    .list-type-1__picture {
        float: none;

        img {
            max-width: 280px;
            @include breakpoint(small down) {
                margin-right: 0;
            }
        }
    }

    .list-type-1__teaser {
        font-size: 1.5rem;
        font-weight: $light;
    }

    .list-type-1__wrapper-date {
        width: 11rem;
        @include breakpoint(small down) {
            padding-right: 0;
            border-right: none;
            display: table;
            width: 100%;
            border-bottom: 1px solid $color-3--3;
            margin-bottom: 2em;
            .date-1 {
                text-align: left;
                time:nth-of-type(1) {
                    margin-left: 0;
                }
            }
        }
        .date-1 {
            margin-bottom: 0;
            margin-top: 2.5rem;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                display: block;
                right: 1rem;
                top: 1rem;
                width: 1px;
                height: 80px;
                border-left: 2px dotted #00ADA0;
                transform: skewX(-14deg);
            }
            time {
                &:only-of-type {
                    margin-top: 5rem;
                }
            }
            //time ~ time {
            //    margin-top: 0;
            //}
        }
    }
    .list-type-1__wrapper {
        padding-left: 2em;
        @include breakpoint(small down) {
            padding-left: 0;
        }

        .date-1,
        .hour-place {
            text-align: left;
            padding-bottom: 2em;
            .place,
            .hour {
                font-family: $typo-2;
                font-size: 1.5rem;
                font-weight: $light;
                font-style: normal;
                color: $color-2--3;
            }
        }
    }

    .agenda-item--focus {
        margin-bottom: 4.5rem;
        .agenda-item__category {
            &:before {
                color: $color-white;
            }
        }
    }
}
/*
@include breakpoint(medium down) {
    .list-type-1--event {
      .list-type-1__picture {
        img {
          margin-right: 2em;
        }
      }
      .list-type-1__wrapper-date {

        display: table-cell;
        vertical-align: top;
        width: 13em;
        border-bottom: 0;
        margin-bottom: 0;
        .date-1, .hour-place {
          width: auto;
          display: block;
          vertical-align: inherit;
          padding-bottom: 0;
        }
        .date-1 {
          text-align: center;
          time:nth-of-type(1) {
            margin-left: 1em;
          }
        }
      }
      .list-type-1__wrapper {
        padding-left: 2em;
      }
    }
}
*/
