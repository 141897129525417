.combobox-container {
    position: relative;
}

.combobox-suggestions {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
}

.combobox-suggestion {
    font-size: 1.2em;
    color: $color-black;
    background: $color-3--1;
    border-right: .1rem solid $color-3--2;
    border-bottom: .1rem solid $color-3--2;
    border-left: .1rem solid $color-3--2;
    padding: .5rem;
    &:first-child {
        border-top: .1rem solid $color-3--2;
    }
    &:hover, &:focus {
        background: $color-1--3;
        color: $color-white;
    }
    &:focus {
        outline: .1rem dotted $color-white;
        outline-offset: -.2rem;
    }
}

.combobox-clear-button {
    position: absolute;
    right: .8rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    font-family: inherit;
    font-size: 1.4em;
    color: $color-black;
    background: $color-3--1;
    border-radius: 50%;
    transition: background ease .3s, color ease .3s;
    &:hover, &:focus {
        background: $color-3--3;
        color: $color-white;
    }
}
