// @name site contact
.site-contact {
    position: relative;
    padding-left: 6rem;
    color: $color-white;
    @include breakpoint(medium only) {
        width: calc(35% - 6em) !important;
        &:after {
            content: none !important;
        }
    }
    .site-contact__img-wrap {
        position: absolute;
        width: 5.2em;
        z-index: 0;
        left: 0;
        top: 0;
        img {
            display: block;
            width: 100%;
        }
    }
    .site-contact__wrapper {
        overflow: hidden;
        h3 {
            font-family: $typo-2;
            text-transform: uppercase;
            font-weight: $light;
            letter-spacing: 0.3px;
            font-size: 1.8em;
            margin-bottom: 0.5em;
            strong {
                font-weight: $medium;
            }
        }
    }
    p {
        font-size: 1.4em;
        font-style: normal;
        font-weight: $light;
        margin: em(.5, 1.3) 0 em(.8, 1.3);
        &:first-child {
            margin-top: 0;
        }
    }
    a {
        color: $color-white;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    .site-contact__write,
    .site-contact__tel {
        float: left;
        display: block;
        margin-right: 1rem;
        font-size: 1.2rem;
        text-decoration: none !important;
        background-color: $color-white;
        padding: 0;
        border-radius: 50%;
        outline-color: $color-1--4;
        width: 30px;
        height: 30px;
        @include default-icons-absolute-before('\e02b', 1.1rem, $color-2--3, 50%, inherit, inherit, 50%);
        &:hover,
        &:focus {
            background-color: rgba($color-white, 0.8);
        }
        &:before {
            transform: translate(-50%, -50%);
        }
    }
    .site-contact__write {
        &:before {
            content: '\e0a5';
            color: $color-1--2;
        }
    }
}
