// @name cookies banner
.cookies-top-bloc {
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 15px;
    background: darken($color-3--3, 20%);
    width: 900px;
    margin: 0 auto;
    @include breakpoint(medium down) {
        width: 100%;
        bottom: 0;
    }
    .teaser-1 {
        margin-top: 0;
        margin-bottom: 2em;
        color: color-contrast(darken($color-3--3, 20%));
    }
}

.cookies-top-bloc__more {
    &:before {
        content: "\e003";
    }
}

.cookies-top-bloc__button-wrapper {
    text-align: right;
    @include breakpoint(medium down) {
        text-align: center;
    }
}

// @name cookie page
.cookie-manager {

}
