.banner {

    $img-small-height: 27rem;

    background-color: $color-5--5;
    position: relative;
    @include breakpoint(large) {
        margin-top: -12rem;
    }

    &__decor {
        overflow: hidden;
        position: absolute;
        //top: -5rem;
        //bottom: 1.5rem;
        bottom: -.3rem;
        display: block;
        width: 100%;
        //height: 1.5rem;
        height: 5.4rem;
        z-index: 100;
        //@include breakpoint(medium) {
            //top: -5rem;
            //bottom: 0;
        //}
        @include breakpoint(small down) {
            display: none;
        }
        &:after {
            content: '';
            position: absolute;
            width: 110%;
            left: 50%;
            height: 150px;
            background-color: $color-5--5;
            top: 0;
            z-index: 10;
            border-radius: 50%;
            transform: translateX(-50%);
            @include breakpoint(small down) {
                width: 200%;
            }
        }
    }
    &__item {
        width: 100%;
        position: relative;
        background-color: $color-5--5;
        @include breakpoint(medium) {
            height: 43rem;
            overflow: hidden;
        }
        @include breakpoint(large) {
            height: 76rem;
        }
        &--video {
            position: relative;
            &:after {
                content: none !important;
            }
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @extend %bg-pattern-white;
            @include breakpoint(small only) {
                height: $img-small-height;
            }
        }
        &-media {
            width: 100%;
            height: 100%;
            overflow: hidden;
            @include breakpoint(small only) {
                height: $img-small-height;
                position: relative;
                z-index: 5;
            }
        }
        img,
        video {
            min-width: 100%;
            width: auto;
            max-width: none;
            min-height: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            display: block;
        }
    }
    &__info {
        position: relative;
        z-index: 10;
        margin-top: -3.5rem;
        @include breakpoint(medium) {
            margin-top: 0;
            position: absolute;
            bottom: 10rem;
            right: 8rem;
            z-index: 10;
        }
        @include breakpoint(large) {
            bottom: initial;
            top: 40%;
            right: 15rem;

        }
        &-wrap {
            width: 45rem;
            @include breakpoint(small only) {
                width: 100%;
                padding: 1.1rem;
            }
            &:before {
                @include breakpoint(small only) {
                    content: '';
                    position: absolute;
                    width: 140%;
                    left: 50%;
                    height: 50px;
                    background-color: $color-5--5;
                    top: 2.5rem;
                    z-index: -1;
                    border-radius: 50%;
                    transform: translateX(-50%);
                }
            }
            .category,
            a {
                color: $color-white;
                text-transform: uppercase;
                @include breakpoint(medium) {
                    text-shadow: 0.5px 0.9px 3px rgba($color-black, 0.35);
                }
            }
            .category {
                display: inline-block;
                padding: 1rem 1.2rem;
                font-size: 1.4rem;
                background-color: rgba(#0064ac, 0.8);
                @include breakpoint(small only) {
                    margin-bottom: 0.7rem;
                    font-size: 1.3rem;
                    border-color: transparent;
                }
            }
            a {
                display: block;
                font-family: $typo-2;
                font-size: 2.5rem;
                line-height: 1.2;
                @include breakpoint(small only) {
                    color: $color-black;
                }
                @include breakpoint(medium) {
                    font-size: 3.5rem;
                }
                @include breakpoint(large) {
                    font-size: 4rem;
                }
            }
        }
    }
    .modeplay {
        position: absolute;
        cursor: pointer;
        z-index: 120;
        @include default-icons-before ('\e075', 0, 1.8em, $color-white, middle);
        @include breakpoint(small only) {
            right: 3rem;
            top: $img-small-height - 4;
        }
        @include breakpoint(medium) {
            bottom: 3rem;
            left: 3rem;
        }
        &:before {
            transition: all ease 0.2s;
        }
        &--pause:before {
            content: '\e076';
        }
        &:hover,
        &:focus {
            &:before {
                color: $color-4--1;
            }
        }
    }
    .prevCarrousel1,
    .nextCarrousel1 {
        position: absolute;
        z-index: 120;
        bottom: 40%;
        @include breakpoint(small only) {
            display: none;
        }
        button {
            @include default-icons-before ('\e016', 0, 6em, $color-white, middle);
            &:before {
                transition: all ease 0.2s;
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
            }
            &:hover,
            &:focus {
                &:before {
                    color: $color-4--1;
                }
            }
        }
    }
    .prevCarrousel1 {
        left: 3%;
    }
    .nextCarrousel1 {
        right: 3%;
        button:before {
            content: '\e017';
        }
    }
    .pagerCarrousel1 {
        position: absolute;
        left: 50%;
        bottom: 4.2rem;
        z-index: 120;
        transform: translateX(-50%);
        @include breakpoint(small only) {
            display: none;
        }
        li {
            float: left;
            &.cycle-pager-active {
                //button:before,
                //button:hover:before {
                //    color: $color-3--3;
                //}

                button {
                    background: $color-3--3;
                }
            }
        }
        button {
            $dot-size: 15px;
            //text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.12);
            margin: 2.5px;
            position: relative;
            border: 0;
            //background: transparent;
            background: $color-white;
            display: block;
            height: $dot-size;
            width: $dot-size;
            border-radius: 50%;
            //outline: none;
            box-shadow: 2px 2px 0 0 rgba($color-black, .3);
            line-height: 0;
            font-size: 0;
            color: transparent;
            cursor: pointer;
            transition: all 300ms ease;

            &:hover, &:focus {
                background: $color-3--3;
            }
            //&:before {
            //    position: absolute;
            //    top: 0;
            //    left: 0;
            //    content: "•";
            //    width: $dot-size;
            //    height: $dot-size;
            //    font-size: $dot-size * 3;
            //    line-height: $dot-size;
            //    color: $color-white;
            //    -webkit-font-smoothing: antialiased;
            //    -moz-osx-font-smoothing: grayscale;
            //    transition: all 0.15s ease-in-out;
            //}
        }
    }
}
