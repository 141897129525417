// @name Breadcrumb
.breadcrumb {
    $breadcrumb__font-size: 1.4;
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    //width: 70%;
    width: 100%;
    p {
        font-size: #{$breadcrumb__font-size}em;
        font-weight: $medium;
        color: $color-black;
        .breadcrumb__here {
            color: $color-black;
        }
        a {
            font-weight: $light;
            color: $color-black;
            @include default-icons-after('\e027', 0 0 0 .5rem, 1.2rem, $color-3--3, -.2rem);
            &:last-child {
                &:after {
                    color: $color-black;
                }
            }
        }
    }
}
