/* stylelint-disable */
#tarteaucitronRoot {
    .tarteaucitronH1 {
        font-size: 25px;
    }

    div#tarteaucitronInfo {
        font-size: 16px !important;

        a {
            font-size: 16px;
        }
    }

    #tarteaucitronPersonalize {
        background: $color-white;
        border: solid 1px rgba($color-3--2, 0.5);
        color: $color-black;

        @include on-event() {
            background: transparent;
            color: $color-white;
        }
    }

    #tarteaucitronAlertBig {

        #tarteaucitronCloseAlert {
            background: transparent;
            color: $color-white;
        }

        > button {
            @include breakpoint(small down) {
                margin: 5px;
            }
        }
    }

    #tarteaucitron #tarteaucitronServices {

        .tarteaucitronDetails {
            background: $color-black;
            display: block !important;
            max-width: inherit;
            padding: 10px 20px;
            position: static;
        }

        .tarteaucitronTitle {
            background: $color-black;

            button {
                margin-top: 0;
            }
        }
    }
}

#tarteaucitronDisclaimerAlert {
    display: block;
    margin: 0 0 10px !important;
    text-align: center !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
    box-sizing: border-box !important;
    padding: 20px 30px !important;
}

div#tarteaucitronInfo {
    position: inherit !important;
}

#tarteaucitronAlertSmall {
    left: 0;
    right: auto !important;
}

#fancyboxLine {
    display: none;
}

.ttc-open-cookie-manager-wrapper {
    display: none;
    text-align: center;
    margin-top: 30px;

    @include breakpoint(small down) {
        display: block;
    }
}

/* stylelint-enable */
