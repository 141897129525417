.sidebar {
    h2,
    &-title {
        font-family: $typo-2;
        color: $color-3--2;
        font-size: 2rem;
        padding-bottom: 1.2em;
        text-transform: uppercase;
        margin-top: 0;
    }
    h2 {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 1rem;
        &:not(:first-child):not(.sidebar-title) {
            margin-top: 3rem;
        }
        > *:first-child {
            margin-top: 0;
        }
    }
    #anchor-bar + .tools {
        margin-top: 3rem;
    }
}

#barsContainer {
    > button {
        position: relative;
        padding: 2rem 2.5rem 1.8rem 2.5rem;
        display: block;
        width: 100%;
        border-top: 0.5rem solid $color-3--1;
        text-align: left;
        transition: all 250ms ease;
        white-space: normal;
        > p {
            font-size: 1.3rem;
            text-align: left;
            font-family: $typo-1;
            font-weight: $light;
            color: $color-3--3;
        }
        &.is-current {
            > p {
                color: #4c4c4c;
                font-weight: $bold;
            }
        }
    }
}

.toc-title {
    display: none;
}

.toc-bar {
    position: absolute;
    left: 0;
    top: -.5rem;
    height: .5rem;
    background-color: $color-2--2;
}
