.page-head {

    .header {
        position: relative;
        margin-bottom: 3rem;
        @include breakpoint(medium down) {
            margin-bottom: 2rem;
        }
        @include breakpoint(small down) {
            margin-bottom: 0;
        }
    }

    .page-banner + .header {
        position: absolute;
        margin-bottom: 0;
    }

    .page-banner {
        &__picture {
            display: block;
            width: 100%;
            height: 55rem;
            overflow: hidden;
            @include breakpoint(medium down) {
                height: 27rem;
            }
            @include breakpoint(small down) {
                height: 15rem;
            }

            img {
                display: inline;
                width: inherit;
                min-width: 100%;
                max-width: none;
                height: inherit;
                min-height: 100%;
                max-height: none;
                object-fit: cover;
                font-family: 'object-fit: cover;';
            }
        }
    }
}
