.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
}

.ddm.ddm__content {
    margin: 4em 0 4em 0;
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: relative;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
    }
    &.ddm--active {
        .ddm__sub-level {
            padding: 1em 0;
            max-height: 100%;
            padding: 15px;
            @extend %bg-pattern-blue;
            &-wrap {
                background-color: $color-white;
                padding: 2.5em 3em;
            }
        }
    }
}

.ddm__content {
    .ddm__button {
        $click-roll__font-size: 2;
        display: block;
        width: 100%;
        text-align: left;
        font-size: #{$click-roll__font-size}em;
        font-family: $typo-3;
        font-weight: $light;
        background-color: $color-1--1;
        color: $color-3--3;
        padding: em(1.5, $click-roll__font-size) em(6, $click-roll__font-size) em(1.5, $click-roll__font-size) em(2, $click-roll__font-size);
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        @include default-icons-absolute-before('\e08a', 1rem, $color-white, 50%, 1.5rem, inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
            background-color: rgba($color-1--2, 0.2);
            border-radius: 50%;
            padding: 1.5em;
        }
    }
    &.ddm--active .ddm__button,
    .ddm__button:hover,
    .ddm__button:focus {
        background-color: $color-1--2;
        @extend %bg-pattern-blue;
        color: $color-white;
        &:before {
            background-color: rgba($color-1--1, 0.3);
        }
    }
    &.ddm--active .ddm__button {
        &:before {
            content: "\e023";
        }
    }

    &.ddm--active .ddm__button:hover,
    &.ddm--active .ddm__button:focus {
        color: $color-3--3;
        background-color: $color-1--1;

        &:before {
            background-color: rgba($color-1--2, 0.2);
        }
    }
}
