.agglo {
    .heading-block {
        h2 {
            color: $color-3--3;
        }
    }
}

.topicality {
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
    @include breakpoint(small only) {
        flex-direction: column;
    }
    &__info {
        display: flex;
        align-items: center;
        height: auto;
        min-height: 39rem;
        position: relative;
        background-color: $color-1--2;
        text-align: right;
        border-radius: 0 37% 37% 0 / 0 70% 70% 0;
        @include breakpoint(large) {
            width: 59rem;
            padding: 8rem 9rem 8.5rem 8rem;
        }
        @include breakpoint(medium down) {
            min-height: 25rem;
            //width: 43.5rem;
            width: 60%;
            padding: 4rem 9rem 4.5rem 2.5rem;
        }
        @include breakpoint(small only) {
            min-height: auto;
            width: 100%;
            padding: 2.5rem 1rem 6rem;
            text-align: left;
            border-radius: 0;
            &:before {
                width: 28rem;
                height: 28rem;
                content: '';
                position: absolute;
                bottom: -3rem;
                left: 50%;
                border-radius: 50%;
                transform: translateX(-50%);
                background-color: inherit;
                z-index: -1;
            }
        }
        &-wrap {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            z-index: 5;
            @include breakpoint(small only) {
                position: static;
                transform: translateY(0);
            }
        }
        .category {
            margin-bottom: 1rem;
            position: relative;
            color: $color-white;
            font-size: 1.2rem;
            font-weight: $bold;
            font-family: $typo-2;
            text-transform: uppercase;
            @include breakpoint(medium down) {
                margin-bottom: .5rem;
            }
            &:before {
                margin-right: 0.4rem;
                content: '//';
                color: $color-2--1;
                letter-spacing: -0.1rem;
            }
        }
        .title {
            margin: 0 0 1.2rem auto;
            color: #b9ded0;
            font-size: 2.8rem;
            font-weight: $bold;
            font-family: $typo-2;
            text-transform: uppercase;
            @include breakpoint(large) {
                width: 50%;
            }
            @include breakpoint(medium down) {
                font-size: 2rem;
                line-height: 1.2;
                width: 100%;
                margin-bottom: 1rem;
            }
            @include breakpoint(small down) {
                padding-right: 5rem;
            }
            a {
                color: #b9ded0;
            }
        }
        p:not(.category) {
            color: $color-white;
            font-size: 1.6rem;
            font-weight: $light;
            font-family: $typo-1;
            @include breakpoint(medium down) {
                font-size: 1.3rem;
                line-height: 1.2;
            }
            @include breakpoint(small down) {
                font-size: 1.4rem;
            }
        }
        & .plus {
            @include breakpoint(small only) {
                top: inherit;
                bottom: -4.5rem;
                right: 50%;
                transform: translateX(50%);
            }
        }
    }
    &__image {
        width: 65%;
        margin-left: auto;
        position: relative;
        z-index: 2;
        height: auto;
        @include breakpoint(medium down) {
            //min-height: 25rem;
            //width: calc(40% + 12rem);
            width: 60%;
        }
        @include breakpoint(small only) {
            width: 100%;
        }
        &-link {
            display: block;
            width: 100%;
            overflow: hidden;
            @include breakpoint(large) {
                min-height: 39rem;
            }
            @include breakpoint(medium down) {
                min-height: 25rem;
            }
            @include breakpoint(small down) {
                min-height: 20rem;
            }
        }
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            min-width: 100%;
            max-width: none;
            width: auto;
            min-height: 100%;
            max-height: none;
            height: auto;
            object-fit: cover;
            font-family: 'object-fit: cover;'
        }
    }
    &__video {
        width: 65%;
        margin-left: auto;
        position: relative;
        z-index: 2;
        height: auto;
        background: $color-black;
        //@include breakpoint(large) {
        //    min-height: 39rem;
        //}
        @include breakpoint(medium down) {
            //min-height: 25rem;
            //width: calc(40% + 12rem);
            width: 60%;
        }
        @include breakpoint(small only) {
            width: 100%;
        }
        &-link {
            display: block;
            width: 100%;
            position: relative;
            overflow: hidden;
            @include breakpoint(large) {
                min-height: 39rem;
            }
            @include breakpoint(medium down) {
                min-height: 25rem;
            }
            @include breakpoint(small down) {
                min-height: 20rem;
            }
        }
        video {
            width: auto;
            min-width: 100%;
            height: auto;
            min-height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            font-family: 'object-fit: cover;'
        }
        div.iframe {
            display: block;
            height: 388px;
            width: 75%;
            margin-left: auto;
            margin-right: 3%;
        }
    }
    &__hide {
        display: none;
    }
}

.innovations {
    display: flex;
    margin: 4rem -1rem 0;
    justify-content: center;
    @include breakpoint(small only) {
        flex-direction: column;
    }
    @include breakpoint(large) {
        margin-left: -1.7rem;
        margin-right: -1.7rem;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        text-align: center;
        margin: 0 1rem;
        position: relative;
        white-space: normal;
        @include breakpoint(small only) {
            padding: 2.5rem 4rem 2.5rem 2rem;
            margin-bottom: 3rem;
            margin-right: 5rem;
            flex-direction: row;
            text-align: left;
        }
        @include breakpoint(medium down) {
            padding: 1.5rem 3rem 4rem;
        }
        @include breakpoint(large) {
            padding: 2rem 3rem 2.5rem;
            margin: 0 1.7rem;
        }
        svg {
            width: 4.9rem;
            height: 5.2rem;
            fill: $color-white;
        }
        &-info {
            width: 100%;
            .title {
                margin: 0.3rem 0 0.5rem;
                font-size: 2.2rem;
                text-transform: uppercase;
                color: $color-3--3;
                font-weight: $light;
                font-family: $typo-2;
            }
            a {
                font-size: 1.4rem;
                font-family: $typo-1;
                font-weight: $light;
                color: $color-3--3;
                white-space: normal;
            }
            @include breakpoint(small only) {
                width: 80%;
                margin-left: 2rem;
            }
        }
        & .plus {
            z-index: 1;
            top: inherit;
            bottom: -1.6rem;
            right: 50%;
            background-color: inherit;
            transform: translateX(50%);
            box-shadow: inset 0px -18px 45px -27px rgba(0, 0, 0, 0.5);
            &:before {
                font-size: 1.6rem;
                color: $color-black;
            }
            &:hover,
            &:focus {
                background-color: inherit;
                opacity: 0.9;
            }
            @include breakpoint(small only) {
                box-shadow: none;
                right: -1.95rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    &__bg {
        transition: all 0.3s ease;
        &--blue {
            background-color: #91d4ef;
        }
        &--lightblue {
            background-color: #b5e1f8;
        }
        &--green {
            background-color: #96cdad;
        }
        &--lightgreen {
            background-color: #b9ded0;
        }
        &:hover,
        &:focus {
            opacity: 0.9;
            a {
                text-decoration: none;
            }
        }
    }
}

.cando {
    margin: 6rem 0 2rem;
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 1rem -1rem 0;
    }
    h3 {
        font-size: 3rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: $light;
        font-family: $typo-2;
        color: $color-3--3;
    }
    &__item {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        background: $color-5--5;
        background: linear-gradient(135deg, $color-5--5 0%, rgb(228,244,253) 50%, rgb(213,238,251) 100%);
        @include breakpoint(medium down) {
            padding: 1.8rem 2.5rem;
            flex-direction: row;
            justify-content: space-between;
            text-align: left;
        }
        @include breakpoint(large) {
            padding: 4rem 1.5rem 2rem;
            flex-direction: column;
            text-align: center;
        }
        &-wrap {
            margin: 1rem;
            padding: 0.8rem;
            @extend %bg-pattern-blue;
            @include breakpoint(small only) {
                width: calc(100% - 2rem);
            }
            @include breakpoint(medium down) {
                width: calc(50% - 2rem);
            }
            @include breakpoint(large) {
                width: calc(20% - 2rem);
            }
        }
        .title {
            color: $color-3--3;
            font-size: 1.6rem;
            font-family: $typo-1;
            font-weight: $light;
            white-space: normal;
            @include breakpoint(medium down) {
                margin-bottom: 0;
                flex-basis: 80%;
            }
            @include breakpoint(large) {
                margin-bottom: 1rem;
                width: 100%;
            }
        }
        & .plus {
            width: 2.1rem;
            height: 2.1rem;
            position: static;
            transform: translate(0);
            &:before {
                font-size: 1rem;
            }
            &-decor {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                &:before,
                &:after {
                    content: '';
                    width: 1.25rem;
                    height: 0.1rem;
                    position: absolute;
                    top: 50%;
                    background-color: #91d4ef;
                    transform: translateY(-50%);
                }
                &:before {
                    left: -1.25rem;
                }
                &:after {
                    right: -1.25rem;
                }
            }
        }
    }
}

.plus {
    width: 3.9rem;
    height: 3.9rem;
    display: block;
    position: absolute;
    right: -1.95rem;
    top: 50%;
    background-color: $color-2--2;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        content: '\e08a';
        font-family: 'icons-default';
        font-size: 1.2rem;
        color: $color-5--5;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
    }
    &:hover,
    &:focus {
        background-color: $color-2--3;
    }
}
