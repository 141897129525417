.contacter {
    margin: 6rem 0;
    &__wrap {
    }
    .list-document {
    }
}

.contacter-item {
    @extend  %bg-pattern-blue;
    @include breakpoint(small down) {
        padding-bottom: 1.5rem;
    }
    @include breakpoint(medium) {
        display: flex;
    }
    & + & {
        margin-top: 4rem;
    }
    &__image {
        display: block;
        text-align: center;
        img {
        }
    }
    &__content {
        margin: 1.5rem;
        background-color: $color-white;
        padding: 2rem;
        position: relative;
        backface-visibility: hidden;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100px;
            height: 120px;
            top: 50%;
            left: 0;
            margin: -60px 0 0 -40px;
            background: url("#{$path-images}ui/contacter-decor.png") no-repeat center center;
            @include breakpoint(small down) {
                transform: rotate(90deg);
                z-index: 0;
                top: 0;
                left: 50%;
                margin: -50px 0 0 -50px;
            }
        }
        @include breakpoint(small down) {
            margin-bottom: 0;
        }
        @include breakpoint(medium) {
            flex-grow: 1;
            padding-right: 20rem;
        }
        @include breakpoint(large) {
            padding-right: 24rem;
        }
        * {
            position: relative;
            z-index: 1;
        }
    }
    &__category {
        font-size: 1.2em;
    }
    &__title {
        font-size: 2em;
    }
    &__address {
        font-size: 1.5em;
        font-style: normal;
        line-height: 1.2;
        font-weight: $light;
        margin-bottom: 0.5em;
    }
    &__time {
        font-size: 1.5rem;
        font-weight: $light;
        font-family: $typo-1;
        @include default-icons-before('\e05b', 0 .5rem 0 0, 1.15em, $color-2--2, -.1rem);
    }
    &__actions {
        margin: 1rem 0 0;
        padding: 0;
        display: flex;
        @include breakpoint(medium) {
            position: absolute;
            right: 2rem;
            top: 2rem;
        }
        @include breakpoint(large) {
            right: 6rem;
        }
        li {
            + li {
                margin-left: 3px;
            }
        }
    }
    &__action {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        background-color: #CCC;
        border-radius: 50%;
        font-size: 1.2em;
        background-color: $color-2--2;
        transition: opacity 0.2s ease;
        @include default-icons-absolute-before('\e028', 1em, $color-white, 50%, inherit, inherit, 50%);
        &:before {
            transform: translate(-50%, -50%);
        }
        &:hover,
        &:focus {
            opacity: 0.8;
        }
        &--email {
            &:before {
                font-size: em(1.6, 1.2);
            }
        }
        &--tel {
            background-color: $color-1--2;
            &:before {
                content: '\e02b';
                font-size: em(1.4, 1.2);
            }
        }
        &--www {
            background-color: $color-1--3;
            &:before {
                content: 'www';
                font-weight: $light;
                line-height: 0;
                font-size: em(1, 1.2);
                font-family: $typo-1;
            }
        }
        &--plus {
            background-color: $color-1--1;
            &:before {
                content: '\e08a';
                font-size: em(1.8, 1.2);
            }
        }
    }
}
