.aller-plus {
    margin: 6rem 0;
    &__wrap {
        background-color: $color-1--2;
        @extend  %bg-pattern-white;
    }
    &__items {
        padding: 1.5rem 1rem;
        margin: 0;
        @include breakpoint(medium) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 4rem 1rem;
        }
        > * {
            margin: 0;
            padding: 0.5rem 0;
            @include breakpoint(medium) {
                width: 50%;
                padding: 0 2rem;
                flex: 0 0 50%;
                &:nth-child(odd) {
                    border-right: 1px solid rgba($color-white, 0.2);
                }
            }
        }
        a {
            margin-bottom: 0;
            overflow: visible;
            display: block;
            color: $color-white;
            font-size: 1.6rem;
            font-weight: $light;
            padding: 0.25rem 0.5rem 0.25rem 1.5rem;
            outline-color: $color-2--2;
            @include default-icons-absolute-before('\e01b', 1rem, $color-2--1, .6em, inherit, inherit, 0);
            @include breakpoint(medium) {
                padding: 0.5rem 0.5rem 0.5rem 1.5rem;
                &:before {
                    top: 0.8em;
                }
            }
            &:hover,
            &:focus {
                text-decoration: none;
                color: $color-2--2;
            }
        }
    }
}
