#wayzer {
    width: 100%;

    .iIyLDV {
        width: 100%;
    }
}

.services {
    background-color: $color-5--5;
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: relative;
    .title-1 {
        margin-top: 0;
        text-align: center;
        color: $color-3--3;
    }

    &__title {
        color: $color-1--2;
        font-family: $typo-2;
        font-size: 22px;
        font-weight: 300;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 1em;
        span {
            background-color: $color-5--5;
            position: relative;
            z-index: 2;
            padding-right: 1em;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            top: 50%;
            left: 0;
            background-color: $color-3--3;
            opacity: 0.1;
        }
    }
    &__wrap {
        @include breakpoint(large) {
            display: flex;
        }
    }
    &__left {
        width: 65%;
        @include breakpoint(medium down) {
            width: 100%;
        }
    }
    &__right {
        @include breakpoint(medium only) {
            display: flex;
            width: 100%;
            .service-agglo {
                width: 55%;
                flex-shrink: 0;
                margin-right: 2rem;
            }
            .service-item {
                height: calc(50% - 1rem);
                width: 100%;
                &--annuaire {
                    margin-top: 0;
                }
            }
        }
        @include breakpoint(large) {
            width: calc(35% - 40px);
            margin-left: 40px;
        }

        &-wrap {
            flex: 1 1 auto;
            width: 100%;
            @include breakpoint(medium only) {
                width: calc(45% - 2rem);
            }
        }
    }
}

.service-demarches {
    $demarcesh-circle-small: 60px;
    $demarcesh-circle-large: 105px;
    $transition: all 0.2s ease;
    position: relative;
    z-index: 20;
    &__list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        z-index: 18;
        @include breakpoint(medium) {
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 3rem;
        }
        @include breakpoint(large) {
            padding-bottom: 4rem;
        }
        li {
            width: 100%;
            min-height: 16rem;
            margin-bottom: 1em;
            position: relative;
            @include breakpoint(small only) {
                display: flex;
                align-items: center;
                min-height: auto;
            }
            @include breakpoint(medium) {
                text-align: center;
                width: 20%;
            }
            &.service-demarches__more {
                a {
                    background-color: $color-1--2;
                    text-decoration: none;
                    &:before {
                        content: attr(aria-label);
                        font-size: 1.2em;
                        font-weight: $light;
                        vertical-align: middle;
                        display: block;
                        @include breakpoint(small only) {
                            position: absolute;
                            color: $color-3--3;
                            font-size: 1.4em;
                            font-weight: 300;
                            left: 7rem;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        @include breakpoint(medium) {
                            font-family: $typo-2;
                            text-transform: uppercase;
                            margin-top: 3em;
                            color: $color-white;
                        }
                    }
                    &:hover,
                    &:focus {
                        background-color: $color-1--3;
                    }
                    span {
                        text-align: center;
                        @include default-icons-before('\e01b ', 0.5em, 1.2em, $color-2--2, -.1rem);
                        &:before {
                            @include breakpoint(small only) {
                                margin: 0;
                                line-height: $demarcesh-circle-small;
                                display: block;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        a,
        button {
            border-radius: 50%;
            background-color: $color-white;
            display: inline-block;
            width: $demarcesh-circle-small;
            height: $demarcesh-circle-small;
            transition: $transition;
            flex-shrink: 0;
            @include breakpoint(medium) {
                width: $demarcesh-circle-large;
                height: $demarcesh-circle-large;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                z-index: 5;
                top: 0;
                bottom: 0;
                width: $demarcesh-circle-small;
                @include breakpoint(small only) {
                    left: 0;
                    width: 100%;
                }
                @include breakpoint(medium) {
                    left: 50%;
                    transform: translateX(-50%);
                    width: $demarcesh-circle-large + 10;
                }
            }
        }
        button {
            box-shadow: 2px 3px 0 rgba($color-black, 0.12);
            $demarches-plus: 21px;
            &:hover,
            &:focus {
                background-color: $color-3--3;
                box-shadow: none;
                svg {
                    fill: $color-1--1;
                }
                &:before {
                    opacity: 1;
                }
                & + p {
                    text-decoration: underline;
                    color: $color-1--2;
                }
            }
            @include default-icons-absolute-before('\e08a', 1rem, $color-white, #{$demarcesh-circle-small - ($demarches-plus + 5)}, 50%, inherit, inherit);
            position: static;
            &:before {
                background-color: $color-2--2;
                width: $demarches-plus;
                height: $demarches-plus;
                line-height: $demarches-plus;
                text-align: center;
                border-radius: 50%;
                padding-left: 1px;
                opacity: 0;
                top: $demarcesh-circle-large - ($demarches-plus + 5);
                transform: translateX(#{($demarcesh-circle-small / 2) - 5});
                transition: opacity 200ms ease;
                @include breakpoint(medium) {
                    transform: translateX(#{($demarcesh-circle-large / 2) - 5});
                }
                @include breakpoint(small down) {
                    transform: none;
                    top: auto;
                    left: 4rem;
                    bottom: 0;
                }
            }
        }
        p {
            color: $color-3--3;
            font-size: 1.4em;
            font-weight: 300;
            margin: 1em;
            @include breakpoint(medium) {
                margin: 1em auto .5rem;
                max-width: $demarcesh-circle-large;
            }
        }
        svg {
            fill: $color-2--2;
            transition: $transition;
            width: 100%;
            height: 100%;
            max-width: 24px;
            max-height: 24px;
            @include breakpoint(medium) {
                max-width: 42px;
                max-height: 42px;
            }
        }
    }

    //&__modals {
    //}

    .demarches-modal {
        display: none;
        width: 100%;
        min-height: calc(100% - 8rem);
        background-color: $color-3--3;
        color: $color-white;
        padding: 4rem;
        position: absolute;
        top: 5rem;
        left: 0;
        z-index: 15;
        overflow: hidden;
        @include breakpoint(small down) {
            min-height: calc(100% - 6rem);
            padding: 2rem;
        }

        &.is-open {
            display: block;
            z-index: 25;
        }

        &__wrap {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            //position: relative;
            position: static;
            z-index: 16;
            @include breakpoint(small down) {
                display: block;
                //position: static;
            }
        }

        &-aside {
            flex: 0 1 29.5rem;
            width: 29.5rem;
            padding-right: 3.5rem;
            @include breakpoint(small down) {
                width: 100%;
                padding-right: 0;
            }

            h3, h2 {
                font-family: $typo-1;
                font-weight: $bold;
                font-size: 1.9rem;
                line-height: 2.2rem;
                color: $color-2--1;
                @include breakpoint(small down) {
                    padding-right: 5.5rem;
                }

                a {
                    color: inherit;
                    outline-color: $color-2--1;

                    &:hover, &:focus {
                        text-decoration: underline;
                    }

                    &:focus {
                        outline-color: $color-2--1;
                    }
                }
            }

            > p {
                font-family: $typo-1;
                font-weight: $light;
                font-size: 1.4rem;
                line-height: 1.2em;
                color: $color-white;
                margin: .5rem 0 0;
                @include breakpoint(small down) {
                    padding-right: 5.5rem;
                }
            }

            + .demarches-modal__content {
                @include breakpoint(small down) {
                    margin-top: 2rem;
                }
            }
        }

        &__img {
            display: block;
            margin-bottom: 1.1rem;

            img {
                display: block;
                width: 100%;
                @include breakpoint(small down) {
                    width: auto;
                    max-width: 100%;
                    min-width: 26rem;
                }
            }

            figcaption {
                display: block;
                font-family: $typo-2;
                font-weight: $bold;
                font-size: 1.2rem;
                line-height: 1.4rem;
                color: $color-white;
                text-transform: uppercase;
                text-align: left;
                padding-top: 2rem;
            }

            + h3, h2 {
                @include breakpoint(small down) {
                    padding-right: 0;
                }

                + p {
                    @include breakpoint(small down) {
                        padding-right: 0;
                    }
                }
            }

            + p {
                @include breakpoint(small down) {
                    padding-right: 0;
                }
            }
        }

        &__content {
            flex:  1 1 auto;
            width: calc(100% - 29.5rem);
            @include breakpoint(small down) {
                width: 100%;
            }
        }

        &__header {
            //padding-right: 3.5rem;
            padding-right: 8rem;
            padding-bottom: .6rem;
            border-bottom: 1px dashed $color-1--2;
            @include breakpoint(small down) {
                padding-right: 0;
            }

            h3, h1 {
                font-family: $typo-1;
                font-weight: $extralight;
                font-size: 2.2rem;
                line-height: 2.4rem;
                color: $color-white;
                text-transform: uppercase;
                margin: -0.2rem 0 1rem;
            }

            p {
                font-family: $typo-1;
                font-weight: $light;
                font-size: 1.5rem;
                line-height: 1.2em;
                color: $color-white;
                margin: 0 0 1rem;
            }

            + .demarches-modal__list {
                margin-top: 1.4rem;
            }
        }

        &__list {
            padding-right: 10rem;
            @include breakpoint(small down) {
                padding-right: 0;
            }

            li {
                position: relative;
                padding-left: 2rem;
                margin: 1.1rem 0;

                &:before {
                    content: '\e01b';
                    font-family: 'icons-default';
                    color: $color-2--1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 1.3rem;
                    line-height: 1.9rem;
                }

                a {
                    display: inline-block;
                    font-family: $typo-1;
                    font-weight: $thin;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    color: $color-white;
                    outline-color: $color-2--1;

                    &:hover, &:focus {
                        color: $color-2--1;
                        text-decoration: underline;
                    }

                    &:focus {
                        outline-color: $color-2--1;
                    }
                }
            }
        }

        &__decor {
            display: block;
            position: absolute;
            z-index: -1;
            //bottom: -4rem;
            //right: -3.5rem;
            bottom: 0;
            right: 0.5rem;
            //@include breakpoint(small down) {
            //    bottom: 0;
            //    right: 0.5rem;
            //}

            svg {
                width: 16.5rem;
                //width: 19rem;
                fill: #0b3f55;
            }

            img {
                display: block;
                width: 16.5rem;
            }
        }

        &__close {
            text-align: left;
            text-indent: -9999px;
            white-space: nowrap;
            position: absolute;
            top: 3rem;
            //top: -1rem;
            right: 4rem;
            //right: 0;
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            background: $color-1--5;
            outline-color: $color-2--1;
            transition: all 300ms ease;
            @include breakpoint(small down) {
                top: 2.5rem;
                right: 2.5rem;
            }
            &:before {
                content: "\e022";
                font-family: "icons-default";
                font-weight: normal;
                font-size: 2rem;
                line-height: 1em;
                color: $color-2--1;
                transition: all 300ms ease;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-indent: 0;
                display: block;
                margin: 0;
                padding-left: .1rem;
            }
            &:hover, &:focus {
                background: $color-2--1;
                &:before {
                    color: $color-1--5;
                }
            }
            &:focus {
                outline-color: $color-2--1;
            }
        }
    }

}

.service-meteo {
    margin-bottom: 2em;
    &__wrap {
        @include breakpoint(medium) {
            display: flex;
        }
    }
    .meteo-item {
        display: flex;
        align-items: center;
        position: relative;
        @include breakpoint(small only) {
            flex-wrap: wrap;
            margin-bottom: 2rem;
        }
        @include breakpoint(medium) {
            width: 45%;
            justify-content: space-between;
        }
        + .meteo-item {
            @include breakpoint(medium) {
                margin-left: 10%;
            }
            &:before {
                //@extend %bg-title-dot;
                @include breakpoint(medium) {
                    content: '••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••';
                    position: absolute;
                    left: -20%;
                    letter-spacing: 0.2px;
                    bottom: 30px;
                    width: 60px;
                    transform: rotate(103deg);
                    overflow: hidden;
                    color: $color-2--2;
                    //background-repeat: repeat-x;
                    //background-color: transparent;
                    //background-size: 5px;
                    margin-top: -1px;
                    //height: 3px;
                }
            }
        }
        &__day {
            font-weight: $bold;
            font-size: 1.5em;
            margin-right: 1em;
            @include breakpoint(small only) {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
        &__temp {
            height: 60px;
            position: relative;
            padding-left: 7rem;
            @include breakpoint(small only) {
                margin-right: 4rem;
            }
            img {
                width: 100%;
                //height: 100%;
                max-width: 120px;
                max-height: 85px;
                position: absolute;
                display: block;
                left: 0;
                top: 0;
            }
            p {
                line-height: 1;
            }
            small {
                font-size: 0.5em;
                vertical-align: super;
            }
            &-main {
                font-size: 3rem;
                font-weight: $normal;
                color: $color-1--2;
                margin-top: 0.5rem;
            }
            &-secondary {
                font-size: 2rem;
                font-weight: $light;
            }
        }
        ul {
            color: $color-3--3;
            font-style: italic;
            font-size: 1.3em;
        }
    }

    &__source {
        text-align: right;
        margin-top: 1em;
        a {
            font-size: 1.2em;
            text-transform: uppercase;
            font-family: $typo-2;
            color: $color-3--5;
            @include default-icons-after('\e01b', 0 0 0 0.5rem, 1em, $color-2--2, -.1rem);
        }
    }
}

.service-agglo,
.service-item {
    color: $color-white;
    position: relative;
    background-color: $color-1--2;
    @extend %bg-pattern-white;
    a {
        outline-color: $color-white;
    }
}

.service-agglo {

    $this: &;

    padding: 1.5em 3em;
    @include breakpoint(medium) {
        padding-left: 12em;
    }
    @include breakpoint(large) {
        margin-top: 1rem;
    }
    img {
        top: -0.5em;
        left: 1.5em;
        position: absolute;
        @include breakpoint(small only) {
            display: none;
        }
    }
    &__title,
    &__subtitle {
        text-transform: uppercase;
        font-family: $typo-2;
        font-weight: $light;
    }
    &__title {
        font-size: 3em;
        white-space: nowrap;
        strong {
            font-weight: $medium;
        }
    }
    &__subtitle {
        font-size: 1.8em;
        letter-spacing: 0.36px;
        margin-bottom: 1em;
    }
    &__description {
        font-size: 1.5em;
        margin-bottom: 0.75em;
        font-weight: $thin;
    }
    &__actions {
        display: flex;
        max-width: 100%;
        a {
            border: 1px solid $color-2--1;
            font-family: $typo-2;
            font-size: 1.3em;
            font-weight: $bold;
            color: $color-white;
            text-transform: uppercase;
            background: none;
            padding: 1rem 1rem 1rem 5rem;
            text-decoration: none;
            transition: all 0.2s ease-in-out;
            outline-color: $color-white;
            position: relative;
            overflow: hidden;
            &:hover,
            &:focus {
                background-color: rgba($color-2--1, 0.5);
            }
            //@include default-icons-absolute-before('\e02b', 1.4rem, $color-2--3, 50%, inherit, inherit, 0.75rem);
            //&:before {
            //    background-color: $color-white;
            //    width: 3rem;
            //    height: 3rem;
            //    line-height: 3rem;
            //    margin-top: -1.5rem;
            //    text-align: center;
            //    padding-left: 1px;
            //    border-radius: 50%;
            //}
            .service-agglo__button-icon {
                display: block;
                width: 3rem;
                height: 3rem;
                margin-top: -1.5rem;
                line-height: 2.8rem;
                text-align: center;
                position: absolute;
                top: 50%;
                left: .9rem;

                img {
                    display: inline;
                    vertical-align: middle;
                    line-height: .1rem;
                    position: static;
                    top: auto;
                    left: auto;
                    height: auto;
                    max-height: 3rem;
                    width: auto;
                    max-width: 100%;
                }
            }
            &#{$this}__telephone {
                //&:before {
                //    content: '\e02b';
                //}
            }
            &#{$this}__enligne {
                margin-left: 8px;
                //&:before {
                //    content: '\e00b';
                //    color: $color-1--2;
                //    font-family: 'icons-project';
                //}
            }
        }
    }
    &__questions {
        display: block;
        margin-top: 1em;
        font-size: 1.2em;
        font-weight: $light;
        text-transform: uppercase;
        font-family: $typo-2;
        color: $color-white;
        @include default-icons-before('\e01b', 0.5rem 0 0 0, 1em, $color-2--2, -.1rem);
    }
}

.service-item {
    padding: 1rem 8rem;
    margin-top: 2rem;
    color: $color-white;
    text-transform: uppercase;
    font-family: $typo-2;
    font-weight: $light;
    display: flex;
    align-items: center;
    @include breakpoint(medium) {
        padding: 1rem 10rem;
    }
    @include breakpoint(large) {
        padding: 1rem 8rem 1rem 12rem;
        height: 105px;
    }
    &--annuaire {}
    &--cartographie {
        svg {
            margin-top: -0.5rem;
        }
    }
    &__description {
        align-items: center;
    }
    svg {
        position: absolute;
        display: block;
        left: 1rem;
        top: 0;
        max-width: 6rem;
        width: 100%;
        height: 100%;
        @include breakpoint(medium) {
            left: 2rem;
            max-width: 8rem;
            //max-height: 90px;
        }
        @include breakpoint(large) {
            //left: 3rem;
        }
    }
    h3 {
        font-weight: $medium;
        font-size: 2.3em;
        margin-top: 0;
        a {
            color: inherit;
        }
    }
    p {
        font-size: 1.2em;
    }
}
