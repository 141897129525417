.telecharger {
    margin: 6rem 0;
    &__wrap {
        background-color: $color-1--2;
        @extend %bg-pattern-white;
    }
    .list-document {
        &__listitems {
            padding: 1.5rem 0;
            margin: 0;
            @include breakpoint(medium) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                padding: 4rem 1rem;
            }
            > * {
                margin: 0;
                padding: 0.5rem 0;
                @include breakpoint(medium) {
                    width: 50%;
                    padding: 0 2rem;
                    flex: 0 0 50%;
                    &:nth-child(odd) {
                        border-right: 1px solid rgba($color-white, 0.2);
                    }
                }
            }
        }
        &__title-file {
            margin-bottom: 0;
            overflow: visible;
            display: block;
            color: $color-white;
            font-size: 1.6rem;
            font-weight: $light;
            word-break: break-all;;
            min-height: 5rem;
            padding: 1.5rem 0.5rem 1.5rem 5rem;
            outline-color: $color-2--2;
            &:before {
                top: 50%;
                transform: translateY(-50%);
                left: 1rem;
            }
            &:hover,
            &:focus {
                text-decoration: none;
                color: $color-2--2;
            }
            .mo {
                font-size: 1.2rem;
            }
        }
    }
}
