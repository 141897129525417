.profile {

    &__link {
        display: inline-block;
        height: 8rem;
        line-height: 8rem;
        position: relative;
        color: $color-3--2;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: $light;
        transform: skewX(-13.6deg);
        font-family: $typo-2;
        width: 13.2rem;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2rem 0 3.5rem;
        text-align: center;
        @media(min-width: 1280px) and (max-width: 1600px) {
            padding-right: .8rem;
            width: auto;
        }

        &:hover, &:focus {
            text-decoration: none;
            background-color: $color-1--2;
            color: $color-white;
            svg {
                path {
                    stroke: $color-white;
                }
            }
        }
    }

    &__link-inner {
        transform: skewX(13.6deg);
        display: block;
        position: relative;
    }

    &__icon {
        position: absolute;
        display: block;
        left: -2.5rem;
        width: 2.1rem;
        height: 2.1rem;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;

        svg {
            line-height: 1;
            fill: none;
            stroke-width: 20px;
            stroke: $color-5--3;
        }
    }

    &__name {
        display: block;
        width: 7.7rem;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.mobile-profile {
    display: none;
    @include breakpoint(medium down) {
        display: block;
        width: 100%;
        background-color: $color-white;
        border-bottom: 1px dotted $color-3--2;
        padding: 0;

        .profile__link {
            display: block;
            width: 100%;
            padding: 0 1rem;
            color: $color-3--2;
            text-align: center;
            transform: none;
            background-color: $color-white;
            transition: background-color 300ms ease;
            outline-color: $color-white;
            position: relative;
            z-index: 2;

            &:hover, &:focus {
                background-color: $color-1--2;

                .profile__icon svg {
                    stroke: $color-white;
                }

                .profile__name {
                    color: $color-white;
                }
            }

            &:focus {
                outline-color: $color-white;
            }
        }

        .profile__link-inner {
            transform: none;
            //display: inline-block;
            //vertical-align: top;
            //white-space: nowrap;
            //width: 100%;
            //max-width: 100%;
            line-height: 8rem;
            text-align: center;
        }

        .profile__icon {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            position: relative;
            left: auto;
            top: auto;
            transform: none;
            margin-right: .4rem;

            svg {
                stroke: rgba($color-3--2, .6);
            }
        }

        .profile__name {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: calc(100% - 3rem);
        }
    }
}

