%titles-general {
    font-family: $typo-2;
    text-transform: uppercase;
    font-weight: $light;
    line-height: 1em;
}

.heading-block {
    position: relative;
    color: $color-1--2;
    margin: 4em 0 3em;
    text-align: center;
    font-family: $typo-2;
    h2,
    h3 {
        display: inline-block;
        padding: 0.25em 0.5em;
        margin: 0 !important;
        background-color: $color-white;
        position: relative;
        z-index: 10;
        a {
            color: inherit;
        }
    }
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-2--1;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
    }
    &--center {
        &:after {
            @extend %bg-title-dot;
            background-repeat: repeat-x;
            background-color: transparent;
            background-size: 5px;
            margin-top: -1px;
            height: 3px;
        }
        h2,
        h3 {
            position: relative;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                content: '';
                top: 50%;
                width: 45px;
                height: 10px;
                margin-top: -5px;
                @extend %bg-title-dots;
                background-repeat: no-repeat;
                background-position: 100% 0;
                background-color: #fff;
            }
            &:before {
                right: 100%;
                transform: scaleX(-1);
            }
            &:after {
                left: 100%;
            }
        }
    }
    &-subtitle {
        text-transform: uppercase;
        text-align: center;
        font-size: 1.2em;
        position: absolute;
        font-weight: $light;
        width: 100%;
        a {
            color: $color-3--2;
            margin: 0 5px;
            &:before {
                content: '/ ';
            }
        }
    }
}

// @name Title 1
// @description Styling for title 1
.title-1 {
    $title--1__font-size-small: 2.8;
    $title--1__font-size-medium: 3;
    $title--1__font-size-large: 4.4;
    font-size: #{$title--1__font-size-small}em;
    margin: em(3, $title--1__font-size-small) 0 em(2, $title--1__font-size-small);
    @extend %titles-general;
    @include breakpoint(medium) {
        font-size: #{$title--1__font-size-medium}em;
    }
    @include breakpoint(large) {
        font-size: #{$title--1__font-size-large}em;
        margin: em(2.5, $title--1__font-size-large) 0 em(1.5, $title--1__font-size-large);
    }
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size-small: 2.8;
$title--2__font-size-large: 3;
.title-2 {
    font-size: #{$title--2__font-size-small}em;
    margin: em(2.5, $title--2__font-size-small) 0 em(1.5, $title--2__font-size-small);
    @include breakpoint(medium) {
        font-size: #{$title--2__font-size-large}em;
    }
    @extend %titles-general;
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 1.8;
.title-3 {
    font-size: #{$title--3__font-size}em;
    color: $color-3--3;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    padding-bottom: em(1.6, $title--3__font-size);
    border-bottom: 1px solid $color-3--2;
    @extend %titles-general;
}
