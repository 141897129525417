.go-to-top {
    margin-top: -1em;
    display: block;
    width: 6rem;
    opacity: 1;
    z-index: 7000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    p {
        text-align: right;
    }
    &.hidden {
        visibility: hidden;
        opacity: 0;
        margin: 0;

        .button-1 {
            display: none;
        }
    }
    &.fixed {
        position: fixed;
        right: 1%;
        bottom: 0;
    }
    &.static {
        position: absolute;
        right: 1%;
    }
    .button-1 {
        width: 6rem;
        height: 6rem;
        background-color: $color-3--3;
        border-radius: 50%;
        overflow: hidden;
        &:before {
            content: "\e015";
            font-size: 2rem;
        }
    }
}
