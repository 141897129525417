.menu-main-1__sublevel {
    display: none;
    &[aria-hidden="false"] {
        display: block;
    }
}

//
.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: block;
        @include breakpoint(medium down) {
            display: none;
            &[aria-hidden="false"] {
                display: block;
            }
        }
    }
}

@include breakpoint(medium down) {
    body.menu-main-1--active {
        overflow-y: hidden;
        .menu-main-1__listitems-0 {
            > .menu-main-1__item {
                overflow-y: auto;
                height: 100%;
            }
        }
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    //position: relative;
    position: static;
    @include breakpoint(medium down) {
        z-index: 300;
        //position: static;
        position: relative;
    }
}

.menu-main-1 {
    //@extend .wrapper-main;
    width: 100%;
    @include breakpoint(medium down) {
        .wrapper-main {
            width: 100%;
            padding: 0;
            margin: 0;
        }
    }
}

@include breakpoint(medium down) {
    body.menu-main-1--active {
        overflow-y: hidden;
        .menu-main-1__listitems-0 {
            > .menu-main-1__item {
                overflow-y: auto;
                height: 100%;
            }
        }
    }
}

.menu-main-1__item {
    > .menu-main-1__header {
        a {
            display: block;
        }
    }
    > .menu-main-1__sublevel {

    }
    //&.menu-main-1__item--current {
    //    text-decoration: underline;
    //}
}

.menu-main-1__closing-button {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    width: 35px;
    height: 35px;
    background: transparent;
    outline-color: $color-1--2;
    transition: all 300ms ease;
    &:before {
        content: "\e022";
        font-family: "icons-default";
        font-weight: normal;
        font-size: 3rem;
        line-height: 1em;
        color: $color-2--2;
        transition: all 300ms ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-indent: 0;
        display: block;
        margin: 0;
        padding-left: .2rem;
    }
    &:hover, &:focus {
        &:before {
            color: $color-1--2;
        }
    }
    &:focus {
        outline-color: $color-1--2;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        @include breakpoint(medium down) {
            //position: absolute;
            position: fixed;
            z-index: 101;
            top: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            transition: transform ease .3s;
            transform: translate(100%, 0);
            width: 100%;
            background: rgba($color-black, .5);
        }
        &.menu-main-1__item--active {
            @include breakpoint(medium down) {
                transform: translate(0, 0);
                > .menu-main-1__header {
                    .menu-main-1__action {
                        left: .8rem;
                    }
                }
            }
        }
        > .menu-main-1__header {
            display: none;
            @include breakpoint(medium down) {
                display: block;
                background: $color-white;
                padding: 2.5rem 0;
                border-bottom: 1px dotted $color-3--2;
                > span {
                    display: block;
                    text-transform: uppercase;
                    font-family: $typo-2;
                    font-weight: $normal;
                    font-size: 1.9em;
                    line-height: 2rem;
                    color: $color-3--3;
                    padding: 0 0 0 4.7rem;
                }
                .menu-main-1__action {
                    text-align: left;
                    text-indent: -9999px;
                    white-space: nowrap;
                    position: absolute;
                    top: 1.5rem;
                    left: -5rem;
                    width: 4rem;
                    height: 4rem;
                    background: transparent;
                    transition: left ease .3s;
                    cursor: pointer;
                    outline-color: $color-2--2;
                    &:before {
                        content: "\e02f";
                        font-family: "icons-default";
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 1em;
                        color: $color-2--2;
                        transition: all 300ms ease;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-indent: 0;
                        display: block;
                        margin: 0;
                    }
                    &:focus {
                        outline-color: $color-2--2;
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            @include breakpoint(medium down) {
                display: none;
            }
            &[aria-hidden="false"] {
                @include breakpoint(medium down) {
                    display: block;
                }
            }
            > .menu-main-1__closing-button {
                display: none;
                @include breakpoint(medium down) {
                    display: block;
                    position: absolute;
                    top: 1.7rem;
                    right: 1.9rem;
                }
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding-left: 4rem;
    @media(min-width: 1280px) and (max-width: 1600px) {
        padding-left: 2rem;
    }
    @include breakpoint(medium down) {
        display: block;
        padding-left: 0;
        background: $color-white;
        position: relative;
        z-index: 10;
    }
    .wrapper-main {
        @include breakpoint(medium down) {
            max-width: none;
        }
    }
    > li {
        display: block;
        @include breakpoint(medium down) {
            border-bottom: 1px dotted $color-3--2;
        }

        //&:first-child > .menu-main-1__header:after {
        &:nth-child(3) > .menu-main-1__header:after {
            display: none;
            @include breakpoint(medium down) {
                display: block;
            }
        }

        &.menu-main-1__item--active {
            > .menu-main-1__header {

                &:before {
                    visibility: visible;
                    opacity: 1;
                    transform: skewX(13.6deg) scale(1);
                    transition: transform 200ms 400ms ease;
                }

                &:after {
                    @include breakpoint(medium down) {
                        visibility: visible;
                        opacity: .3;
                    }
                }

                > a {
                    &:before {
                        //visibility: visible;
                        //opacity: 1;
                        //transform: skewX(13.6deg) scale(1);
                        //transition: transform 200ms 400ms  ease;
                        @include breakpoint(medium down) {
                            content: '\e023';
                            background-color: $color-white;
                            color: $color-2--2;
                            transform: scale(1);
                            transition: all 300ms ease;
                        }
                    }

                    &:after {
                        background-color: $color-1--2;
                        height: calc(100% + 15px);
                        transition: background-color 300ms ease, height 500ms 200ms ease;
                        @include breakpoint(medium down) {
                            height: 100%;
                            transition: all 300ms ease;
                        }
                    }

                    span {
                        color: $color-white;
                        transition: none;
                    }
                }
            }
        }

        > .menu-main-1__header {
            position: relative;
            z-index: 15;
            transform: skewX(-13.6deg);
            @include breakpoint(medium down) {
                transform: none;
            }

            &:before {
                content: '';
                visibility: hidden;
                opacity: 0;
                width: 54rem;
                height: 1.5rem;
                background: transparent url("../Images/custom/menu-item-shadow.png") center no-repeat;
                position: absolute;
                //top: calc(100% - 1.5rem);
                top: 100%;
                left: calc(50% - 29rem);
                transform: skewX(13.6deg) scale(0);
                z-index: -3;
                @include breakpoint(medium down) {
                    display: none;
                }
            }

            &:after {
                content: '';
                position: absolute;
                left: -2px;
                bottom: 0;
                border-right: 2px dotted $color-5--1;
                height: 50%;
                @include breakpoint(medium down) {
                    display: block;
                    width: 100%;
                    height: .1rem;
                    //height: 1.5rem;
                    //background: transparent url("../Images/custom/menu-item-shadow.png") top center no-repeat;
                    //background-size: cover;
                    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
                    top: 100%;
                    left: 0;
                    visibility: hidden;
                    opacity: 0;
                    z-index: -3;

                }
            }

            > a {
                font-family: $typo-2;
                font-weight: $normal;
                color: $color-3--3;
                font-size: 2rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                display: block;
                padding: 2.9rem 4.3rem;
                //transform: skewX(-13.6deg);
                text-decoration: none;
                position: relative;
                transition: all 300ms ease;
                outline-color: $color-1--2;
                @media(min-width: 1280px) and (max-width: 1600px) {
                    padding-left: 2.3rem;
                    padding-right: 2.3rem;
                }
                @include breakpoint(medium down) {
                    padding: 3.1rem 6rem 3.1rem 2.8rem;
                    outline-color: $color-white;
                }

                &:hover, &:focus {
                    text-decoration: none;
                    color: $color-white;

                    &:before {
                        @include breakpoint(medium down) {
                            background-color: $color-white;
                            color: $color-2--2;
                        }
                    }

                    &:after {
                        background-color: $color-1--2;
                    }
                }

                &:focus {
                    outline-color: $color-1--2;
                    @include breakpoint(medium down) {
                        outline-color: $color-white;
                    }
                }

                > span {
                    display: block;
                    transform: skewX(13.6deg);
                    position: relative;
                    z-index: -1;
                    @include breakpoint(medium down) {
                        transform: none;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -2;
                    transition: background-color 300ms ease, height 0ms ease;
                }

                &:before {
                    //content: '';
                    //visibility: hidden;
                    //opacity: 0;
                    //width: 54rem;
                    //height: 1.5rem;
                    //background: transparent url("../Images/custom/menu-item-shadow.png") center no-repeat;
                    //position: absolute;
                    ////top: calc(100% - 1.5rem);
                    //top: 100%;
                    //left: calc(50% - 29rem);
                    //transform: skewX(13.6deg) scale(0);
                    //z-index: -3;
                    @include breakpoint(medium down) {
                        content: "\e08a";
                        font-family: "icons-default";
                        font-weight: normal;
                        font-size: 1.2rem;
                        line-height: 2.1rem;
                        color: $color-white;
                        text-align: center;
                        transition: all 300ms ease;
                        display: block;
                        width: 2.1rem;
                        height: 2.1rem;
                        border-radius: 50%;
                        margin: 0;
                        background-color: $color-2--2;
                        position: absolute;
                        top: 50%;
                        left: auto;
                        right: 2rem;
                        z-index: -1;
                        margin-top: -1rem;
                        visibility: visible;
                        opacity: 1;
                        transform: scale(1);
                        background-image: none;
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            transition: visibility ease .2s, max-height ease .2s;
            @include breakpoint(medium down) {
                position: static;
                max-height: inherit;
                display: none;
            }
            // level 1 Displayed
            &[aria-hidden="false"] {
                visibility: visible;
                max-height: 3000px;
                @include breakpoint(medium down) {
                    display: block;
                    max-height: inherit;
                }
                .menu-main-1__listitems-1__wrapper {
                    transform: translateY(0);
                    transition: transform ease-out .4s .2s;
                    @include breakpoint(medium down) {
                        transform: inherit;
                        transition: inherit;
                        padding: 2rem 1rem 1rem;
                    }
                }
            }
            .menu-main-1__listitems-1__wrapper {
                padding: 5.8em 0 1.5em;
                transform: translateY(-110%);
                transition: transform ease-in .2s;
                height: 100%;
                background: $color-1--2;
                @include breakpoint(medium down) {
                    background: $color-1--2;
                    transform: inherit;
                    height: auto;
                    padding: 0;
                }
            }
            .menu-main-1__closing-button {
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                position: absolute;
                top: 2rem;
                left: calc(50% + 57.2rem);
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: $color-1--6;
                //outline-color: $color-2--1;
                outline-color: $color-white;
                transition: all 300ms ease;
                overflow: hidden;
                @include breakpoint(medium down) {
                    display: none;
                }
                &:before {
                    content: "\e022";
                    font-family: "icons-default";
                    font-weight: normal;
                    font-size: 2.2rem;
                    line-height: 1em;
                    color: $color-2--1;
                    transition: all 300ms ease;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-indent: 0;
                    display: block;
                    margin: 0;
                    padding-left: .2rem;
                }
                &:hover, &:focus {
                    &:before {
                        color: $color-white;
                    }
                }
                &:focus {
                    //outline-color: $color-2--1;
                    outline-color: $color-white;
                }
            }
        }
    }
}

// Level 2
.menu-main-1__listitems-2 {
    display: inline-block;
    vertical-align: top;
    width: 33.333%;
    margin: 0 -.5rem 0 0;
    //width: 39rem;
    //width: 100%;
    position: relative;
    height: auto;
    transition: height ease .3s;
    //margin: 0 -1rem;
    //margin: 0;
    @extend .clear-fix;
    @include breakpoint(medium down) {
        width: 100%;
        margin: 0;
        //padding: 2rem 1rem;
        padding: 0;
    }

    > li {
        //display: inline-block;
        //vertical-align: top;
        //width: 33.333%;
        //margin-right: -.4rem;
        margin: 0 -1rem;
        padding: 0 1rem 1.6rem;
        display: block;
        width: 100%;
        @include breakpoint(medium down) {
            display: block;
            width: 100%;
            //margin: 1rem 0;
            margin: 0 0 1rem;
            padding: 0;
        }

        > .menu-main-1__header {
            position: relative;
            z-index: 17;
            > a {
                font-family: $typo-2;
                font-weight: $bold;
                font-size: 1.8rem;
                line-height: 2rem;
                padding: .8rem 4rem .8rem 1rem;
                text-transform: uppercase;
                color: $color-white;
                display: block;
                position: relative;
                transition: all 300ms ease;
                margin-bottom: .5rem;
                outline-color: $color-1--1;
                @include breakpoint(medium down) {
                    padding: .8rem 1rem .8rem 2rem;
                    margin-right: 3.6rem;
                    margin-bottom: 0;
                    z-index: 5;
                }

                &:after {
                    content: '\e01b';
                    font-family: 'icons-default';
                    display: block;
                    position: absolute;
                    right: 2rem;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 1.4rem;
                    color: $color-1--2;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 300ms ease;
                    @include breakpoint(medium down) {
                        display: none;
                    }
                }

                &:hover, &:focus {
                    background-color: $color-1--3;
                    text-decoration: none;

                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &:focus {
                    outline-color: $color-1--1;
                }
            }

            > .menu-main-1__action {
                display: none;
                @include breakpoint(medium down) {
                    display: block;
                    position: absolute;
                    z-index: 3;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    text-align: left;
                    text-indent: -9999px;
                    white-space: nowrap;
                    background: transparent;
                    overflow: hidden;
                    transition: all 300ms ease;
                    outline-color: $color-1--1;
                }

                &:hover, &:focus {
                    background-color: $color-1--3;

                    &:before {
                        color: $color-1--2;
                    }
                }

                &:focus {
                    outline-color: $color-1--1;
                }

                &:before {
                    @include breakpoint(medium down) {
                        content: '\e01b';
                        font-family: 'icons-default';
                        font-size: 1.4rem;
                        color: $color-white;
                        text-indent: 0;
                        text-align: center;
                        display: block;
                        width: 3.6rem;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: all 300ms ease;
                    }
                }
                &[aria-expanded="true"] {
                    background-color: $color-1--3;
                    &:before {
                        @include breakpoint(medium down) {
                            content: "\e018";
                            color: $color-1--2;
                        }
                    }
                }
            }
        }

        > .menu-main-1__sublevel {
            display: block;
            //position: absolute;
            //left: 100%;
            //top: 0;
            //width: calc(118rem - 78rem);
            //visibility: hidden;
            //transform: scaleX(0);
            //transform-origin: 0 0;
            //transition: transform ease .2s, visibility ease 0s .2s;
            //background: darken($color-1--2, 5%);
            @include breakpoint(medium down) {
                display: none;
                position: static;
                width: 100%;
                transform: inherit;
                height: 0;
                transition: inherit;
            }
            &[aria-hidden="false"] {
                //visibility: visible;
                //transform: scaleX(1);
                //transition: transform ease .4s, -webkit-transform ease .4s;
                @include breakpoint(medium down) {
                    display: block;
                    transform: inherit;
                    height: auto;
                    transition: inherit;
                }
            }
        }
    }

    .menu-main-1__closing-button {
        display: none;
    }
}

// Level 3
.menu-main-1__listitems-3 {
    padding-left: 2em;
    @include breakpoint(medium down) {
        padding: .4rem 0 .2rem 2.6rem;
    }
    > li {
        margin: 0.1rem 0;
        @include breakpoint(medium down) {
            margin: .2rem 0;
        }
        > .menu-main-1__header {
            a {
                font-family: $typo-1;
                font-weight: $light;
                font-size: 1.4rem;
                line-height: 1.6rem;
                padding-left: 1.5rem;
                color: $color-white;
                display: block;
                position: relative;
                transition: all 300ms ease;
                outline-color: $color-white;

                &:before {
                    content: '\e01b';
                    font-family: 'icons-default';
                    color: $color-2--1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 1rem;
                    line-height: 1.6rem;
                }

                &:hover, &:focus {
                    font-weight: $bold;
                    text-decoration: underline;

                    &:before {
                        color: $color-white;
                    }
                }
            }
        }

        //> .menu-main-1__sublevel {
        //    display: block;
        //    position: absolute;
        //    left: 100%;
        //    top: 0;
        //    width: calc(118rem - 78rem);
        //    visibility: hidden;
        //    transform: scaleX(0);
        //    transform-origin: 0 0;
        //    transition: transform ease .2s, visibility ease 0s .2s;
        //    background: darken($color-1--2, 10%);
        //    @include breakpoint(medium down) {
        //        display: none;
        //        position: static;
        //        width: 100%;
        //        transform: inherit;
        //        height: 0;
        //        transition: inherit;
        //        background: darken($color-1--2, 15%);
        //    }
        //    &[aria-hidden="false"] {
        //        transition: transform ease .4s, visibility ease 0s 0s;
        //        visibility: visible;
        //        transform: scaleX(1);
        //        @include breakpoint(medium down) {
        //            display: block;
        //            transform: inherit;
        //            height: auto;
        //            transition: inherit;
        //        }
        //    }
        //}
    }
}

// Level 4
//.menu-main-1__listitems-4 {
//    padding: 1em;
//    @include breakpoint(medium down) {
//        padding: .5em 0;
//    }
//    > li {
//        margin: 0.8em 0;
//        > .menu-main-1__header {
//            a {
//                font-size: 1.5em;
//                @include breakpoint(medium down) {
//                    padding-top: .3em;
//                    padding-bottom: .3em;
//                    padding-left: 1.7em;
//                }
//            }
//        }
//    }
//}
