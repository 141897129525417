.home-newsletter {
    width: 35%;
    @include breakpoint(medium down) {
        width: 100%;
    }
    .heading-block {
        @include breakpoint(small down) {
            margin-bottom: 4rem;
        }
    }
    h2 {
        font-size: 3rem;
        font-weight: $light;
        color: $color-3--5;
    }

    &__content {
        padding: 3.5rem 4rem;
        background-color: $color-3--6;
        @include breakpoint(medium down) {
            display: flex;
            padding: 2.5rem 3rem;
        }
        @include breakpoint(small down) {
            flex-direction: column;
        }

        p {
            position: relative;
            padding-left: 10rem;
            font-family: $typo-1;
            font-size: 1.6rem;
            font-weight: $light;
            color: $color-3--3;
            &:before {
                font-family: "icons-project";
                font-size: 9rem;
                color: $color-3--4;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: inherit;
                bottom: inherit;
                left: 0;
                content: "\E010";
                line-height: 1;

            }
            @include breakpoint(medium down) {
                width: 50%;
                padding-right: 5rem;
                margin-top: 3rem;
                &:before {
                    top: 35%;
                }
            }
            @include breakpoint(small down) {
                width: 100%;
                padding-left: 6rem;
                padding-right: 0;
                margin-top: 0;
                margin-bottom: 3rem;
                &:before {
                    top: 50%;
                    font-size: 5rem;
                }
            }
        }

        form {
            margin: 4rem 0 0;
            @include breakpoint(medium down) {
                width: 50%;
                margin-top: 0;
            }
            @include breakpoint(small down) {
                width: 100%;
            }
            label {
                font-size: 1.6rem;
                color: $color-3--3;
                cursor: inherit;
            }
            input {
                background-color: $color-3--7;
                border: inherit;
                font-style: italic;
                color: $color-3--2;
                margin-bottom: 1rem;
            }
            .button-1 {
                width: 100%;
                height: 6rem;
                padding: 1.25rem 3.2rem;
                &:before {
                    margin-right: 0.7rem;
                    color: $color-1--4;
                    position: static;
                    display: inline-block;
                    transform: none;
                }
            }
        }
    }
}
