// Newsletter inscription
.newsletter {
    //@include breakpoint(xlarge) {
    //    padding-right: 2rem;
    //}
    &__title {
        color: $color-white;
        text-transform: uppercase;
        font-size: 1.8em;
        margin-bottom: 1em;
    }
    form {
        margin: 0;
    }
    fieldset {
        margin-bottom: .5em;
    }

    &__fields-wrapper,
    &__button-wrapper {
        display: block;
        vertical-align: bottom;
    }
    &__fields {
        position: relative;
        &-wrapper {}
    }
    &__field{
        &-wrapper {
            width: 100%;
            label, p {
                color: $color-white;
                font-size: 1.3em;
                margin-bottom: 0.7em;
            }
            input {
                color: $color-white;
                border: none;
                background-color: $color-1--3;
                outline-color: $color-1--4;
                padding-right: 38px;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $color-3--1;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: $color-3--1;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: $color-3--1;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: $color-3--1;
                }
            }
        }
    }
    &__button-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        .button-1 {
            background-color: $color-1--3;
            outline-color: $color-1--4;
            color: $color-1--4;
            overflow: hidden;
            &:before {
                color: inherit;
            }
            &:hover,
            &:focus {
                background-color: $color-1--2;
            }
        }
    }
    &__links {
        margin: 0.5em 0;
        li {
            display: inline;
            margin-right: 2rem;
            &:before {
                content: '/';
                font-family: $typo-2;
                color: $color-1--4;
            }
            a {
                color: $color-white;
                font-size: 1.2rem;
                font-weight: $light;
                &:hover,
                &:focus {
                    text-decoration: underline;
                    color: $color-white;
                }
            }
        }
    }
}
