.on-the-web {
    margin-bottom: 5rem;

    .title-1 {
        font-size: 3rem;
        color: $color-3--5;
        @include breakpoint(small down) {
            font-size: 2.8rem;
        }
    }

    .slick-slider {
        //display: flex;
        //justify-content: space-between;
        &__item {
            padding: 0 2rem;
            @include breakpoint(medium down) {
                padding: 0 3rem;
            }
            @include breakpoint(small down) {
                padding: 0;
            }
        }
        &__img {
            display: block;
            margin-bottom: 2.5rem;
            position: relative;
            &:before, &:after {
                z-index: -1;
                position: absolute;
                content: "";
                bottom: 10px;
                left: 10px;
                width: 50%;
                top: 80%;
                //max-width: 103px;
                background: rgba(61,66,76,.3);
                box-shadow: 0 15px 1px rgba(61,66,76,.3);
                transform: rotate(-3deg);
            }
            &:after {
                transform: rotate(3deg);
                right: 10px;
                left: auto;
            }
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
        &__descr {
            display: block;
            text-align: center;
            font-size: 1.4rem;
            font-family: $typo-2;
            font-weight: $light;
            color: $color-3--3;
            line-height: 1.2;
        }
        .slick-list {
            margin: 0 5.5rem;
            @include breakpoint(small down) {
                margin: 0 3rem;
            }
        }
    }

    .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        padding: 0;
        margin-top: -2rem;
        background: transparent;
        &:before {
            content: "";
            display: block;
            background: url("../Images/ui/prev_on-the-web.png") no-repeat;
            width: 17px;
            height: 25px;
        }
        @include breakpoint(medium down) {
            left: 3rem;
        }
        @include breakpoint(small down) {
            left: 0;
        }
    }
    .slick-next {
        right: 0;
        @include breakpoint(medium down) {
            right: 3rem;
            left: auto;
        }
        @include breakpoint(small down) {
            right: 0;
            left: auto;
        }
        &:before {
            background: url("../Images/ui/next_on-the-web.png") no-repeat;
        }
    }
}
