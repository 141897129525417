// @name Dropdown menu
.ddm.ddm--on .filters__ddm {
    display: inline-block;
    vertical-align: middle;
    .button-1 {
        &:before {
            content: "\e031";
        }
    }
}

@include breakpoint(small down) {
    .ddm.ddm--on > .filters__header > .filters__ddm:only-child {
        display: block;
        .button-1 {
            display: block;
            width: 100%;
            height: 4rem;
            &:before {
                content: "\e031";
                font-size: 1.4rem;
            }
        }
    }
}

.filters .ddm .ddm__sub-level {
    position: static;
    top: inherit;
    right: inherit;
    z-index: inherit;
}

// @name Filters
// @description Filters above list. The Fields are managed with flexbox.
// You can display the fields on 1, 2, 3 or 4 columns.
// On the div element .filters__fields add a good class:
// 1 column: no class, it's the default display.
// 2 columns: add class .filters__fields--column-2
// 3 columns: add class .filters__fields--column-3
// 4 columns: add class .filters__fields--column-4
// By default, label and field are place on top of other. For positionning side by side add the class .filters__wrapper--in-line on .filters__wrapper

.filters {
    //margin-top: -6em;
    padding: 0.1em 0 3em 0;
    //background: $color-3--1;
    // adapt form
    form {
        margin: 2em 0 0;
        @include breakpoint(small down) {
            margin: 2em 0 0;
        }
    }
    .radio,
    .checkbox {
        fieldset {
            margin: 0;
        }
        &:first-child {
            margin-top: 0;
        }
        &.column-2 {
            legend + div {
                margin-top: 1.1em;
            }
        }
    }
}

.filters__header {
    text-align: left;
}

.filters__locate {
    &:before {
        content: "\e04d";
    }
}

.filters {
    position: relative;
    padding: 4rem 0;
    margin-bottom: 4rem;
    background: $color-5--5;
    background: linear-gradient(135deg, rgb(228,244,253) 10%, rgb(213,238,251) 100%);
    //&:before {
    //    content: "\e009";
    //}
    //&:before {
    //    content: '';
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //    box-shadow: 0 -10px 10px -6px rgba(0, 0, 0, 0.15);
    //    width: 100%;
    //    border-radius: 50%;
    //    height: 140px;
    //    z-index: -1;
    //}
    //&:after {
    //    content: '';
    //    position: absolute;
    //    left: 0;
    //    bottom: 0;
    //    box-shadow: 0 10px 10px -1px rgba(0, 0, 0, 0.15);
    //    width: 100%;
    //    border-radius: 50%;
    //    height: 140px;
    //    z-index: -1;
    //}
    fieldset {
        margin-bottom: 0;
    }
    legend {
        font-weight: $light;
        color: $color-1--3;
    }
    label {
        font-size: 1.6rem;
    }
    select {
        background: $color-3--9 url("../Images/form/select_right-green_03.png") no-repeat 100% 50%;
        border: inherit;
        height: 40px;
    }
    input {
        height: 40px;
        background: $color-3--9;
        border: inherit;
        position: relative;
        &:after {
            content: "\e05c";
            position: absolute;
            font-family: 'icons-default';
            font-size: 2rem;
            right: 4rem;
            top: 50%;
            transform: translateY(-50%);
            color: $color-2--2;
        }
    }
}

.filters__wrapper {
    padding: 0;
    display: table;
    width: 100%;
    @include breakpoint(small down) {
        display: block;
        table-layout: inherit;
    }
    &.filters__wrapper--in-line {
        .filters__field-wrapper {
            width: 100%;
            .filters__field-1, .filters__field-2 {
                display: table-cell;
            }
            .filters__field-1 {
                text-align: right;
                padding-right: 1em;
                white-space: nowrap;
            }
            .filters__field-2 {
                display: table-cell;
                width: 100%;
            }
        }
        .filters__button-wrapper {
            padding-top: .5em;
        }
    }
}

// Just for ie11
@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .filters__wrapper {
        table-layout: fixed;
    }
}

.filters__fields,
.filters__button-wrapper {
    display: table-cell;
    vertical-align: top;
    @include breakpoint(small down) {
        display: block;
        vertical-align: inherit;
    }
}

// @name Container of any fields
// @state .filters__fields--column-2 - Indicates 2 columns
// @state .filters__fields--column-3 - Indicates 3 columns
// @state .filters__fields--column-4 - Indicates 4 columns
// @state .filters__fields--in-line - Indicates label and field are side by side. Not works with checkbox and radio.
.filters__fields {
    width: 100%;
    .filters__fields-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0;
        margin-left: -1%;
        margin-right: -1%;
        @include breakpoint(small down) {
            display: block;
            flex-wrap: inherit;
            justify-content: inherit;
            margin: 0;
        }
        > .filters__field-wrapper {
            margin: .5em 1% 1.5em;
            flex: 1 0 100%;
            @include breakpoint(small down) {
                align-self: inherit;
                flex: inherit;
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    &.filters__fields--column-2,
    &.filters__fields--column-3,
    &.filters__fields--column-4 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                @include breakpoint(small down) {
                    width: 100%;
                }
            }
        }
    }
    &.filters__fields--column-2 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                flex: 1 0 48%;
            }
        }
    }
    &.filters__fields--column-3 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                flex: 1 0 31.3333%;
            }
        }
    }
    &.filters__fields--column-4 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                flex: 1 0 23%;
            }
        }
    }
}

.filters__button-wrapper {
    padding-left: 2em;
    padding-top: 3.3em;
    white-space: nowrap;
    display: flex;
    //flex-direction: column;
    @include breakpoint(small down) {
        padding-left: 0;
        padding-top: 0;
        text-align: right;
        width: 100%;
    }
}

// Just for ie11
@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .filters__button-wrapper {
        width: 25em;
    }
}

.filters__reset {
    min-height: 4rem;
    margin-right: .5rem;
    &:before {
        content: "\e058";
    }
}

.button-1.filters__submit {
    min-height: 4rem;
    &:before {
        content: "\e031";
    }
}

// @name Filters dark
// @description Filters with dark backhround
.filters__dark {
    background: $color-3--3;
}

@include breakpoint(small down) {
    .ddm {
        .filters__ddm {
        }
        .filters__ddm__sub-level {
            display: block;
            height: auto;
            overflow: hidden;
            max-height: 0;
            transition: max-height ease .2s;
        }
        &.ddm--active {
            .filters__ddm__sub-level {
                max-height: 100em;
                transition: max-height ease 1s;
            }
        }
    }
}

.proposer {
    display: flex;
    align-items: flex-start;

    .proposer-item-left {
        position: relative;
        z-index: 1;
        width: 26%;
        background-color: $color-3--3;
        @extend %bg-pattern-white;
        padding: 2rem;
        &:after {
            content: '';
            position: absolute;
            width: 17px;
            height: 100%;
            background: $color-white;
            transform: skewX(-13deg);
            //z-index: -1;
            top: 0;
            right: -9px;
        }
        p {
            font-family: $typo-2;
            font-size: 2rem;
            font-weight: $light;
            color: $color-2--2;
            text-transform: uppercase;
            span {
                font-weight: $bold;
            }
        }
    }

    .proposer-item-right {
        //display: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 74%;
        position: relative;
        z-index: 5;
        background-color: $color-2--1;
        padding: 1.5rem 2rem;
        transform: translate(6px, 10px);
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -10px;
            width: 100%;
            height: 100%;
            background: inherit;
            z-index: -1;
            transform: skewX(-13deg);
        }

        .proposer-item__cnt {
            p {
                font-size: 1.6rem;
                font-weight: $medium;
                color: $color-3--3;
            }
        }
    }
    .proposer-btn {
        position: relative;
        font-family: $typo-2;
        font-size: 1.2rem;
        font-weight: $bold;
        color: $color-3--3;
        text-transform: uppercase;
        &__icon {
            position: absolute;
            top: 50%;
            left: -5rem;
            width: 4.1rem;
            height: 4.5rem;
            transform: translateY(-50%);
            svg {
                path {
                    line-height: 1;
                    fill: none;
                    stroke-width: 20px;
                    stroke: $color-white;
                }
            }
        }
    }
}
