// Galerie classique
.classical-gallery {
    max-width: 70rem;
}

.classical-gallery__title {
    $classical-gallery__title_font-size: 1.6;
    font-family: $typo-2;
    text-align: right;
    font-weight: $normal;
    font-size: #{$classical-gallery__title_font-size}em;
    color: $color-3--3;
    margin: em(3, $classical-gallery__title_font-size) 0 em(1, $classical-gallery__title_font-size) 0;
}

.classical-gallery {
    margin: 4em 0 7em;
    @include breakpoint(small down) {
        // Galerie classique (avec vignettes) + Galerie album
        margin-left: -10px;
        margin-right: -10px;
        position: relative;
    }
    &__title {
        @include breakpoint(small down) {
            padding: 0 10px;
        }
    }
    &__nb-items {
        display: block;
        padding: 0.85em;
        background-color: $color-1--2;
        color: $color-white;
        font-weight: $bold;
        position: absolute;
        pointer-events: none;
        z-index: 10;
        bottom: 0;
        right: 0;
        font-size: 1.6em;
        margin: 0;
        @include breakpoint(medium) {
            display: none;
        }
    }
}

.slider-galerie {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 10px;
    @include breakpoint(small down) {
        margin-bottom: 0;
    }
    .slider-galerie__item {
        width: 100%;
        height: 100%;
        z-index: 1 !important;
        border: none;
        background: $color-3--1;
        @include breakpoint(small down) {
            height: auto;
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
    .infos-img {
        position: absolute;
        z-index: 1;
        top: 100%;
        right: 0;
        width: calc(100% - 530px);
        display: table;
        padding: 10px 0;
        font-family: $typo-1;
        font-weight: $light;
        font-style: italic;
        color: $color-3--3;
        font-size: 1.3em;
        //text-align: right;
        white-space: normal;
        @include breakpoint(small down) {
            display: none;
            position: static;
            right: inherit;
            bottom: inherit;
            background: #000;
        }
        &__nbItems {
            display: table-cell;
            vertical-align: bottom;
            white-space: nowrap;
            width: 70px;
        }
        &__wrap {
            display: table-cell;
            vertical-align: top;
        }
        &__legend {
            display: block;
        }
    }
    a {
        display: block;
        width: 100%;
        height: auto;
        text-align: center;
        position: relative;
        white-space: nowrap;
        padding: 68.08510% 0 0 0; // 100/(940/640)
        @include breakpoint(small down) {
            display: inline-block;
            text-decoration: none;
            padding: 0;
        }
    }
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%) translateY(-50%);
        @include breakpoint(small down) {
            max-height: inherit;
            position: static;
            top: inherit;
            bottom: inherit;
            left: inherit;
            right: inherit;
            transform: inherit;
            display: inline;
        }
    }
}

.carousel-galerie {
    width: 426px;
    display: inline-block;
    text-align: right;
    position: relative;
    z-index: 1;
    .item {
        border: none;
    }
}

.carousel-galerie__thumb {
    width: 352px;
    height: 74px;
    z-index: 2;
    margin-left: 37px;
    text-align: left;
    @include breakpoint(small down) {
        display: none;
    }
    &.no-carousel {
        position: relative;
        .carousel-galerie__item {
            display: inline-block;
            margin: 0 3px;
        }
    }
}

.carousel-galerie__item {
    z-index: 1 !important;
    text-align: center;
    &.cycle-slide-active button,
    &.cycle-pager-active button,
    button:hover,
    button:focus {
        &:before {
            @include background-opacity($color-2--2, .2)
        }
    }
    button {
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        &:before {
            transition: all ease .2s;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0);
        }
        img {
            width: 74px;
            height: 74px;
        }
    }
}

.carousel-galerie__pager {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 74px;
    width: 426px;
    z-index: 1;
    @include breakpoint(small down) {
        display: none;
    }
}

.carousel-galerie__prev,
.carousel-galerie__next {
    position: absolute;
    top: 0;
    padding: 0;
    button {
        width: 37px;
        height: 74px;
        text-indent: -9999px;
        overflow: hidden;
        transition: all ease .2s;
        @include default-icons-absolute-before('\E00D', 2.5rem, #78735c, 50%, auto, auto, 0, 'icons-project');
        &:before {
            text-indent: 0;
            transform: translateY(-50%);
            transition: all 300ms ease;
            width: 3.7rem;
            text-align: center;
        }
        &:hover,
        &:focus {
            &:before {
                color: $color-2--2;
                //text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
            }
        }
    }
    &:before {
        content: "";
        speak: none;
    }
}

.carousel-galerie__prev button:hover:before,
.carousel-galerie__prev button:focus:before {
    padding-right: .6rem;
}

.carousel-galerie__next button:hover:before,
.carousel-galerie__next button:focus:before {
    padding-left: .6rem;
}

.carousel-galerie__prev {
    left: 0;
}

.carousel-galerie__next {
    right: 0;
    button {
        &:before {
            content: "\E00E";
        }
    }
}
