// @name Agenda
// @description Sections content below the content

.block-organigrame {

    .heading-block {
    }

    &__title {
        margin-top: 0;
    }

    &__wrapper {
        display: flex;
        //justify-content: center;
        flex-wrap: wrap;
        margin: 3em -0.5em 1em;
        @include breakpoint(small only) {
            margin-left: 0;
            margin-right: 0;
        }
        .organigrame-item {
            width: calc((100% / 2) - 1em);
            margin: 0 0.5em 3em;
            @include breakpoint(small only) {
                width: 100%;
                margin: 0 0 4em;
                flex-wrap: wrap;
            }
            &:last-child {
                @include breakpoint(small only) {
                    margin-bottom: 0;
                }
            }
        }
    }
}


.organigrame-item {
    transition: all 0.5s ease;
    display: flex;
    &__img {
        position: relative;
        display: block;
        min-width: 150px;
        max-width: 150px;
        margin-right: 2em;
        @include breakpoint(small only) {
            margin-bottom: 1rem;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    &__wrap {
        color: $color-3--2;
        position: relative;
        flex-grow: 1;
        font-size: 1.3em;
        @include breakpoint(small only) {
            width: 100%;
        }
        p {
            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
        a {
            color: $color-3--2;
        }
        .button-1--variation-1 {
            color: $color-white;
            line-height: 1.2em;
            display: block;
            padding: 1.1rem 1rem 1.1rem 3.5rem;
            //padding-top: 0.7em;
            //padding-bottom: 0.7em;
            //padding-right: 1rem;
            width: 100%;
            max-width: 230px;

            &:before {
                content: '\e028';
                left: 1.3rem;
                transform: translateY(-50%);
            }
        }
    }
    &__title {
        font-family: $typo-2;
        font-weight: $bold;
        font-size: 1.5em;
        text-transform: none;
        margin-bottom: 0.5em;
    }
    &__post {
        font-weight: $bold;
    }
}
