.location {

    $this: &;

    position: relative;
    margin-top: 4rem;
    padding-bottom: 6rem;
    background-color: $color-1--3;
    overflow-x: hidden;
    @extend %bg-pattern-white;

    &.active-overlay:after {
        @include breakpoint(medium down) {
            display: block;
        }
    }

    &.active-overlay {
        .location__information-info,
        .location__information-descr {
            visibility: hidden;
        }
    }

    &:after {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 15;
    }

    .heading-block {
        margin-top: 2em;
        overflow: hidden;

        &:after {
            display: none;
        }
    }
    .title-1 {
        font-size: 3.4rem;
        line-height: 1.2em;
        color: $color-white;
        //background-color: $color-1--3;
        background-color: transparent;
        //@extend %bg-pattern-white;
        @include breakpoint(medium down) {
            font-size: 3rem;
        }
        @include breakpoint(small down) {
            font-size: 2.8rem;
            max-width: 24rem;
        }
        &:before, &:after {
            //background-color: $color-1--3;
            background-color: transparent;
        }

        span {
            display: block;
            position: relative;

            &:before, &:after {
                content: '';
                display: block;
                width: 200rem;
                height: .2rem;
                margin-top: -.1rem;
                @extend %bg-title-dot;
                background-color: transparent;
                position: absolute;
                top: 50%;
                z-index: -1;
            }

            &:before {
                right: calc(100% + 3rem);
            }

            &:after {
                left: calc(100% + 3rem);
            }
        }
    }
    &__wrap {
        display: flex;
        align-items: center;
        position: relative;
        //overflow: hidden;
        @include breakpoint(medium down) {
            flex-direction: column;
            position: static;
        }
    }

    &__information {
        flex-shrink: 0;
        width: 250px;
        margin-right: 3rem;
        text-align: right;
        font-weight: $light;
        color: $color-white;
        font-size: 1.6em;
        @include breakpoint(medium down) {
            width: 100%;
            display: flex;
            margin-right: 0;
        }
        @include breakpoint(small only) {
            //flex-direction: column;
            display: block;
            padding-left: 5rem;
            padding-right: 5rem;
            margin-bottom: 2rem;
            text-align: left;
            font-size: 1.4rem;
        }
        &-info {
            text-transform: uppercase;
            color: $color-4--1;
            font-family: $typo-2;
            font-size: em(2, 1.6);
            margin-bottom: 1em;
            @include breakpoint(medium down) {
                flex: 0 0 21.5rem;
                //width: 40%;
                width: 21.5rem;
                margin-right: 2rem;
            }
            @include breakpoint(small only) {
                flex: 1 1 100%;
                width: 100%;
                margin-right: 0;
            }
        }
        &-descr {
            @include breakpoint(medium down) {
                text-align: left;
                flex: 1 1 auto;
                width: calc(100% - 21.5rem);
            }
            @include breakpoint(small only) {
                width: 100%;
            }
        }
    }
    &__modals {
        //display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        @include breakpoint(medium down) {
            //top: -3rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @include breakpoint(small down) {
            top: auto;
            bottom: 6rem;
            transform: translate(-50%, 0);
        }
        .location-modal {
            width: 27rem;
            padding: 2.5rem 2.5rem 3rem 2rem;
            background-color: $color-1--3;
            text-align: left;
            @include breakpoint(medium down) {
                //width: 100%;
                //width: 74rem;
                width: 30rem;
                //padding: 1rem;
            }
            //@include breakpoint(small only) {
            //    width: 27rem;
            //}
            //&__wrap {
            //    @include breakpoint(medium down) {
            //        display: flex;
            //        justify-content: space-around;
            //    }
            //    @include breakpoint(small only) {
            //        flex-direction: column;
            //        justify-content: flex-start;
            //    }
            //}
            .button-reset {
                position: absolute;
                top: -1.2rem;
                right: -1.2rem;
                width: 3.2rem;
                height: 3.2rem;
                background-color: $color-1--2;
                border-radius: 50%;
                &:before {
                    content: "\e022";
                    font-family: "icons-default";
                    font-size: 2rem;
                    color: $color-white;
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(50%, -50%);
                    line-height: 1;

                }
                &:hover, &:focus {
                    &:before {
                        color: $color-2--2;
                    }
                }
            }
            h3 {
                margin-bottom: 1.5rem;
                //@include breakpoint(medium only) {
                //    padding-right: 1rem;
                //}
                a {
                    font-family: $typo-2;
                    font-size: 2.1rem;
                    font-weight: $light;
                    color: $color-white;
                    text-transform: uppercase;
                }

            }
            &__img {
                display: block;
                margin-bottom: 3rem;
                //@include breakpoint(medium only) {
                //    padding-right: 1rem;
                //}
                img {
                    display: block;
                    width: 100%;
                }
            }
            //.list-infos {
                //@include breakpoint(medium only) {
                //    max-width: 22rem;
                //}
            //}
            .list-infos__list {
                font-family: $typo-1;
                font-size: 1.4rem;
                font-weight: $light;
                margin-bottom: 1rem;
                li {
                    position: relative;
                    &:before {
                        content: "\e027";
                        font-family: "icons-default";
                        font-size: 1.4rem;
                        color: $color-2--2;
                        position: absolute;
                        top: 0.2rem;
                        right: inherit;
                        bottom: inherit;
                        left: 0;
                        line-height: 1;
                    }
                    span {
                        font-weight: $bold;
                        padding-left: 2rem;
                    }
                }
            }
            .list-infos__item,
            .list-infos__item a {
                display: block;
                font-family: $typo-1;
                font-size: 1.4rem;
                font-weight: $light;
                margin-bottom: 1rem;
                color: $color-white;
                text-decoration: none;
            }
            .list-infos__item a {
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
            .list-infos__phone a {
                font-weight: $bold;
            }
            .list-infos__website {
                a {
                    @include default-icons-after('\e086', 0 0 0 .5rem, 1rem, $color-2--2, middle);

                }
            }
            .button-1 {
                padding: 1.5rem 2.5rem 1.5rem 3.5rem;
                margin-top: 1rem;
                font-size: 1.2rem;
                background-color: $color-3--3;
                &:before {
                    content: "\e08a";
                    left: 1.5rem;
                    color: $color-2--2;
                }
                &:hover, &:focus {
                    background-color: $color-2--2;
                    &:before {
                        color: $color-white;
                    }
                }
                //@include breakpoint(medium down) {
                //    margin-top: 0;
                //}
            }
        }
    }
    &__twocolumns {
        flex-grow: 1;
        display: flex;
        width: 100%;
        @include breakpoint(small only) {
            flex-direction: column;
        }
    }

    &__map {
        max-width: 473px;
        width: 58%;
        position: relative;
        flex-shrink: 0;
        @include breakpoint(medium down) {
            width: 50%;
        }
        @include breakpoint(small only) {
            width: 100%;
        }
        polygon {
            fill: $color-1--2;
            stroke: $color-1--3;
            &.active,
            &:focus,
            &:hover {
                cursor: pointer;
                fill: $color-4--3;
            }
        }
        .map-local__back {
            fill: $color-1--3;
        }
    }

    &__list {
        padding-left: 2rem;
        width: 50%;
        @include breakpoint(small only) {
            padding-left: 0;
            width: 100%;
        }
        h3 {
            font-size: 3.5rem;
            line-height: 2em;
            font-family: $typo-2;
            text-transform: uppercase;
            color: $color-white;
            margin-bottom: 0.25em;
            @include breakpoint(medium only) {
                font-size: 3rem;
                line-height: 1em;
                vertical-align: bottom;
            }
            @include breakpoint(small only) {
                font-size: 2.8rem;
                line-height: 1em;
                vertical-align: bottom;
            }
            span {
                display: inline-block;
                vertical-align: middle;
                margin-top: -1.4rem;
                margin-right: -0.05em;
                //vertical-align: -0.2em;
                font-size: 2em;
                line-height: 1em;
                color: $color-1--1;
                @include breakpoint(medium only) {
                    font-size: 5.1rem;
                    vertical-align: bottom;
                    margin-top: 0;
                }
                @include breakpoint(small only) {
                    font-size: 4.1rem;
                    vertical-align: bottom;
                    margin-top: 0;
                }
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
                padding-right: 0.5em;
            }
            button {
                color: $color-white;
                padding-left: 1.25em;
                font-size: 1.6rem;
                line-height: 2rem;
                font-family: $typo-2;
                font-weight: $light;
                text-transform: uppercase;
                white-space: normal;
                text-align: left;
                @include default-icons-absolute-before('\e011', 1.25em, $color-2--1, 0.2rem, inherit, inherit, 0);
                outline-color: $color-2--1;
                &.active {
                    font-weight: $bold;
                    color: $color-2--1;
                    text-decoration: underline;
                    &:before {
                        color: $color-white;
                    }
                }
                @include breakpoint(medium only) {
                    font-size: 1.4rem;
                }
                @include breakpoint(small only) {
                    font-size: 1.3rem;
                }
            }
        }
    }

}

.interactive-map-tooltip {

    background: $color-4--3;
    color: $color-white;
    font-family: $typo-2;
    font-weight: $light;
    text-transform: uppercase;
    font-size: 1.6em;
    min-width: 100px;
    text-align: center;
    white-space: nowrap;

    position: absolute;
    z-index: 101;
    line-height: 1;
    padding: 1rem 2rem;
    pointer-events: none;
    transform: translate(-50%, -250%);
    &:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        speak: none;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 9px 0 9px;
        border-color: $color-4--3 transparent transparent transparent;

        //bottom: 100%;
        //left: 0;
    }
}

/* needed for old browsers */
//dialog {
//    display: block;
//}
/* removes scroll when modal is opened */
//.no-scroll {
//    overflow: hidden;
//}
/* overlay covers everything */
//.modal-overlay {
//    position: fixed;
//    top: 0;
//    bottom: 0;
//    right: 0;
//    left: 0;
//    z-index: 666;
//}
/* modal */
//.modal {
//    position: fixed;
//    left: 25%;
//    right: auto;
//    top: 15%;
//    width: 50%;
//    background: #fff;
//    z-index: 667;
//}

.location-modal {
    display: none;
    &.is-open {
        display: block;
    }
}
