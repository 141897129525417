.fixed-menu-overlay {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.4s ease;

    &.is-visible {
        visibility: visible;
        opacity: 1;
    }
}

.fixed-menu {
    $this: &;

    position: fixed;
    transform: translateX(293px);
    transition: transform 0.4s ease;
    top: 0;
    right: 0;
    bottom: 0;
    color: $color-white;
    z-index: 9999;
    width: 293px;

    @include breakpoint(medium down) {
        display: none;
    }

    &--active {
        transform: translateX(0);
        .fixed-menu_text {
            &:after {
                content: "\e00b";
            }
        }
    }

    .service-agglo {
        padding-left: 6.5rem !important;
        margin-top: 0;
        &__title {
            font-size: 2.2rem;
        }
        &__subtitle {
            font-size: 1.3rem;
        }
        &__description {
            font-size: 1.4rem;
        }
        &__questions {
            margin-top: 0;
        }
        &__actions {
            a {
                border: none;
                padding: 0.5rem 0.5rem 0.5rem 2rem;
                @include default-icons-absolute-before('\e02b', 1.4rem, $color-2--3, 50%, auto, auto, -.1rem);
                &:before {
                    width: 2rem;
                    height: auto;
                    line-height: 1;
                    transform: translateY(-50%);
                    text-align: center;
                    //left: -.1rem;
                    background-color: transparent;
                    color: $color-white !important;
                }
            }
        }
        a.service-agglo__enligne {
            margin-left: 1.5rem;
            &:before {
                content: '\e00b';
                font-family: 'icons-project';
                color: $color-white;
            }
        }
    }
    .service-item {
        height: 45px;
        margin-top: .6rem;
        padding: 1rem 8rem 1rem 6.5rem;
        svg {
            max-width: 2.5rem;
        }
    }
}

.fixed-menu_box {
    position: relative;
    left: calc(100% - 293px);
    height: 100%;
    padding: 100px 0 100px;
    background-color: $color-3--3;
    z-index: 2000;
}

.fixed-menu_title {
    padding: 1rem 4rem ;
    margin-bottom: 20px;
    font-family: $typo-3;
    font-size: 2rem;
    text-transform:uppercase;
    font-weight:$normal;
    color: $color-white;
}
.fixed-menu_text{
    position: relative;
    font-family: $typo-2;
    display: block;
    margin: 0 auto;
    width: 10rem;
    white-space: normal;
    &:before, &:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: $color-2--2;
    }
    &:before {
        content: "\E015";
        top: -4rem;
        font-family: 'icons-project';
        font-size: 2.8rem;
        font-weight: $normal;
    }
    &:after {
        content: "\e009";
        bottom: -3rem;
        font-family: 'icons-default';
        font-size: 1.8rem;

    }
}
.fixed-menu_button {
    display: block;
    position: absolute;
    z-index: 2001;
    left: -10rem;
    top: 50%;
    width: 12rem;
    height: 12rem;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: $normal;
    color: $color-white;
    text-transform: uppercase;
    background-color: $color-3--3;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow:  3px -7px 7px 0 rgba(0,0,0,0.35);;
    transition: background-color 0.3s ease;
    &:hover,
    &:focus {
        color: $color-1--1;
    }
}

.fixed-menu_close {
    @include default-icons-absolute-before('\e022', 2rem, $color-white, 50%, inherit, inherit, 50%);
    position:absolute;
    right: 15px;
    top: 65px;
    width: 3.5rem;
    height: 3.5rem;
    background-color: $color-1--5;
    border-radius: 50%;
    &:before {
        width: 3.5rem;
        transform:translate(-50%, -50%);
        color: $color-2--2;
    }
    &:hover, &:focus {
        &:before {
            color: $color-white;
        }
    }
}

.fixed-menu_list {
    padding: 0 2.5rem 2.8rem 5.5rem;

    li {
        margin: 0 0 1rem;
    }

    a {
        position: relative;
        display: block;
        font-family: $typo-1;
        font-size: 1.6rem;
        font-weight: $thin;
        color: $color-white;
        &:before {
            content: "\e017";
            position: absolute;
            left: -2rem;
            top: .2rem;
            font-family: 'icons-default';
            font-size: 1.6rem;
            line-height: 1;
            color: $color-2--2;
        }
        &:hover, &:focus, &:hover:before, &:focus:before {
            text-decoration: none;
            color: $color-1--1;
        }
    }
}

.mobile-fixed-menu {
    display: none;
    @include breakpoint(medium down) {
        display: block;

        &.is-active {
            .fixed-menu_opener {
                color: $color-white;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);

                .fixed-menu_text {
                    color: $color-white;

                    &:before, &:after {
                        color: $color-white;
                    }
                }
            }

            .fixed-menu_box {
                display: block;
            }
        }

        .fixed-menu_opener {
            position: relative;
            left: auto;
            top: auto;
            z-index: 2;
            width: 100%;
            height: auto;
            box-shadow: none;
            border-radius: 0;
            transform: none;
            line-height: 3rem;
            text-align: center;
            border-bottom: 1px dotted $color-3--2;
            outline-color: $color-white;
            padding: 3rem 2.8rem 4.5rem;

            &:hover, &:focus {
                .fixed-menu_text {
                    color: $color-white;

                    &:before, &:after {
                        color: $color-white;
                    }
                }
            }
        }

        .fixed-menu_text {
            width: auto;
            transition: all 300ms ease;

            &:before {
                display: inline-block;
                vertical-align: top;
                font-size: 2.6rem;
                line-height: 3rem;
                color: $color-2--1;
                width: auto;
                margin-right: 1rem;
                position: static;
                left: auto;
                transform: none;
                margin-top: -.2rem;
                transition: all 300ms ease;
            }

            &:after {
                font-size: 1.6rem;
                color: $color-2--1;
                bottom: -2.3rem;
                transition: all 300ms ease;
            }
        }

        .fixed-menu_box {
            display: none;
            position: relative;
            top: auto;
            left: auto;
            z-index: 1;
            border-bottom: 1px dotted $color-3--2;
            padding: 2rem 1rem;

            .fixed-menu_close {
                display: none;
            }
        }

        .fixed-menu_list {
            padding: 0 0 0 2.6rem;

            li {
                margin: 1rem 0;
                padding-left: 0;
            }

            a {
                font-size: 1.6rem;
                line-height: 1.8rem;
                padding-left: 1.5rem;
                outline-color: $color-2--1;

                &:hover, &:focus {
                    font-weight: $bold;
                    text-decoration: underline;
                    color: $color-white;

                    &:before {
                        color: $color-white;
                    }
                }

                &:focus {
                    outline-color: $color-2--1;
                }

                &:before {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    top: -0.1rem;
                    left: -0.1rem;
                }
            }
        }

        .fixed-menu_close {
            display: none;
        }

        .service-agglo {
            display: none;
        }

        .service-item {
            display: none;
        }
    }
}
