// @name Top of content
.top-of-content {
  //background: $color-3--1;
  @include breakpoint(medium down) {
    display: none;
  }
}

.top-of-content__wrapper {
  @extend .wrapper-main;
  padding-top: 2em;
  padding-bottom: 2em;
  //border-bottom: 1px solid $color-3--2;
}

// @name Main wrapper
.main {
  width: 100%;
  clear: both;
}

.page {
  overflow-x: hidden;
}

// @name Main section
.section-main {
  $this: &;
  // @name Main section wrappers
  &__wrapper {
    @extend .wrapper-main;
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
    @include breakpoint(large) {
      //padding-top: 7em;
    }
  }
  &__wrapper-2 {
    width: 100%;
    @include breakpoint(large) {
      display: table;
      table-layout: fixed;
    }
    > * {
      @include breakpoint(large) {
        display: table-cell;
        vertical-align: top;
      }
    }
  }
  // @name Main section aside
  &__aside {
    @include breakpoint(large) {
      width: 35rem;
      padding-right: 7rem;
    }
    > .column-bloc {
      *:first-child {
        margin-top: 0;
      }
    }
  }
  // @name Main section content
  &__content {
    width: 100%;
  }

  &__two-column {
    display: flex;
    @include breakpoint(medium down) {
      flex-direction: column;
    }
  }
}

// @name Footer
.footer {
  background-color: $color-1--3;
  position: relative;
  overflow: hidden;
  @extend %bg-pattern-white;
  .footer__wrapper {
    @extend .wrapper-main;
    padding-top: 3em;
    padding-bottom: 3em;
    @include breakpoint(xlarge) {

    }
  }
  .footer__wrapper-2 {
    margin: 0 -1.5em;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(small down) {
      margin: 0;
      display: block;
    }
    @include breakpoint(medium) {
      margin: 0 -3em;
    }
    @include breakpoint(large) {
      flex-wrap: nowrap;
    }
    @include breakpoint(large only) {
      justify-content: space-between;
    }
    > * {
      width: 100%;
      margin: 0 1.5em;
      position: relative;
      @include breakpoint(small down) {
        flex: inherit;
        width: auto;
        border-bottom: 2px dotted $color-1--4;
        padding-bottom: 3em;
        margin: 0 3em 3em;
        &:after {
          content: none;
        }
      }
      @include breakpoint(medium) {
        width: calc(50% - 6em);
        margin: 0 3em 3em;
      }
      @include breakpoint(large) {
        flex: 1 1 100%;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        right: -2rem;
        top: 0;
        width: 1px;
        height: 156px;
        border-left: 2px dotted $color-2--2;
        transform: skewX(-14deg);
      }
      &:last-child {
        border: none !important;
        &:after {
          content: none;
        }
      }
    }

    .site-infos {
      @include breakpoint(medium only) {
        width: calc(65% - 6em) !important;
        padding-left: 22rem;
      }
      @include breakpoint(large) {
        padding-left: 16rem;
      }
      @include breakpoint(large only) {
        padding-left: 15rem;
        flex: 0 1 auto;
        min-width: 38.5rem;
      }
      @include breakpoint(xlarge) {
        padding-left: 5.5rem;
      }
    }

    .site-contact {
      @include breakpoint(large only) {
        flex: 1 1 auto;
        width: auto;
        min-width: 22rem;
      }
    }

    .newsletter {
      //@media(min-width: 1280px) and (max-width: 1499px) {
      @include breakpoint(large only) {
        flex: 0 0 26rem;
        width: 26rem;
      }
      @include breakpoint(xlarge) {
        padding-right: 2rem;
      }
    }

    .menu-cross {
      //flex-grow: .9;
      //flex-shrink: .9;
      @include breakpoint(large only) {
        flex: 0 1 auto;
        width: auto;
        min-width: 15rem;
        //margin-right: -3rem;
        margin-right: 0;
      }
      @include breakpoint(xlarge) {
        min-width: 28%;
        margin-right: -13rem;
      }
      @include breakpoint(small down) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
  }
}
