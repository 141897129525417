.home-gallery {
    overflow: hidden;
    position: relative;

    .title-1 {
        font-size: 4rem;
        color: $color-3--3;
        @include breakpoint(medium down) {
            font-size: 3rem;
        }
        @include breakpoint(small down) {
            font-size: 2.8rem;
        }
    }
    .heading-block-subtitle {
        a {
            color: $color-black;
        }
    }

    .image-gallery {
        display: flex;
        flex-wrap: wrap;
        &__lg,
        &__sm {
            width: 100%;
        }
        &__lg {
            @include breakpoint(small only) {
                width: 100%;
                order: 0;
            }
            @include breakpoint(medium down) {
                margin: 0 auto;
            }
            @include breakpoint(large) {
                width: 60%;
            }
        }
        &__sm {
            display: flex;
            flex-wrap: wrap;
            @include breakpoint(large) {
                width: 40%;
            }
            .image-gallery-item {
                width: 100%;
                @include breakpoint(medium) {
                    width: (100% / 3);
                }
                @include breakpoint(large) {
                    width: 50%;
                }
            }

        }
    }
}

.image-gallery-item {
    text-decoration: none;
    position: relative;
    display: block;

    $this: &;

    &#{$this}--video {
        &:after,
        &:before {
            position: absolute;
            display: block;
            line-height: 50px;
            left: 50%;
            top: 50%;
            text-align: center;
            z-index: 1;
            visibility: visible;
            opacity: 1;
            transition: all 0.25s ease;
            @include breakpoint(medium down) {
                display: none !important;
            }
        }
        &:before {
            content: '';
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: rgba($color-2--2, 0.7);
            font-size: 4.5em;
            color: $color-4--1;
            transform: translate(-50%, -50%) rotate(15deg);

        }
        &:after {
            content: '\e075';
            font-family: 'icons-default';
            width: 50px;
            height: 50px;
            color: $color-white;
            font-size: 2em;
            transform: translate(-50%, -50%);
        }
    }

    .image-gallery__lg & {
        @include breakpoint(medium only) {
            display: flex;
            background-color: #f3f6f8;
            margin-bottom: 3rem;
            width: 100%;
            #{$this}__img {
                width: (100% / 3 * 2);
            }
            #{$this}__content {
                text-align: left;
                margin-top: 6rem;
            }

        }
    }

    &__img {
        $padding-height: 80%;
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        backface-visibility: hidden;
        @include breakpoint(large) {
            padding-bottom: $padding-height;
        }
        .image-gallery__sm & {
            @include breakpoint(large) {
                padding-bottom: ($padding-height / 3 * 2);
            }
        }
        @include breakpoint(medium down) {
            padding: 0;
            height: auto;
        }
        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;'; //object-fit polyfill
            @include breakpoint(medium down) {
                position: static;
                transform: translate(0, 0);
                width: 100%;
            }
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($color-1--2, 0.8);
            opacity: 0;
            transition: all 0.25s ease;
            @include breakpoint(medium down) {
                display: none !important;
            }
        }
    }

    &__content {
        color: $color-white;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -25%);
        width: 90%;
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: all 0.25s ease;
        white-space: normal;
        @include breakpoint(medium down) {
            position: static;
            visibility: visible;
            opacity: 1;
            width: auto !important;
            margin: 2em;
            transform: translate(0, 0) !important;
            color: $color-black;
        }
        .image-gallery__sm & {
            width: 98%;
        }
        .category {
            font-size: 1.2em;
            line-height: 1;
            margin-bottom: 0.5em;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 0.35px;
            font-family: $typo-2;
            @include breakpoint(large) {
                position: relative;
                padding-top: 5rem;
                color: $color-white;
                @include default-icons-absolute-after('\e08a', 1.5rem, $color-1--2, 0, inherit, inherit, 50%);
                &:after {
                    transform: translateX(-50%);
                    border-radius: 50%;
                    padding: 0.85em;
                    text-align: center;
                    width: 4rem;
                    height: 4rem;
                    background-color: $color-white;
                }

            }
            &:before {
                content: '// ';
                font-weight: $light;
            }
        }
        h3 {
            font-weight: $light;
            font-size: 1.6em;
            font-family: $typo-1;
        }
        p {
            font-size: 1.3em;
            font-weight: $light;
            margin: 1rem 0;
        }
    }

    &:focus,
    &:hover {
        text-decoration: none;
        &:after,
        &:before {
            opacity: 0;
            visibility: hidden;
        }
        #{$this}__img {
            &:after {
                opacity: 1;
            }
        }
        #{$this}__content {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);
        }
    }

}
