.interesse {
    @extend  %bg-pattern-blue;
    padding: 3rem;
    position: relative;
    @include breakpoint(small down) {
        padding: 2rem;
    }
    .title-1 {
        font-size: 4rem;
        color: $color-1--3;
        @include breakpoint(medium down) {
            font-size: 3rem;
        }
        @include breakpoint(small down) {
            font-size: 2.8rem;
        }
    }
    .heading-block-subtitle {
        a {
            color: $color-black;
        }
    }
    &-wrap {
        //padding: 2.5rem 3rem 8rem;
        padding: 2.5rem 3rem;
        background-color: $color-white;
        @include breakpoint(medium down) {
            padding: 3rem 3rem 4rem;
        }
        @include breakpoint(small down) {
            padding: 2.5rem 1.5rem 0 2rem;
        }
        .heading-block {
            margin: 0 0 4rem 0;
            @include breakpoint(medium down) {
                margin: 0 0 6rem;
            }
            @include breakpoint(small down) {
                margin-bottom: 4.5rem;
            }
            .title-1 {
                @include breakpoint(medium down) {
                    font-size: 3rem;
                }
                @include breakpoint(small down) {
                    font-size: 3rem;
                    line-height: 1.2;
                    padding: 0;
                    &:before, &:after {
                        content: none;
                    }
                }
            }
            &--center {
                &::after {
                    @include breakpoint(small down) {
                        content: none;
                    }
                }
            }
        }
    }
    .interesse__content {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        @include breakpoint(small down) {
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &.sucsesfully-vouted {
            @include breakpoint(medium down) {
                flex-wrap: wrap;
            }
            @include breakpoint(small down) {
                display: block;
            }
            .content-item:first-child {
                @include breakpoint(medium down) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
    .content-item {
        width: 33.334%;
        margin-right: 7rem;
        &:last-child {
            min-height: 22.5rem;
            border-left: 1px solid $color-3--4;
            @include breakpoint(medium down) {
                display: none;
            }
        }

        &--result, &--result:last-child {
            flex: 1 0 66.665%;
            width: 66.666%;
            min-height: auto;
            margin: 0;
            @include breakpoint(medium down) {
                display: flex;
                flex: 1 0 100%;
                width: 100%;
                border-left: none;
                margin-top: 2.5rem;
            }
            @include breakpoint(small down) {
                display: block;
                margin-top: 0;
                padding: 0 0 3rem;
            }
        }

        form {
            margin: 0;
            .radio {
                margin: 0 0 .5rem;
                div {
                    margin: 0 0 1.5rem 0;
                    @include breakpoint(small down) {
                        margin: 0 0 1.1rem 0;
                    }
                }
            }
            .button-1 {
                height: 4rem;
                background-color: $color-3--3;
                font-size: 1.2rem;
                &:before {
                    color: $color-2--2;
                    font-size: 1.2rem;
                    left: 1.6rem;
                }
            }
            @include breakpoint(medium down) {
                padding-left: 3rem;
            }
            @include breakpoint(small down) {
                padding-left: 0;
            }
        }
        img {
            position: absolute;
            right: 10rem;
            bottom: -.3rem;
        }
        @include breakpoint(medium down) {
            width: 50%;
            margin-right: 5rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 3rem;
        }
    }
    .content-descr {
        font-size: 1.8rem;
        font-family: $typo-2;
        color: $color-1--2;
        font-weight: $bold;
        line-height: 1.2;
        @include breakpoint(small down) {
            font-size: 1.6rem;
        }
    }
}

.interesse-result {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    @include breakpoint(small down) {
        display: block;
    }

    &__chart-wrap {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-right: 4rem;
        @include breakpoint(medium down) {
            justify-content: center;
        }
        @include breakpoint(small down) {
            display: block;
            width: 100%;
            padding: 0 0 1.5rem;
        }

        #pieChartTarget {
            width: 200px;
            height: 200px;
            float: none;
        }
    }

    &__content {
        width: 50%;
        overflow: hidden;
        @include breakpoint(small down) {
            width: 100%;
        }
    }

    &__title {
        font-family: $typo-2;
        font-weight: $bold;
        font-size: 1.8rem;
        line-height: 1.2;
        color: $color-1--2;
        text-transform: uppercase;
        margin: 0 0 1.2rem;
    }

    .list-pie-chart {
        margin: 0;

        > li.pieChart {
            font-family: $typo-1;
            font-weight: $light;
            font-size: 1.6rem;
            line-height: 2rem;
            color: $color-black;
            padding-left: 2rem;
            margin: .5rem 0;
            position: relative;

            &:before {
                top: .2rem;
                left: 0;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__participants {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-black;
        margin: 1rem 0 0;
    }
}
