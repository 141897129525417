// @name Document list
// @description List of document in publications list

.list-document__listitems {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -1%;
    @include breakpoint(medium down) {
        flex-direction: column;
    }
    > * {
        flex: 1 1 31.3333%;
        margin: 0 1% 3em;
        @include breakpoint(medium down) {
            flex: 0 0 1;
        }
    }
    .button-1 {
        padding-left: 2.2em;
        padding-right: 1em;
        &:before {
            left: em(1.5, 1.4);
        }
    }
}

.list-document__item {
    &.list-document__item--postulate {
        text-align: right;
        align-self: flex-end;
    }
}

.list-document__title-file {
    font-size: 1.4em;
    font-weight: $normal;
    padding-left: em(4, 1.4);
    margin-bottom: em(1.5, 1.4);
    overflow: hidden;
    word-break: break-all;
    @include default-icons-absolute-before('\e005', 3.5rem, $color-2--2, 0.2rem, inherit, inherit, 0, 'icons-project');
    .list-document__file-size {
        display: block;
        font-weight: $light;
    }
    &.list-document__title-file--pdf {
        &:before {
            content: "\e003";
        }
    }
    &.list-document__title-file--doc {
        &:before {
            content: "\e001";
        }
    }
    &.list-document__title-file--jpg {
        &:before {
            content: "\e002";
        }
    }
    &.list-document__title-file--xls {
        &:before {
            content: "\e006";
        }
    }
    &.list-document__title-file--ppt {
        &:before {
            content: "\e004";
        }
    }
    &.list-document__title-file--zip {
        &:before {
            content: "\e007";
        }
    }
}

.list-document__download {
    a:before {
        content: "\e063";
        //font-size: 10px;
    }
}

.list-document__read {
    a:before {
        content: "\e06e";
        //font-size: 10px;
    }
}

// @name list document type 1
// @description Liste in bloc
.list-document-1__listitems {
    $list-document-1__listitems__font-size: 1.2;
    margin: 0 0 em(0.5, $list-document-1__listitems__font-size) 0;
    & + .list-document-1__listitems {
        padding: em(0.5, $list-document-1__listitems__font-size) 0 0;
    }
}

.list-document-1__item {
    $list-document-1__item__font-size: 1.2;
    font-size: #{$list-document-1__item__font-size}em;
    font-family: $typo-2;
    text-transform: uppercase;
    font-weight: $bold;
    margin: em(0.5, $list-document-1__item__font-size) 0;
    color: $color-3--3;
    position: relative;
    z-index: 99;
    a {
        color: inherit;
        display: inline-block;
        @include default-icons-before('\e063', 0 .5rem 0 0, 1.2rem, $color-1--2, -.2rem);
        &:before {
            padding-left: 0.2rem;
            width: 3.5rem;
            height: 3.5rem;
            line-height: 3.5rem;
            border: 1px solid $color-3--1;
            text-align: center;
            border-radius: 50%;
        }
        &:hover,
        &:focus {
            color: $color-1--2;
            text-decoration: underline;
            &:before {
                color: inherit;
            }
        }
    }
    &.list-document-1__item--read {
        a:before {
            content: "\e06e";
            color: $color-2--2;
        }
    }
}

.list-document-1__file-size {
    font-weight: $light;
    font-size: em(1, 1.2);
    white-space: nowrap;
}
