.main-menu {

    $this: &;
    padding-left: 3.5rem;

    &__title {
        display: none;
    }

    &__action-btn {
        display: none;
    }

    &__wrapper {
        width: 100%;
    }

    &__nav {}

    &__list {

        &.level-0 {}
        &.level-1 {
            display: flex;

            > #{$this}__item {

                > #{$this}__link {
                    padding: 2.7rem 4.3rem;
                    display: block;
                    font-family: $typo-2;
                    font-size: 2rem;
                    text-transform: uppercase;
                    color: $color-3--3;
                    font-weight: $normal;
                    transform: skewX(-13.6deg);
                    text-decoration: none;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        left: -2px;
                        bottom: 0;
                        border-right: 2px dotted $color-5--1;
                        height: 50%;
                    }

                    #{$this}__link-inner {
                        display: block;
                        transform: skewX(13.6deg);
                    }

                    &:hover, &:focus {
                        text-decoration: none;
                        color: $color-white;
                        background-color: $color-1--2;
                    }
                }

                &.is-current {

                    > #{$this}__link {
                        background-color: $color-1--2;
                        color: $color-white;

                        &::after {
                            display: none;
                        }
                    }

                }

                &.is-active {

                    #{$this}__dropdown {
                        display: block;
                    }

                }

                &:first-child {
                    > #{$this}__link {

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &.level-2 {
            @extend .clear-fix;
            margin-bottom: 2rem;

            > #{$this}__item {

                > #{$this}__link {
                    padding: .65rem 4rem .65rem .8rem;
                    text-transform: uppercase;
                    color: $color-white;
                    font-size: 1.8rem;
                    font-weight: $bold;
                    display: block;
                    position: relative;
                    transition: all 300ms ease;
                    margin-bottom: .5rem;

                    &::after {
                        content: '\e01b';
                        font-family: 'icons-default';
                        display: block;
                        position: absolute;
                        right: 2rem;
                        top: 1rem;
                        font-size: 1.2rem;
                        color: $color-1--2;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 300ms ease;
                    }

                    &:hover, &:focus {
                        background-color: $color-1--3;
                        text-decoration: none;

                        &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        &.level-3 {
            padding-left: 2rem;

            > #{$this}__item {
                position: relative;
                padding-left: 1.4rem;
                margin-bottom: 2px;

                &::before {
                    content: '\e01b';
                    font-family: 'icons-default';
                    color: $color-5--2;
                    position: absolute;
                    left: 0;
                    top: 2px;
                    font-size: 1rem;
                }

                > #{$this}__link {
                    color: $color-white;
                    font-size: 1.4rem;

                    &:hover, &:focus {
                        font-weight: $bold;
                    }
                }
            }
        }
    }

    &__dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $color-1--2;
        padding: 6rem 0 1rem;
    }

    &__container {
        @extend .wrapper-main;
        padding: 0;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2rem;
    }

    &__column {
        width: calc(100%/3);
        padding: 0 2rem;
    }

}
