.demarche {

    $this: &;

    &__btn {
        display: inline-flex;
        width: 10.5rem;
        height: 10.5rem;
        border-radius: 50%;
        justify-content: center;
        align-content: center;
        text-transform: uppercase;
        font-family: $typo-2;
        font-size: 1.4rem;
        padding: 3rem 1.5rem;
        background-color: $color-3--3;
        color: $color-2--1;
        white-space: normal;
        position: relative;
        overflow: hidden;
        z-index: 25;
        box-shadow: 3px 3px 0 0 rgba($color-black, .2);

        &::after {
            content: '\e018';
            font-family: 'icons-default';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 1.5rem;
            font-size: 1.4rem;
        }
    }

    &__btn-text {
        display: block;
    }

    &.is-active {

        #{$this}__btn {
            background-color: $color-3--8;
            box-shadow: 0 0 4rem rgba($color-black, .4);

            &::after {
                content: '\e022';
                font-size: 4rem;
                top: 50%;
                left: 50%;
                bottom: auto;
                margin-top: .5rem;
                transform: translate(-50%, -50%);
                color: $color-white;
            }
        }

        #{$this}__btn-text {
            text-indent: -9999px;
        }

        #{$this}__drop {
            display: block;
        }
    }

    &__drop {
        display: none;
        position: absolute;
        left: 0;
        padding: 1rem 0 4.5rem;
        top: -4rem;
        width: 100%;
        z-index: 20;
        background-color: $color-3--3;
        @media(min-width: 1280px) and (max-width: 1600px) {
            top: -2rem;
        }
    }

    &__header {
        padding-left: 33rem;
        padding-right: 1rem;
        width: calc(100% - 52.5rem);
        margin-bottom: 2.5rem;
    }

    &__main {
        padding-top: 8.5rem;
    }

    &__footer {
        text-align: right;
    }

    &__link {
        display: inline-block;
        position: relative;
        font-family: $typo-2;
        font-size: 1.6rem;
        text-transform: uppercase;
        color: $color-white;
        padding-left: 1.2rem;

        &::before {
            content: '/';
            font-family: inherit;
            position: absolute;
            left: 0;
            color: $color-2--2;
        }
    }

    &__nav {
        padding: 0 9rem;
    }

    &__nav-list {
        margin: 0 -2rem;
        display: flex;
        flex-wrap: wrap;
    }

    &__nav-item {
        padding: 0 2rem;
        position: relative;
        width: 20%;
        margin-bottom: 5rem;

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            border-left: 2px dotted $color-2--2;
            transform: skewX(-16deg);
        }

        &:first-child, &:nth-child(6n) {
            &::before {
                display: none;
            }
        }
    }

    &__nav-link {
        display: block;
        font-family: $typo-1;
        font-size: 1.6rem;
        font-weight: $light;
        color: $color-5--5;

        &:hover, &:focus {
            font-weight: $bold;
            color: $color-2--1;
        }
    }

    &__nav-icon {
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            height: 4rem;
            width: auto;
        }
    }

    &__nav-text {
        margin: 0 auto;
        display: block;
        text-align: center;
        width: 100%;
        max-width: 14.5rem;
        padding-bottom: 2.5rem;
    }

    &__title {
        text-align: right;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: $light;
        color: $color-5--4;
        font-family: $typo-2;
    }
}

.mobile-demarche {
    display: none;
    @include breakpoint(medium down) {
        display: block;

        &.is-active {

            .demarche__btn {
                //background-color: $color-1--2;
                color: $color-white;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);

                &-text {
                    text-indent: 0;
                }

                &:after {
                    content: '\e019';
                    font-family: 'icons-default';
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 1.4rem;
                    font-size: 1.4rem;
                }
            }

            .demarche__drop {
                display: block;
            }
        }

        .demarche__btn {
            width: 100%;
            height: auto;
            border-radius: 0;
            text-align: center;
            line-height: 2.2rem;
            padding: 3.1rem 2.8rem;
            border-bottom: 1px dotted $color-3--2;
            outline-color: $color-white;
            box-shadow: none;

            &:hover, &:focus {
                //background-color: $color-1--2;
                color: $color-white;
            }

            &:focus {
                outline-color: $color-white;
            }

            &:after {
                bottom: 1.4rem;
                transition: all 300ms ease;
            }

            &-text {
                transition: all 300ms ease;
            }
        }

        .demarche__drop {
            display: none;
            position: static;
            top: auto;
            left: auto;
            border-bottom: 1px dotted $color-3--2;
            padding: 3rem 1rem;
        }

        .demarche__header {
            display: none;
        }

        .demarche__main {
            padding: 0;
        }

        .wrapper-main {
            max-width: none;
        }

        .demarche__nav {
            padding: 0 2rem;

            &-list {
                justify-content: center;
            }

            &-item {
                margin-bottom: 2.5rem;
                @media(max-width: 1100px) {
                    width: 33.333%;

                    &:nth-child(6n):before {
                        display: block;
                    }

                    &:nth-child(3n + 1):before {
                        display: none;
                    }
                }

                @include breakpoint(small down) {
                    width: 50%;

                    &:nth-child(2n):before {
                        left: .1rem;
                    }

                    &:nth-child(3n + 1):before {
                        display: block;
                    }

                    &:nth-child(2n + 1):before {
                        display: none;
                    }
                }

                @media(max-width: 450px) {
                    width: 100%;

                    &:before {
                        display: none !important;
                    }
                }
            }

            &-icon {
                padding: 1.5rem;
            }

            &-text {
                padding-bottom: 1.5rem;
            }
        }

        .demarche__footer {
            text-align: center;
            padding-top: 1rem;

            a {
                display: inline-block;
            }
        }
    }
}
