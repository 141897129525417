// @name Agenda
// @description Sections content below the content

.agenda {

    $this: &;

    .heading-block {
        .title-1 {
            color: $color-3--3;
        }
        &-subtitle {
            a {
                color: $color-black;
                &:before {
                    color: #107ec0;
                }
            }
        }
    }

    &__title {
        margin-top: 0;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 4em 0 1em;
        .agenda-item {
            width: 100%;
            @include breakpoint(medium) {
                width: 50%;
            }
            @include breakpoint(large) {
                width: 25%;
            }
        }
    }
}

.agenda-item {

    $this: &;

    position: relative;
    transition: all 0.5s ease;
    background-color: #f9fafb;
    padding: 3em;
    @include breakpoint(medium only) {
        padding: 0.5em;
        display: flex;
    }
    @include breakpoint(large) {
        padding: 1em;
    }

    &:nth-child(odd) {
        background-color: #f3f6f8;
    }

    &#{$this}--focus {
        background-color: $color-2--2;
        @extend %bg-pattern-white;
        width: 100% !important;
        @include breakpoint(small only) {
            padding: 1em;
        }
        @include breakpoint(medium) {
            padding: 1em;
            display: flex;
        }
        #{$this}__img {
            @include breakpoint(large) {
                width: 60%;
                flex-shrink: 0;
                max-height: 400px;
            }
        }
        #{$this}__date {
            top: -3.5rem;
            left: 0;
            margin: 0;
            @include breakpoint(medium only) {
                left: 0;
                top: 1.6rem;
                margin-left: -4.2rem;
                transform: none;
            }
            @include breakpoint(large) {
                //right: 40%;
                //left: initial;
                left: -3.5rem;
                //top: 50%;
                top: 2.5rem;
                //transform: translate(50%, -50%);
                transform: none;
                //margin-top: -4rem;
            }

            time {
                width: 71px;
                height: 71px;
            }
            .day {
                font-size: 2.2em;
            }
            .month {
                font-size: 1.4em;
            }
        }
        #{$this}__wrap {
            color: $color-3--3;
            padding-left: 8rem;
            padding-bottom: 2rem;
            @include breakpoint(medium) {
                padding-left: 4em;

            }
            @include breakpoint(large) {
                align-self: center;
                padding-left: 6em;
            }
            > * {
                color: inherit;
            }
        }
        #{$this}__title {
            font-size: 2em;
            font-weight: $bold;
            @include breakpoint(large) {
                font-size: 2.8em;
            }
        }
    }

    &__img {
        position: relative;
        display: block;
        @include breakpoint(medium only) {
            width: 50%;
            flex-shrink: 0;
        }
        &-empty {
            width: 100%;
            display: block;
            padding-bottom: 5%;
            @include breakpoint(medium) {
                padding-bottom: 66.75%;
            }
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;' //object-fit polyfill
        }
    }
    &__wrap {
        color: $color-3--2;
        position: relative;
        padding: 1em 1em 0 6.5em;
        @include breakpoint(medium only) {
            padding: 2em 2em 2em 2.5em;
        }
        @include breakpoint(large) {
            padding: 3em 4em 4em 10em;
        }

    }
    &__date {
        position: absolute;
        margin: 0;
        z-index: 0;
        top: -30px;
        left: -1em;
        color: $color-white;
        @include breakpoint(medium only) {
            //left: initial;
            left: 0;
            //right: 95%;
            top: 1rem;
            margin-left: -4.3rem;
            transform: none;
        }
        @include breakpoint(large) {
            left: 2rem;
            top: -3.5rem;
        }
        time {
            margin: 0;
        }
    }
    &__category {
        font-size: 1.2em;
        font-weight: $normal;
        position: relative;
        color: $color-3--3;
        &:before {
            content: '// ';
            color: $color-1--2;
            font-style: italic;
            letter-spacing: -0.1em;
            margin-right: 0.5em;
        }
        &--short {
            margin: 0 auto;
            max-width: 150px;
        }
    }
    &__title {
        font-weight: $light;
        font-size: 1.6em;
        color: $color-1--2;
        border: none;
        padding: 0;
        line-height: 1.2;
        margin: 0.5em 0;
        @include breakpoint(large) {
            font-size: 1.8em;
        }
    }
    &__link {
        color: inherit;
    }
    &__desc {
        font-size: 1.3em;
        margin-bottom: 1em;
        font-weight: $light;
        @include breakpoint(large) {
            font-size: 1.4em;
        }
    }
    &__time,
    &__place {
        font-weight: $light;
        font-family: $typo-2;
        color: #106674;
        letter-spacing: 0.5px;
        font-size: 1.2em;
    }
}
