// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-publications {
    &__picture {
        display: block;
        text-align: center;
        margin-bottom: 2rem;
        @include breakpoint(medium) {
            float: left;
            img {
                margin-right: 2em;
            }
        }
    }
    &__wrapper {
        overflow: hidden;
    }
    &__title {
        $bloc-publications__title__font-size: 2;
        font-size: #{$bloc-publications__title__font-size}em;
        font-family: $typo-2;
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
        a {
            color: $color-2--3;
            &:hover,
            &:focus {
                color: $color-1--2;
            }
        }
    }
    &__downloadsize {
        font-weight: $thin;
        font-size: 1.3em;
        font-style: italic;
    }
    &__description {
        font-size: 1.5em;
        font-weight: $light;
        margin-bottom: 1em;
    }

}


.column-bloc {
    .bloc-publications__title {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}


.home-publications {
    margin-right: 4rem;
    width: 65%;
    @include breakpoint(medium down) {
        margin-right: 0;
        width: 100%;
    }
    .heading-block {
        @include breakpoint(small down) {
            margin-bottom: 4.5rem;
        }
    }
    h3 {
        font-size: 3rem;
        font-weight: $light;
        color: $color-3--5;
    }
    .bloc-publications {
        &__listitems {
            display: flex;
            @include breakpoint(small down) {
                flex-direction: column;
            }
        }
        &__wrapper {
            @include breakpoint(small down) {
                width: 21rem;
                margin: 0 auto;
            }
        }
        &__item {
            width: 64.5%;
            margin-right: 3rem;
            @include breakpoint(small down) {
                margin-right: 0;
                margin-bottom: 2.5rem;
                width: 100%;
            }
        }
        &__slider {
            width: 35.5%;
            @include breakpoint(small down) {
                width: 100%;
            }

            .list-document-1__listitems {
                margin: 0;
            }

            .list-document-1__item {
                display: inline-block;
                margin: .5rem .2rem 0 .2rem;
            }

            .slick-prev, .slick-next {
                font-size: 0;
                line-height: 0;
                position: absolute;
                top: 50%;
                left: -2rem;
                transform: translateY(-50%);
                display: block;
                padding: 2rem 0;
                //margin-top: -2rem;
                background: $color-white;
                &:before {
                    content: "\e010";
                    display: block;
                    font-family: 'icons-default';
                    font-size: 2.6rem;
                    color: $color-1--2;
                }
                //@include breakpoint(medium down) {
                //    left: 3rem;
                //}
                //@include breakpoint(small down) {
                //    left: 0;
                //}
            }
            .slick-next {
                right: -2rem;
                left: auto;
                //@include breakpoint(medium down) {
                //    right: 3rem;
                //    left: auto;
                //}
                //@include breakpoint(small down) {
                //    right: 0;
                //    left: auto;
                //}
                &:before {
                    content: "\e011";
                    display: block;
                    font-family: 'icons-default';
                    font-size: 2.6rem;
                    color: $color-1--2;
                }
            }
        }
        &__title {
            font-family: $typo-1;
            font-size: 3rem;
            font-weight: $light;
            text-transform: none;
            a {
                color: $color-3--3;
            }
        }
        &__description {
            font-size: 1.6rem;
            font-weight: $light;
            color: $color-3--2;
            span {
                color: $color-1--1;
            }
        }

        &__slider {
            padding: 2rem;
            border: 1rem solid rgba(114, 199, 204, .3);;
        }
        &__content {
            text-align: center;
            h4 {
                a {
                    font-size: 1.6rem;
                    font-weight: $bold;
                    color: $color-black;
                }
            }
        }
        &__slider-img {
            display: block;
            margin: 0 6rem 1rem;
            img {
                display: block;
                width: 100%;
            }
        }
    }
}
