// @name Header page
.header {
    margin-top: 4rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 400;
    @media(min-width: 1280px) and (max-width: 1600px) {
        margin-top: 2rem;
    }
    @include breakpoint(medium down) {
        margin-top: 0;
    }

    &__inner {
        display: flex;
        //flex-wrap: wrap;
        flex-wrap: nowrap;
        position: relative;
        height: 8rem;
        background-color: $color-white;
        @include breakpoint(medium down) {
            height: 7rem;
            background-color: #fafafa;
            position: relative;
        }

        &:after {
            @include breakpoint(medium down) {
                content: '';
                position: absolute;
                right: 7rem;
                bottom: 0;
                border-right: 2px dotted $color-2--2;
                height: 50%;
                transform-origin: 0 100%;
                transform: skewX(-13.6deg);
            }
        }
    }

    &__left-part {
        width: calc(100% - 52.5rem);
        padding-left: 30rem;
        padding-right: 3rem;
        //margin-right: 10.5rem;
        @media(min-width: 1280px) and (max-width: 1600px) {
            width: calc(100% - 47rem);
            padding-left: 21.5rem;
            padding-right: 2rem;
        }
        @include breakpoint(medium down) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__right-part {
        width: 42rem;
        display: flex;
        //flex-wrap: wrap;
        flex-wrap: nowrap;
        padding-left: 3rem;
        @media(min-width: 1280px) and (max-width: 1600px) {
            width: 36.5rem;
            padding-left: 2rem;
        }
        @include breakpoint(medium down) {
            display: none;
        }
    }

    &__logo {
        position: absolute;
        //top: -17.2rem;
        top: -4rem;
        left: -.2rem;
        z-index: 250;
        width: 31.2rem;
        height: 17.9rem;
        padding: 2.7rem 4.5rem 7rem 5rem;
        overflow: hidden;
        @media(max-width: 1600px) {
            width: 22.5rem;
            height: 15rem;
            padding: 4.2rem 4.1rem 4.5rem 3.7rem;
            //top: -9.5rem;
            top: -2rem;
        }
        @include breakpoint(medium down) {
            width: 23.5rem;
            height: 13rem;
            padding-top: 2.2rem;
            padding-right: 5.1rem;
            top: 0;
        }
        @include breakpoint(small down) {
            width: 20rem;
            height: 10.2rem;
            padding: 0.6rem 4.5rem 4rem 2.4rem;
            top: 0;
        }
        //position: absolute;
        //width: 30.2rem;
        //height: 30.2rem;
        //top: -17.2rem;
        //left: -.2rem;
        //border-radius: 50%;
        //background-color: $color-white;
        //box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
        //padding: 16rem 4.5rem 6rem 4rem;
        //z-index: 250;
        //@media(max-width: 1600px) {
        //    width: 21.5rem;
        //    height: 21.5rem;
        //    box-shadow: 0 -5px 25px 0 rgba(0, 0, 0, 0.2);
        //    padding: 11.7rem 3.1rem 3.5rem 2.7rem;
        //    top: -9.5rem;
        //}
        //@include breakpoint(small down) {
        //    width: 18rem;
        //    height: 18rem;
        //    box-shadow: 5px 0 30px 0 rgba(0, 0, 0, .2);
        //    padding: 9.4rem 2.5rem 3rem 2.4rem;
        //    top: -8.8rem;
        //}
        &:after {
            content: '';
            display: block;
            width: 30.2rem;
            height: 30.2rem;
            position: absolute;
            bottom: 1rem;
            left: 0;
            border-radius: 50%;
            background-color: $color-white;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
            z-index: -1;
            @media(max-width: 1600px) {
                width: 21.5rem;
                height: 21.5rem;
                box-shadow: 0 -5px 25px 0 rgba(0, 0, 0, 0.2);
            }
            @include breakpoint(small down) {
                width: 18rem;
                height: 18rem;
                box-shadow: 5px 0 30px 0 rgba(0, 0, 0, .2);
            }
        }

        img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    &__demarche {
        margin-top: -6rem;
        @media(min-width: 1280px) and (max-width: 1600px) {
            margin-top: -4rem;
        }
        @include breakpoint(medium down) {
            display: none;
        }
    }

    &__profile {
        //margin-left: 1rem;
    }
}

.page-banner {
    @include breakpoint(medium down) {
        padding-top: 7rem;
    }
}
