// @name Pager list
.pager {
    width: 100%;
    ul {
        font-family: $typo-2;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        font-weight: $light;
        font-style: normal;
        text-transform: uppercase;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.4em;
        margin: 0 0.1rem;
        a, span {
            padding: em(1, 1.4) em(1.4, 1.4);
            line-height: 1em;
            display: inline-block;
            vertical-align: middle;
        }
        a {
            color: $color-black;
            background: $color-3--1;
            text-decoration: none;
            transition: all ease .2s;
            &:hover, &:focus {
                color: color-contrast($color-1--3);
                background: $color-1--3;
            }
        }
        span {
            cursor: default;
            color: color-contrast($color-1--2);
            background: $color-1--2;
        }
        &.pager__prev,
        &.pager__next {
            a,
            span {
                padding: em(1, 1.4) em(2.4, 1.4);
                @include breakpoint(medium down) {
                    text-indent: -9999px;
                    white-space: nowrap;
                    position: relative;
                    padding: em(1, 1.4) em(1.8, 1.4);
                }
                &:before,
                &:after {
                    @include breakpoint(medium down) {
                        text-indent: 0;
                        line-height: 0;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin: 0;
                    }
                }
            }
            // Inactive
            span {
                color: color-contrast($color-3--1);
                background: $color-3--1;
            }
        }
        &.pager__prev {
            float: left;
            a,
            span {
                float: left;
                @include default-icons-before('\e026', 0 .5rem 0 0, 1.2rem, $color-2--2, -.1rem);
                &:hover:before,
                &:focus:before {
                    color: #fff;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:before,
                &:hover:before,
                &:focus:before {
                    color: $color-3--2;
                }
            }
        }
        &.pager__next {
            float: right;
            a,
            span {
                float: right;
                @include default-icons-after('\e027', 0 0 0 .5rem, 1.2rem, $color-2--2, -.1rem);
                &:hover:after,
                &:focus:after {
                    color: #fff;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:after,
                &:hover:after,
                &:focus:after {
                    color: $color-3--2;
                }
            }
        }
    }

    &--event {
        li {
            font-size: 1.2rem;
            font-weight: $bold;
            border: 1px solid $color-3--7;
            &.pager__prev,
            &.pager__next {
                a,
                span {
                    padding: .8rem 1.7rem;
                    &:before, &:after {
                        content: "";
                        font-size: 2rem;
                        color: $color-2--2;
                        vertical-align: -.5rem;
                        margin: 0;
                    }
                }
                a,
                span {
                    color: $color-3--3;
                    background-color: transparent;
                }
            }
            &.pager__prev {
                a,
                span {
                    &:before {
                        content: "\e010";
                    }
                }
            }
            &.pager__next {
                a,
                span {
                    &:before {
                        content: none;
                    }
                    &:after {
                        content: "\e011";
                    }
                    &:hover,
                    &:focus {
                        background-color: $color-3--3;
                        color: $color-white;
                    }
                }
            }
            a,
            span {
                padding: 1.3rem 1.7rem;
            }
            a {
                background-color: transparent;
            }
        }
    }
}

// @name Pager infinite scroll

@keyframes spin {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(-360deg) translateY(-50%);
    }
}

.pager-infinite {
    margin: 2em 0;
    text-align: center;
    // @dependence .button-1
    .pager-infinite__button {
        &:before {
            content: "\e05f";
        }
    }
    .pager-infinite__loading {
        &:before {
            animation: spin 1s infinite linear;
            content: "\e05f";
            transform-origin: top;
        }
    }
}

// @name Pager single
// @dependence .pager
.pager-single {
    .pager-single__back-to-list {
        a {
            background: $color-1--2;
            color: color-contrast($color-1--2);
            font-weight: $bold;
            padding: em(1, 1.4) em(2.4, 1.4);
            @include default-icons-before('\e02f', 0 .5rem 0 0, 1.2rem, color-contrast($color-1--2), -.1rem);
        }
    }
}

// @name Pager single title
// TODO

// @name Pager alphabet
.pager-alphabet {
    margin-bottom: 5em;
}

.pager-alphabet__title {
    font-size: 1.4em;
    font-weight: $bold;
    margin-bottom: em(1.5, 1.4);
}

.pager-alphabet__listitems {
    text-align: left;
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: .2em 0.1em;
        &.pager-alphabet__item--current {
            > a {
                background: $color-1--2;
                color: $color-white;
            }
        }
        > a, > span {
            $pager-alphabet__listitems__a__font-size: 1.4;
            font-size: #{$pager-alphabet__listitems__a__font-size}em;
            background: $color-3--1;
            color: $color-black;
            display: block;
            padding: em(.6, $pager-alphabet__listitems__a__font-size) em(1.1, $pager-alphabet__listitems__a__font-size);
        }
        > a {
            &:hover, &:focus {
                background: $color-1--3;
                color: $color-white;
            }
        }
        > span {
            background: $color-white;
            border: 1px solid darken($color-3--1, 3%);
            color: darken($color-3--1, 15%);
        }
    }
}

// @name Pager single xl
.pager-single-xl {
    width: 100%;
    margin-bottom: 13em;
    ul {
        font-family: $typo-2;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        font-weight: $light;
        font-style: normal;
        text-transform: uppercase;
        position: relative;
        @extend .clear-fix;
    }

    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.4em;
        &.pager__prev,
        &.pager__next {
            width: 49%;
            .pager__button {
                padding: em(1, 1.4) em(2.4, 1.4);
                @include breakpoint(medium down) {
                    text-indent: -9999px;
                    white-space: nowrap;
                    position: relative;
                    padding: em(1, 1.4) em(1.8, 1.4);
                }
                &:before,
                &:after {
                    @include breakpoint(medium down) {
                        text-indent: 0;
                        line-height: 0;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin: 0;
                    }
                }
            }
            a.pager__link {
                &:hover,
                &:focus {
                    .pager__button {
                        &:before,
                        &:after {
                            color: $color-white;
                        }
                    }
                }
            }
            span.pager__link {
                .pager__button {
                    color: color-contrast($color-3--1);
                    background: $color-3--1;
                }
            }
        }
        &.pager__prev {
            float: left;
            .pager__button {
                @include default-icons-before('\e026', 0 .5rem 0 0, 1.2rem, $color-2--2, -.1rem);
            }
            .pager__link {
                &:hover, &:focus {
                    color: $color-white;
                }
            }
            span.pager__link {
                .pager__button {
                    color: $color-3--2;
                }
                .pager__button:before, .pager__button:hover:before, .pager__button:focus:before {
                    color: $color-3--2;
                }
            }
            .pager__article {
                text-align: left;
                padding-left: 1rem;
            }
        }
        &.pager__next {
            float: right;
            .pager__button {
                @include default-icons-after('\e027', 0 0 0 .5rem, 1.2rem, $color-2--2, -.1rem);
            }
            .pager__link {
                justify-content: flex-end;
                &:hover, &:focus {
                    color: $color-white;
                }
            }
            span.pager__link {
                .pager__button {
                    color: $color-3--2;
                }
                .pager__button:after, .pager__button:hover:after, .pager__button:focus:after {
                    color: $color-3--2;
                }
            }
            .pager__article {
                text-align: right;
                padding-right: 1rem;
            }
        }
    }
    .pager__link {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        @include breakpoint(medium down) {
            //flex-wrap: wrap;
            align-items: flex-start;
        }
    }
    a.pager__link {
        &:hover,
        &:focus {
            text-decoration: none;
            > .pager__button {
                color: color-contrast($color-1--3);
                background: $color-1--3;
            }
        }
        > .pager__button {
            color: $color-black;
            background: $color-3--1;
            text-decoration: none;
            transition: all ease .3s;
        }
        span.pager__link {
            color: color-contrast($color-1--2);
            background: $color-1--2;
        }
    }
    .pager__button {
        padding: em(1, 1.4) em(1.4, 1.4);
        line-height: 1em;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        @include breakpoint(medium down) {
            //order: 1;
        }
    }
    .pager__article {
        text-transform: none;
        font-size: em(1.3, 1.4);
        color: $color-black;
        line-height: normal;
        display: inline-block;
        vertical-align: middle;
        @include breakpoint(medium down) {
            //order: 2;
        }
    }
    .pager-single-xl__back-to-list {
        position: absolute;
        top: 140%;
        left: 50%;
        transform: translateX(-50%);
        a.pager__link {
            display: inline-block;
            &:hover, &:focus {
                .pager__button {
                    color: color-contrast($color-1--3);
                    background: $color-1--3;
                }
            }
            .pager__button {
                background: $color-1--2;
                color: color-contrast($color-1--2);
                font-weight: $bold;
                padding: em(1, 1.4) em(2.4, 1.4);
                @include default-icons-before('\e02f', 0 .5rem 0 0, 1.2rem, color-contrast($color-1--2), -.1rem);
            }
        }
    }
}
