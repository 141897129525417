// @name Signature stratis
.stratis {
    width: 100%;
    font-size: 1.3em;
    color: $color-white;
    text-transform: uppercase;
    text-align: right;
    position: absolute;
    bottom: 0;
    font-weight: $light;
    font-family: $typo-2;
    span {
        display: none;
    }
    a {
        display: inline-block;
        padding: 4px 5px;
        color: inherit;
        background: #c70000;
        text-decoration: none;
        &:hover,
        &:focus {
            color: #c70000;
            background: $color-white;
        }
    }
}
