// @name Cross menu
//.footer {
//    .menu-cross {
//        @include breakpoint(large only) {
//            flex: 0 1 auto;
//            margin-right: -3rem;
//        }
//        @include breakpoint(xlarge) {
//            min-width: 28%;
//            margin-right: -13rem;
//        }
//    }
//}

.menu-cross {
    text-transform: uppercase;
    overflow: hidden;

    li {
        vertical-align: top;
        display: inline-block;
        width: 49%;
        margin: 0.2em 0;
        @include breakpoint(large only) {
            width: 100%;
        }
        a {
            display: block;
            color: $color-white;
            font-weight: $light;
            font-size: 1.3em;
            font-family: $typo-2;
            text-decoration: none;
            padding: 0.2em 0.7em 0.2em 1.5em;
            line-height: 1.2em;
            @include breakpoint(small down) {
                padding-left: 1.4rem;
                padding-right: .5rem;
            }
            @include default-icons-absolute-before('\e01b', 1.1rem, $color-1--4, 0.2em, inherit, inherit, 0);
            &:before {
                line-height: 1.7;
            }
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
        &.active a {
            color: $color-1--2;
            text-decoration: none;
            font-weight: $bold;
        }
    }
}
