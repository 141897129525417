// @name bloc news

// @name Bloc news in column
.news {
    .heading-block {
        .title-1 {
            color: $color-3--3;
        }
        &-subtitle {
            a {
                color: $color-black;
                &:before {
                    color: #107ec0;
                }
            }
        }
    }
    &__wrap {

        $this: &;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 4em -1em 1em;
        @include breakpoint(large) {
            margin-left: -1.5em;
            margin-right: -1.5em;
        }
        .news-item {
            width: 100%;
            @include breakpoint(medium) {
                width: calc((100% / 2) - 2em);
            }
            @include breakpoint(large) {
                width: calc((100% / 4) - 3em);
            }
        }

        &#{$this}--home {
            .news-item {
                @include breakpoint(medium) {
                    width: calc((100% / 3) - 2em);
                }
                @include breakpoint(large) {
                    width: calc((100% / 3) - 3em);
                }
                &:last-child {
                    @include breakpoint(small down) {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
        &#{$this}--content {
            @include breakpoint(medium only) {
                .news-item {
                    display: flex;
                    align-items: center;
                }
                .news-item__img {
                    flex-shrink: 0;
                    align-self: flex-start;
                    width: 60%;
                }
                .news-item__content {
                    padding: 0 0 0 20px;
                    text-align: left;
                    .category {
                        background-position: left bottom;
                        margin-bottom: 0.5em;
                    }
                    &:before {
                        content: none;
                    }
                }
            }
        }

    }
}

.news-item {

    $this: &;

    margin: 0 1em 3em;
    position: relative;
    @include breakpoint(large) {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
    &:not(.news-item--focus) {
        @include breakpoint(small only) {
            margin: 0 3em 3em;
        }
    }
    &#{$this}--focus {
        width: 100% !important;
        @include breakpoint(medium) {
            display: flex;
            align-items: center;
        }
        #{$this}__img {
            position: relative;
            overflow: hidden;
            @include breakpoint(medium) {
                width: 55%;
                flex-shrink: 0;
                max-height: 212px;
            }
            @include breakpoint(large) {
                max-height: 380px;
                width: 60%;
            }
            &:after {
                content: '';
                position: absolute;
                display: block;
                background-color: $color-white;
                border-radius: 50%;
                width: 200px;
                height: 200px;
                left: calc(50% - 100px);
                top: calc(100% - 20px);
                @include breakpoint(medium) {
                    left: calc(100% - 50px);
                    top: -#{ (300 - 212) / 2 }px;
                    width: 300px;
                    height: 300px;
                }
                @include breakpoint(large) {
                    left: calc(100% - 100px);
                    top: -#{ (480 - 380) / 2 }px;
                    height: 480px;
                    width: 480px;
                }
            }
        }
        #{$this}__content {
            text-align: left;
            &:before {
                content: none;
            }
            @include breakpoint(small only) {
                padding: 2rem 5%;
                text-align: center;
            }
            @include breakpoint(medium) {
                text-align: left;
                padding: 0;
            }
            .category {
                color: $color-3--3;
                background: none;
                padding: 0;
            }
            h3 {
                font-family: $typo-2;
                font-size: 1.8em;
                font-weight: $bold;
                color: $color-1--2;
                margin-bottom: 0.5em;
                line-height: 1.2;
                text-transform: uppercase;
                @include breakpoint(medium) {
                    font-size: 2em;
                }
                @include breakpoint(large) {
                    font-size: 2.8em;
                }
            }
        }
    }
    &__img {
        display: block;
        position: relative;
        z-index: -2;
        img {
            display: block;
            width: 100%;
        }
    }
    &__content {
        text-align: center;
        padding: 0 10%;

        &:before {
            content: '';
            display: block;
            width: 100px;
            height: 120px;
            margin: -40px auto -70px;
            transform: rotate(90deg);
            background: url("#{$path-images}ui/contacter-decor.png") no-repeat center center;
            z-index: -1;
            position: relative;
        }
        .category {
            padding-bottom: 1em;
            margin-bottom: 1em;
            position: relative;
            @extend %bg-title-decor;
            background-size: 25px;
            background-position: center bottom;
            background-repeat: no-repeat;
        }
        h3 {
            font-size: 1.6em;
            font-weight: $light;
            color: $color-3--3;
            a {
                color: inherit;
                display: block;
            }
        }
    }
    &__description {
        font-weight: $light;
        font-size: 1.4em;
        @include breakpoint(medium only) {
            font-size: 1.3em;
        }
    }
}
