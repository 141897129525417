.projects {
    overflow-x: hidden;

    .heading-block {
        margin-bottom: 4rem;
        @include breakpoint(medium down) {
            margin-bottom: 4.5rem;
        }
        h2 {
            color: $color-black;
            @include breakpoint(large) {
                font-size: 4rem;
                line-height: 4.4rem;
            }
        }

        .heading-block-subtitle {
            a {
                color: $color-black;
            }
        }
    }

    .slick-slider {
        margin-bottom: 7.5rem;
        @include breakpoint(medium down) {
            margin-bottom: 6rem;
        }
        @media(max-width: 980px) {
            overflow: hidden;
        }
        @include breakpoint(small down) {
            margin-bottom: 3rem;
        }
        //button {
        //    width: 50px;
        //    height: 60px;
        //}

        &__item {
            outline: none;
            overflow: hidden;
            @include breakpoint(medium down) {
                position: relative;
            }
        }
    }
    .slick-list {
        overflow: visible;
    }
    .slick-slider__img {
        display: block;
        padding: 5rem 3rem 5rem 0;
        text-align: right;
        height: 51.8rem;
        @include breakpoint(medium down) {
            padding: 2rem 1.5rem 2rem 0;
            height: 32.7rem;
        }
        @media(max-width: 980px) {
            padding: 0;
            width: 100%;
            overflow: hidden;
        }
        @include breakpoint(small down) {
            height: 18rem;
        }
        img {
            //display: block;
            display: inline;
            vertical-align: top;
            //margin: 0 auto;
            margin: 0;
            //transform: scale(0.9);
            opacity: .6 !important;
            //width: 100%;
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            //@include breakpoint(medium down) {
            //    transform: scale(1);
            //    opacity: 1 !important;
            //}
            @media(max-width: 980px) {
                opacity: 1 !important;
                width: auto;
                min-width: 100%;
                max-width: none;
                height: auto;
                min-height: 100%;
                max-height: none;
                object-fit: cover;
                font-family: 'object-fit: cover;';
            }
        }
    }
    .slick-center {
        position: relative;
        .slick-slider__img {
            padding: 0;
            overflow: hidden;
            img {
                display: block;
                width: auto;
                max-width: none;
                min-width: 100%;
                height: auto;
                max-height: none;
                min-height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover;';
                opacity: 1 !important;
                //transform: scale(1);
            }
        }

        .slick-slider__descr {
            display: block;
        }

        + .slick-slider__item {
            .slick-slider__img {
                padding: 5rem 0 5rem 3rem;
                text-align: left;
                @include breakpoint(medium down) {
                    padding: 2rem 0 2rem 1.5rem;
                }
                @media(max-width: 980px) {
                    padding: 0;
                }
            }
        }
    }
    button.slick-arrow {
        width: 5rem;
        height: 6rem;
        @include breakpoint(medium down) {
            width: 4rem;
            height: 4rem;
        }
        @include breakpoint(small down) {
            width: 3rem;
            height: 3rem;
        }
    }
    .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        z-index: 100;
        top: 50%;
        //left: 5rem;
        left: -14rem;
        transform: translateY(-50%);
        display: block;
        padding: 0;
        background: transparent;
        overflow: hidden;
        @media(max-width: 1700px) {
            left: 4rem;
        }
        @include breakpoint(medium down) {
            left: 1rem;
        }
        @include breakpoint(small down) {
            top: 9rem;
            left: 1.5rem;
        }
        &:before {
            content: "\e01a";
            display: block;
            font-family: 'icons-default';
            font-size: 6rem;
            line-height: 1;
            color: $color-white;
            text-shadow: -2px 2px 8px rgba(0, 0, 0, .6);
            margin-left: -1rem;
            @include breakpoint(medium down) {
                font-size: 4rem;
                margin-left: -.2rem;
            }
            @include breakpoint(small down) {
                font-size: 3rem;
            }
        }
        &:hover, &:focus {
            &:before {
                color: $color-3--3;
            }
        }
    }
    .slick-next {
        //right: 5rem;
        right: -14rem;
        left: auto;
        @media(max-width: 1700px) {
            right: 4rem;
            left: auto;
        }
        @include breakpoint(medium down) {
            right: 1rem;
            left: auto;
        }
        @include breakpoint(small down) {
            right: 1.5rem;
            left: auto;
        }
        &:before {
            content: "\e01b";
            text-shadow: 2px 2px 8px rgba(0, 0, 0, .6);
            margin: 0;
        }
    }

    .slick-slider__descr {
        position: relative;
        display: block;
        @extend %bg-pattern-white-2;

        @include breakpoint(small only) {
            background: #fff;
        }
        @media(max-width: 980px) {
            display: block;
        }
        @include breakpoint(medium) {
            display: none;
            position: absolute;
            top: -6rem;
            left: 6rem;
            border-radius: 50%;
            width: 340px;
            height: 340px;
        }

        &:after,
        &:before {
            @include breakpoint(small only) {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 50%;
            }
        }
        &:after {
            @extend %bg-pattern-white-2;
            @include breakpoint(small only) {
                //background: none;
                //margin-top: -5rem;
                margin-top: -3.5rem;
                width: 340px;
                height: 340px;
            }
        }
        &:before {
            @include breakpoint(small only) {
                //margin-top: -3rem;
                margin-top: -1.5rem;
                width: 285px;
                height: 285px;
                background: #fff;
            }
        }

    }

    .slick-slider__bg {
        position: absolute;
        //z-index: -1;
        top: 2.7rem;
        left: 2.7rem;
        width: 285px;
        height: 285px;
        background-color: #fff;
        border-radius: 50%;
        @include breakpoint(small only) {
            display: none;
        }
    }

    .descr__wrap {
        padding: 9rem 5.8rem;
        text-align: center;
        width: 100%;
        position: relative;
        z-index: 10;

        @include breakpoint(small only) {
            padding: 1rem;
        }

        @include breakpoint(medium) {
            position: absolute;
            width: 340px;
            height: 340px;
        }

        .category {
            padding-bottom: 1em;
            margin-bottom: 1em;
            position: relative;
            @extend %bg-title-decor;
            background-size: 25px;
            background-position: center bottom;
            background-repeat: no-repeat;
            color: $color-3--3;
        }

        .title {
            font-family: $typo-2;
            font-size: 1.8rem;
            font-weight: $bold;
            color: $color-1--2;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }

        .teaser-1 {
            position: relative;
            z-index: 10;
            overflow: hidden;
        }

        .btn {
            padding: 0;
            background-color: transparent;
            @extend %bg-pattern-white-2;
            display: block;
            @include breakpoint(small only) {
                position: relative;
            }
            @include breakpoint(medium) {
                width: 100px;
                height: 100px;
                position: absolute;
                right: 3.3rem;
                bottom: 3.255rem;
            }
            .btn-plus {

                width: 42px;
                height: 42px;
                display: inline-block;

                background-color: #fff;
                &:before {
                    position: absolute;
                    left: 1.5rem;
                    top: 1rem;
                    content: '\e08a';
                    font-family: 'icons-default';
                    font-size: 2rem;
                    color: $color-1--4;
                    //transform: translate(-50%, -50%);
                    transition: all 0.3s ease;
                }
                &:hover, &:focus {
                    &:before {
                        color: $color-3--3;
                    }
                }

                @include breakpoint(small only) {
                    &:before {
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                @include breakpoint(medium) {
                    position: absolute;
                    right: 1.9rem;
                    bottom: 1.9rem;
                }
            }
        }
    }
}
