// @name Search box
// @description Search module
.search-box {
    //float: right;
    //padding: 0;
    padding-top: 2rem;
    padding-left: 4rem;
    @media(min-width: 1280px) and (max-width: 1600px) {
        padding-left: 2rem;
    }
    //@include breakpoint(medium down) {
    //    width: auto;
    //    margin-right: 4em;
    //}

    form {
        margin: 0;
    }
    label {
        font-family: $typo-2;
        font-weight: $normal;
        color: $color-3--3;
        font-size: 2rem;
        line-height: 2.2rem;
        white-space: nowrap;
    }

    input {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.4rem;
        color: rgba(#000, .7);
        background-color: $color-3--6;
        height: 5rem;
        padding: 1.8rem 2rem;
        border: none;
    }

    .search-box__fields-wrapper,
    .search-box__button-wrapper {
        display: inline-block;
        vertical-align: bottom;
        //@include breakpoint(medium down) {
        //    display: table-cell;
        //    vertical-align: middle;
        //}
    }
    //.search-box__fields-wrapper {
    //    @include breakpoint(medium down) {
    //        width: 100%;
    //    }
    //}
    .search-box__button-wrapper {
        //@include breakpoint(medium down) {
        //    padding-left: 1em;
        //}
        .search-box__button {
            background: none;
            width: 5rem;
            height: 5rem;
            line-height: 4rem;
            text-align: center;
            padding: .5rem;

            &:before {
                display: none;
            }
            svg {
                width: 1.8rem;
                height: 1.8rem;
                line-height: 1;
            }
            &:hover, &:focus {
                svg {
                    fill: $color-1--3;
                }
            }
        }
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;
            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 1rem;
            }
            input, select {
                display: table-cell;
                vertical-align: middle;
                //width: 100%;
                width: 50rem;
            }
        }
    }
    // Drop down menu
    .ddm {
        position: static;
        > .ddm__sub-level {
            top: 0;
            right: 3em;
            left: 0;
            width: 100%;
            height: 8rem;
            background-color: $color-white;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            padding-left: 40rem;
            @media(min-width: 1280px) and (max-width: 1600px) {
                padding-left: 30rem;
            }
            //@include breakpoint(medium down) {
            //    z-index: 102;
            //    padding: 1em;
            //    background: $color-3--2;
            //}
        }
        &__button {
            background: transparent;
            svg {
                width: 1.8rem;
                height: 1.8rem;
            }
            &:hover, &:focus {
                svg {
                    fill: $color-1--3;
                }
            }
        }
    }

    .facet-type-dateRange {
        &::before {
            content: none;
        }

        .form-data {
            input {
                height: 4rem !important;
            }
        }
    }
}

// @name Search in mobile menu
.mobile-search-box.search-box {
    display: none;
    @include breakpoint(medium down) {
        display: block;
        width: 100%;
        background-color: $color-white;
        border-bottom: 1px dotted $color-3--2;
        padding: 3rem 2rem;

        .search-box_fields {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            background-color: $color-3--6;
        }

        .search-box__fields-wrapper {
            flex: 1 1 auto;

            input {
                width: 100%;
            }
        }

        .search-box__button-wrapper {
            flex: 0 0 5rem;
        }
    }
}

// @name Search list
$pertinence__font-size: 1.2;
.pertinence {
    float: right;
    font-size: #{$pertinence__font-size}em;
    span {
        font-size: em(1, 1.2);
    }
}

.pertinence__metter {
    display: inline-block;
    vertical-align: middle;
    width: em(10, $pertinence__font-size);
    height: em(1, $pertinence__font-size);
    padding: em(.2, $pertinence__font-size);
    background: $color-3--2;
    margin: 0 em(1, $pertinence__font-size);
    > span {
        display: block;
        height: em(.8, $pertinence__font-size);;
        background: $color-1--2;
    }
}

.secondaryContentSection {
    &.column-bloc {
        padding-left: 0;
        margin-bottom: 4rem;
    }
    h3 {
        font-family: $typo-1;
        text-transform: uppercase;
        color: $color-red;
        font-size: 1.8rem;
        font-weight: $bold;
        margin-top: 0;
        margin-bottom: 1rem;
        padding: .5rem;
        &.actives {
            background-color: $color-red;
            color: $color-white;
            text-align: center;
        }
    }
    #tx-solr-facets-in-use {
        .rte {
            a {
                &:after {
                    content: '\e058';
                    font-size: 1rem;
                    margin-left: 0.1rem;
                    font-family: 'icons-default';
                    vertical-align: middle;
                }
            }
        }
    }
    ul {
        li {
            a {
                font-size: 1.4rem;
                font-weight: $light;
                color: $color-black;
            }
            .close {
                //margin-left: .2rem;
                &:after {
                    content: "\e008";
                    font-family: 'icons-default';
                    font-size: 2.2rem;
                    line-height: 1;
                    vertical-align: -.7rem;
                }
                &:hover, &:focus {
                    text-decoration: none;
                }
            }
            .date {
                font-family: $typo-1;
                font-size: 1.4rem;
                font-weight: $light;
                color: $color-black;
            }
        }
    }
    .facet-label, .subtitle {
        padding: .5rem;
        margin-bottom: 1.5rem;
        color: $color-white;
        font-size: 1.4rem;
        font-weight: 700;
        //text-transform: inherit;
        background-color: $color-black;
    }
    //.filter-solr-date {
    //  padding-left: 0;
    //  &:before {
    //    content: none;
    //  }
    //}
    //.box_search-calendar {
    //  .solr-field {
    //    display: flex;
    //    margin-top: 1rem;
    //    align-items: center;
    //    label {
    //      font-size: 1.6rem;
    //    }
    //    input {
    //      margin-left: 1rem;
    //      padding: .5rem;
    //      //height: 40px;
    //    }
    //    input[type="date"]::-webkit-calendar-picker-indicator,
    //    input[type="date"]::-webkit-inner-spin-button {
    //      display: none;
    //    }
    //    button[type=submit] {
    //      height: 4rem;
    //      font-size: 1.4rem;
    //    }
    //    .form__field-datewrapper {
    //      width: 90%;
    //    }
    //  }
    //}
    .form-data {
        .form__field-datewrapper {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }
        label {
            margin: 0 1rem 0 0;
        }
        .solar-submit {
            width: 100%;
            margin-top: 0 !important;
            &:before {
                content: "\e057" !important;
            }
        }
    }
}

.list-type-1--search {
    .list-type-1__title {
        a {
            color: $color-1--2;
        }
    }
}

.list-type-1-block {
    .category {
        display: inline-block;
    }
}
