// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    //background: $color-3--1;
    padding: 5rem 0 3rem;
    position: relative;
    background-color: $color-white;
    margin-bottom: 1rem;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        box-shadow: 0 10px 10px -1px rgba(0, 0, 0, 0.15);
        width: 100%;
        border-radius: 50%;
        height: 140px;
        z-index: -1;
    }
    &--with-filter {
        &:after {
            content: none;
        }
    }
    .headding__wrapper-title {
        @extend .wrapper-main;
        margin-bottom: 2em;
    }
    .heading__wrapper {
        @extend .wrapper-main;
        display: flex;
        flex-wrap: nowrap;
        @include breakpoint(small down) {
            padding: 0 10px;
            display: block;
        }
        > *:last-child {
            margin-bottom: 0;
        }
        .heading__wrapper-figure {
            flex-basis: auto;
        }
        .heading__wrap {
            flex-basis: 100%;
            position: relative;
        }
    }
    .heading__figure {
        margin-right: 2em;
        display: table;
        @include breakpoint(small down) {
            width: 100%;
            float: none;
            margin-right: 0;
            margin-bottom: 2em;
        }

        .filters__locate {
            white-space: nowrap;
            margin: 0 2rem 1rem 0;
            text-indent: 0;
            padding: 0.89286em 2.28571em 0.89286em 3em;
            &:before {
                top: 50%;
                left: 2.2rem;
            }
        }

        .heading__picture + .filters__locate {
            margin-top: 1rem;
        }
    }
    .heading__picture {
        display: block;
        margin-bottom: .5em;
        img {
            //max-width: 100%;
            height: auto;
        }
    }
    .heading__caption {
        display: table-caption;
        caption-side: bottom;
    }
    h1 {
        font-family: $typo-1;
        font-weight: $light;
        font-size: #{$heading__h1__font-size}em;
        line-height: 1.3;
        color: $color-3--3;
        @include breakpoint(small down) {
            font-size: 2.8em;
            color: $color-black;
        }
    }
    h1 + .teaser-2 {
        margin-top: 1rem;
    }
    .teaser-2 {
        color: $color-3--8;
    }
    .subtitle {
        $category__font-size: 1.8;
        font-size: #{$category__font-size}em;
    }
    .subtitle + h1 {
        margin-top: em(1.2, $heading__h1__font-size);
    }
    &__category {
        margin-bottom: .5rem;
    }
    .communes, .communes a {
        font-family: $typo-2;
        font-weight: $medium;
        font-size: 1.2em;
        color: $color-3--2;
    }
    .date {
        font-size: 1.4em;
    }
    .status {
        $status__font-size: 1.5;
        background: #fff;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;
        &.status--new {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }
    .heading__media {
        margin-top: 1em;
        padding-top: 1em;
        border-top: none;
        .date, .media {
            font-size: 1.6em;
        }
        .media {
            font-weight: $bold;
            border: none;
            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__wrapper {
            .heading__wrapper-figure {
                order: 2;
            }
            .heading__wrap {
                order: 1;
            }
        }
        .heading__figure {
            margin-right: 0;
            margin-left: 2em;
            @include breakpoint(small down) {
                margin-left: 0;
            }
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
            @include breakpoint(medium down) {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__figure {
        margin-right: 0;
    }
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
    }
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
    .heading__wrapper-date {
        width: 8.5em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .heading__wrapper-calendar-button {
        margin: 1em 1em 0;
        line-height: normal;
    }
    .heading__wrap {
        padding-left: 2em;
    }
    .button-1 {
        margin-top: 2em;
        background: $color-white;
        cursor: pointer;
        &:hover, &:focus {
            background: $color-1--3;
        }
    }

    .heading-bottom {
        display: flex;
        margin: 2.5rem 0;
        @include breakpoint(small down) {
            flex-direction: column;
        }
        &__item {
            position: relative;
            width: 30%;
            padding-left: 7.5rem;
            &:before {
                content: '\E013';
                position: absolute;
                display: block;
                left: 0;
                top: -1rem;
                font-family: 'icons-project';
                font-size: 4rem;
                color: $color-2--2;
            }
            @include breakpoint(small down) {
                width: 80%;
                min-height: 15rem;
                margin: 0 auto 2rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &.clock {
                &:before {
                    content: '\E014';
                }
            }
            &.cc-hand {
                &:before {
                    content: '\E012';
                }
                .button-1 {
                    background-color: $color-3--3;
                    color: $color-white;
                    @include breakpoint(medium down) {
                        &:before {
                            left: 2rem;
                        }
                    }
                    &:before {
                        content: '\E011';
                        left: 1rem;
                        font-family: 'icons-project';
                        border: none;
                        color: #00ADA0;
                    }
                    &:hover, &:focus {
                        background-color: $color-1--2;
                    }
                }
            }
            &:after {
                content: '';
                position: absolute;
                display: block;
                left: 5rem;
                top: -1rem;
                width: 1px;
                height: 119px;
                border-left: 2px dotted #00ADA0;
                transform: skewX(-14deg);
            }
            h3 {
                font-family: $typo-2;
                font-size: 1.4rem;
                font-weight: $medium;
                color: $color-1--2;
                text-transform: uppercase;
                margin-bottom: .5rem;
            }
            p {
                font-size: 1.4rem;
                font-weight: $light;
                margin-bottom: 1rem;
            }
            .button-1 {
                font-size: 1.2rem;
                line-height: 1.2;
                color: $color-3--3;
                margin-top: 0;
                padding: 1.5rem 1.5rem 1.5rem 4.5rem;
                @include breakpoint(medium down) {
                    &:before {
                        left: 2rem;
                    }
                }
                &:before {
                    content: "\e053";
                    left: .5rem;
                    font-size: 1.6rem;
                    line-height: 1;
                    color: $color-1--2;
                    border: 1px solid #d7e4eb;
                    border-radius: 50%;
                    padding: .67rem;
                }
                &:hover, &:focus {
                    color: $color-white;
                    &:before {
                        color: $color-white;
                    }
                }
            }

        }
    }
}

//.heading {
//    margin-bottom: 1rem;
//    & + .filters {
//
//        &:after {
//            content: none;
//        }
//
//    }
//}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    .list-infos {
        margin-top: 2em;
    }
    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }
    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }
}

@include breakpoint(small down) {

    // @name Heading single event
    // @dependency .heading
    .heading--single-event {
        .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
            display: block;
            vertical-align: inherit;
        }
        .heading__picture {
            img {
                width: auto;
                max-width: 100%;
            }
        }
        .heading__wrapper-date {
            width: 100%;
            border-right: 0;
            display: table;
            border-bottom: 1px solid $color-3--3;
            margin-bottom: 2em;
            .date-1, .hour-place {
                display: table-cell;
                vertical-align: middle;
                width: 50%;
                padding-bottom: 2em;
            }
            .date-1 {
                text-align: left;
                time:nth-of-type(1) {
                    margin-left: 0;
                }
            }
        }
        .heading__wrap {
            padding-left: 0;
        }
    }
}
